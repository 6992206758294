import * as Yup from 'yup';

export const validationSchema = Yup.object({
    first_name: Yup.string().matches(/^[A-Za-z ]+$/, 'Name should only contain alphabets and spaces')
    .required('First Name is required'),
    middle_name: Yup.string().matches(/^[A-Za-z ]+$/, 'Name should only contain alphabets and spaces').nullable(),
    last_name: Yup.string().matches(/^[A-Za-z ]+$/, 'Name should only contain alphabets and spaces').nullable(),
    email: Yup.string().email('Invalid email').required('Email id is required'),
    address_one: Yup.string().required('Address is required'),
    mobile_number: Yup.string().matches(/^\d{10}$/, 'Mobile number must be 10 digits')
    .required('Mobile number is required'),
    adhar: Yup.string().matches(/^\d{12}$/, 'Adhar number must be 12 digits')
    .required('Adhar number is required'),
    pan: Yup.string().matches(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/, 'Invalid PAN card number')
    .required('PAN card number is required'),
    status: Yup.string().required('Select one status'),
});

export const changePasswordValidation = Yup.object({
    password: Yup.string().matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
        'Password must be 8 characters or longer with an uppercase letter, a number, and a special character.'
      ).required('Please enter new password'),
    password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords did not matched.').required('Please enter confirmed Password.'),
});

export const bankSchema = Yup.object({
    name: Yup.string().matches(/^[A-Za-z ]+$/, 'Name should only contain alphabets and spaces').required('Name is required'),
    bank_name: Yup.string().matches(/^[A-Za-z ]+$/, 'Bank name should only contain alphabets and spaces').required('Bank Name is required'),
    account: Yup.number().required('Account number is required').integer('Account number must be numberic'),
    ifsc: Yup.string().required('IFSC code is required'),
});