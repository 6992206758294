import React from 'react'

export const CardHeader = ({title}) => {
  return (
    <div className='card-header'>
        <div className='card-title'>
            {title}
        </div>
    </div>
  )
}
