import { Form, Formik } from 'formik'
import React from 'react'
import FormikControl from '../../../components/FormikControl'
import { transferType, wallet } from '../../../helpers/constant'
import { useLocation, useNavigate } from 'react-router-dom'
import { makeDmt } from '../../../api/instance_method';
import { ToastContainer, toast } from 'react-toastify'
import { confirmDmtSchema } from '../validation'

const ConfirmDmt = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const {
    name,
    mobile_number,
    beneficiary_name,
    beneficiary_bank,
    beneficiary_account,
    ifsc_code,
    formValue,
  } = state || {}
  const initialValues = {
    customer_bank_accounts_id: formValue.customer_bank_accounts_id,
    amount: Number(formValue.amount),
    transfer_type: '',
    // wallet: ''
  }

  const confirmTransaction = (val) => {
 
    makeDmt(val).then(
      (response) => {
        if (response.data.status !== false) {
          toast.success("Transaction successfull");
          setTimeout(() => {
            navigate('/dmt/customer-login')
          }, 4000);
        } else {
          toast.error('Transaction cannot be made');
          setTimeout(() => {
            navigate('/dmt/customer-login')
          }, 4000)
        }

      },
      (error) => {
        toast.error('Transaction Unsuccessful');
        setTimeout(() => {
          navigate('/dmt/customer-login')
        }, 4000)
      }
    )
      .catch(
        (error) => {
          toast.error('Transaction Unsuccessful');
          setTimeout(() => {
            navigate('/dmt/customer-login')
          }, 4000)
        })
  }
  return (
    <div className='content-wrapper'>
      <div className='container-fluid '>
        <ToastContainer />
        <h5 className='text-center py-4'>PLEASE VERIFY YOUR TRANSACTION DETAILS</h5>
        <div className='row'>
          <div className='col-md-6'>
            <div className='card'>
              <div className='card-body'>
                <h5 className='text-center pb-3'>
                  REMITTER DETAILS
                </h5>
                <table className='table table-hover table-borderless mb-5'>
                  <tr style={{ borderBottom: "1px solid #dee2e6" }}>
                    <th>Name : </th>
                    <td>{name}</td>
                  </tr>
                  <tr style={{ borderBottom: "1px solid #dee2e6" }}>
                    <th>Mobile : </th>
                    <td>{mobile_number}</td>
                  </tr>
                </table>
                <h5 className='text-center pb-3'>RECEIVER DETAILS</h5>
                <table className='table table-hover table-borderless'>
                  <tr style={{ borderBottom: "1px solid #dee2e6" }}>
                    <th>Beneficiary Name : </th>
                    <td>{beneficiary_name}</td>
                  </tr>
                  <tr style={{ borderBottom: "1px solid #dee2e6" }}>
                    <th>Account Number : </th>
                    <td>{beneficiary_account}</td>
                  </tr>
                  <tr style={{ borderBottom: "1px solid #dee2e6" }}>
                    <th>Bank : </th>
                    <td>{beneficiary_bank}</td>
                  </tr>
                  <tr style={{ borderBottom: "1px solid #dee2e6" }}>
                    <th>IFSC Code : </th>
                    <td>{ifsc_code}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='card'>
              <div className='card-body'>
                <h5 className='text-center'>TRANSACTION DETAILS</h5>
                <Formik initialValues={initialValues}
                  // validationSchema={confirmDmtSchema}
                  // validateOnBlur={false}
                  // validateOnChange={false}
                  onSubmit={confirmTransaction}>
                  <Form>
                    <table className='table table-borderless table-hover'>
                      <tr style={{ borderBottom: "1px solid #dee2e6" }}>
                        <th>Amount : </th>
                        <td className='text-center'>&#x20B9; {formValue.amount}</td>
                      </tr>
                      <tr style={{ borderBottom: "1px solid #dee2e6" }}>
                        <th>Charges : </th>
                        <td className='text-center'>1%</td>
                      </tr>
                      <tr style={{ borderBottom: "1px solid #dee2e6" }}>
                        <th>Transfer Type : </th>
                        <td className='my-0 py-0 pt-1 text-center'>
                          <FormikControl
                            control='radio'
                            name='transfer_type'
                            options={transferType}
                          />
                        </td>
                      </tr>
                      <tr style={{ borderBottom: "1px solid #dee2e6" }}>
                        <th>Choose Wallet : </th>
                        <td className='my-0 py-0 pt-1 text-center'>
                          <FormikControl
                            control='radio'
                            name='wallet'
                            options={wallet}
                          />
                        </td>
                      </tr>
                    </table>
                    {/* <FormikControl
                      control="select"
                      name="status"
                      label="Number of 2000 Notes : 2000 x "
                      options={transferType}
                      style={{ width: '10%'}}
                    /> */}
                    <div className='d-flex justify-content-center'>
                      <button
                        className='btn btn-outline-dark mx-2'
                        type='submit'
                      >
                        Confirm Transaction
                      </button>
                      <button className='btn btn-outline-dark mx-2' onClick={() => navigate('/dmt/customer-login')}>
                        Cancel
                      </button>
                    </div>
                    <p className='mt-4' style={{ fontSize: '0.8rem' }}>*Note - You are not allowed to charge more than 1% of the transaction value.</p>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmDmt