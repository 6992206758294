import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import { Formik, Form } from 'formik';
import FormikControl from '../../../components/FormikControl';
import CardComponent from '../../../components/sections/CardBody';
import Table from '../../../components/sections/Table';
import { MdFileUpload } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';

const TransferReport = () => {
  const initialValues = {
    start_date: '',
    end_date: '',
    search: '',
    keyword: '',
    status: ''
  }

  const handleSubmit = (val) => {
    if (val) {
      toast.success('Successful');
    } else {
      toast.error('unsuccessful');
    }
  }
  return (
    <div className='content-wrapper'>
      <Breadcrumbs title='Report' path='retailer-dashboard' />
      <ToastContainer />
      <div className='container'>
        <div className="card">
          <div className='card-header'>
            <div className='card-title'>
              Money Transfer Report
            </div>
          </div>
          <div className="card-body">
            <Formik
              initialValues={initialValues}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className="row">
                  <div className="col-md-6">
                    <FormikControl
                      control="datepicker"
                      name="start_date"
                      label="Start Date"
                    />
                  </div>
                  <div className="col-md-6">
                    <FormikControl
                      control="datepicker"
                      name="end_date"
                      label="End Date"
                    />
                  </div>
                  <div className="col-md-6">
                    <FormikControl
                      control="select"
                      name="search"
                      label="Search"
                      options={[{ key: "1", value: "Search by" }]}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormikControl
                      control="input"
                      name="keyword"
                      label="Keyword"
                      placeholder="Enter keyword"
                    />
                  </div>
                  <div className="col-md-6">
                    <FormikControl
                      control="select"
                      name="status"
                      label="Status"
                      options={[{ key: "1", value: "Transaction Status" }]}
                    />
                  </div>
                </div>
                <div className='text-center'>
                  <button className='btn btn-secondary rounded-pill px-3'>
                    Submit
                  </button>
                </div>
              </Form>
            </Formik>
            <div className="my-5">
              <CardComponent>
                <div className="px-md-5 pt-3 pb-2">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="bg-transparent">Total Amount</th>
                        <th className="bg-transparent">Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="bg-transparent">xx</td>
                        <td className="bg-transparent">xx</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CardComponent>
            </div>
            <Table
              tableHeading={[
                "Date",
                "TXNID",
                "Remitter",
                "Bank Name",
                "Account No",
                "IFSC",
                "BeneName",
                "UTR",
                "Type",
                "Amount",
                "Charges",
                "Wallet Type",
                "Status",
                "Action",
                "Remarks",
              ]}
              tableBody="No data right now"
              colSpan="15"
            />
            <button className="btn btn-dark my-3 rounded-pill">
              Export <MdFileUpload />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransferReport