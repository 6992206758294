import React from "react";

const PanCard = () => {
  return (
    <div className="content-wrapper">
      <div className="container">No Ui</div>
    </div>
  );
};

export default PanCard;
