import React, { useEffect, useState } from 'react'
import { getRetailerByToken } from '../../api/instance_method'
import { useDispatch } from 'react-redux';
import { setRole } from '../../redux/slices/authSlice';
import classes from './RetailerHeader.module.scss'

const RetailerHeader = () => {
  const [wallet, setWallet] = useState([]);
  const [user, setUser] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getRetailerByToken().then(
      (response) => {
        setUser(response.data.user)
        setWallet(response.data.user.wallet)
        dispatch(setRole('retailer'))
      }
    )
  }, [dispatch]);
  
  return (
    <div>
      <div
        style={{ minHeight: '4.5rem', minWidth: '280px', backgroundColor: '#bcc6cd', color: '#ffffff' }}
        className='justify-content-end text-right d-flex'
      >
        <div className={classes.no_mobile}>
          <table className='my-2'>
            <tbody>
              <tr>
                <td className='px-3 retailer-header '>Main Wallet : </td>
                <td className='px-3 retailer-header '>Cash-In Wallet : </td>
                <td className='px-3 '>Welcome : </td>
              </tr>
              <tr>
                {wallet.map((el, id) => {
                  return (
                    <th className='px-3 retailer-header' key={id}>{el.amount}</th>
                  )
                })}
                <th className='px-3'>
                  {user.first_name}<br />
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={classes.mobile}>
          <table className='m-2'>
            <thead className='font-mulish'>
              <tr>
                <td className={classes.right_border}>Cash-in Wallet -</td>
                <td className='pl-2'>Main Wallet -</td>
              </tr>
            </thead>
            <tbody className='fw-bold'>
              <tr>
                {wallet.map((el, id) => {
                  const className = id === 0 ? classes.right_border : classes.otherElements;
                  return (
                    <td className={className} key={id}>{el.amount}</td>
                  )
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default RetailerHeader