import React, { useEffect, useState } from 'react'
import { addAepsSlab, getAepsSlab, getMemberShips } from '../../../api/instance_method'
import { Formik, Form } from 'formik';
import FormikControl from '../../../components/FormikControl';
import { ToastContainer, toast } from 'react-toastify';
import { BiSolidEditAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import EditAeps from './EditAeps';
import Pagination from 'react-js-pagination';
import Loader from '../../../components/Loader';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import { useCallback } from 'react';
import style from './AepsSlab.module.scss';

const AepsSlab = () => {
  const [aepsSlab, setAepsSlab] = useState([]);
  const [updatedState, setUpdatedState] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState('');
  const [plans, setPlans] = useState([])
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [aepsId, setAepsId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const token = sessionStorage.getItem('token')
  const initialValues = {
    from: '',
    to: '',
    commission: '',
    commision_type: '',
    membership_plan_id: ''
  }

  const fetchAepsSlabData = useCallback(() => {
    if (token) {
      getAepsSlab(1).then(
        (response) => {
          console.log('get slabs', response);
          setAepsSlab(response.data.data.data);
          setUpdatedState(true);
          console.log(updatedState)
          setTotalItemsCount(response.data.data.total)
        }
      );
    }
  }, [token, updatedState]);


  useEffect(() => {
    fetchAepsSlabData();

    setTimeout(() => {
      setIsLoading(false)
    }, 2000);
  }, [updatedState, isOpen, plans, fetchAepsSlabData]);

  const handleSubmit = (values) => {
    const commision_type = values.commision_type == 'Percentage' ? 'Percentage' : 'Flat';
    const valuesToSend = { ...values, commision_type };
    if (token) {
      document.getElementById('add_slab').disabled=true;
      addAepsSlab(valuesToSend).then(
        (response) => {
          toast.success("Slab added successfuly")
        },
        (error) => {
          toast.error("Slab cannot be added.")
        }
      )
    }
  }

  useEffect(() => {
    getMemberShips().then(
      (response) => {
        const membershipPlans = response.data.data.data;
        const allPlans = [
          { key: '', value: '--Select Plan--' },
          ...membershipPlans.map((element, id) => ({
            key: element.id,
            value: element.name
          }))
        ]
        setPlans(allPlans);
      }
    )
  }, [])



  const handleClick = () => {
    setShow(!show)
  }
  const openModal = (id) => {
    setAepsId(id)
    setIsOpen(true)
  }
  const closeModal = () => {
    setIsOpen(false)
  }

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    getAepsSlab(pageNumber).then(
      (response) => {
        setAepsSlab(response.data.data.data);
        setTotalItemsCount(response.data.data.total);
      },
      (error) => {
        toast.error(error);
        navigate('/')
      }
    )
  }
  return (
    <div className='content-wrapper'>

      <ToastContainer />
      <Breadcrumbs title='AEPS Slab' path='/dashboard' />

      {
        isOpen && (
          <EditAeps closeModal={closeModal} aepsId={aepsId} />
        )
      }

      <div className='container'>
        {show && (
          <div className={`card position-absolute ${style.add_form}`}>
            <div className='card-header '>
              <div className='card-title font-mulish fw-bold'>
                Add Aeps Slab
              </div>
              <div className='card-tools '>
                <button onClick={handleClick} className="close me-1 color-white">x</button>
              </div>
            </div>
            <div className='card-body'>
              <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
              >
                <Form>
                  <div className='row mx-auto'>
                    <div className='col-md-6'>
                      <FormikControl
                        control='input'
                        type='text'
                        name='from'
                        label='From'
                      />
                    </div>
                    <div className='col-md-6'>
                      <FormikControl
                        control='input'
                        type='text'
                        name='to'
                        label='to'
                      />
                    </div>
                  </div>
                  <div className='row mx-auto'>
                    <div className='col-md-12'>
                      <FormikControl
                        control='input'
                        type='text'
                        name='commission'
                        label='Commission'
                      />
                    </div>
                  </div>
                  <div className='row mx-auto'>
                    <div className='col-md-6'>
                      <FormikControl
                        control='select'
                        name='membership_plan_id'
                        label='Plan Type'
                        options={plans}
                      />
                    </div>
                    <div className='col-md-6 text-center'>
                      <h6 className='font-weight-bold font-mulish'>Commission Type</h6>
                      <div style={{ marginTop: '-.8rem', paddingLeft: '1rem' }}>
                        <FormikControl
                          control="checkbox"
                          name="commision_type"
                          checked
                          options={[
                            { key: '1', value: 'Percentage' },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='text-center my-4'>
                    <button type='submit' id="add_slab" className='btn rounded-pill my-2 px-3 btn-dark mx-3'>
                      Add Slab
                    </button>
                    <button
                      type='button'
                      className='btn btn-danger rounded-pill my-2 px-3 mx-3'
                      onClick={handleClick}
                    >
                      Cancel
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        )}
        {isLoading ? (
          <Loader />
        ) : (
          <div className='card'>
            <div className='card-header'>
              <div className='card-title font-mulish fw-bold'>
                Aeps Slabs
              </div>
              <div className='card-tools mr-1'>
                <h5 className='pointer' onClick={handleClick}>
                  <i className="bi bi-database-fill-add"></i>
                </h5>
              </div>
            </div>
            <div className="card-body table-responsive">
              <div id="example2_wrapper" className="dataTables_wrapper dt-bootstrap4">
                <table
                  id="example2"
                  className="table table-bordered table-hover dataTable dtr-inline"
                  aria-describedby="example2_info"
                >
                  <thead>
                    <tr>
                      <th>From</th>
                      <th>To</th>
                      <th>
                        RETAILER COMMISSION
                      </th>
                      <th>Commission Type</th>
                      <th>Plan Type</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  {aepsSlab.length ? (
                    <tbody>
                      {aepsSlab.map((element) => {
                        return (
                          <tr key={element.id}>
                            <td>{element.from}</td>
                            <td>{element.to}</td>
                            <td>{element.commission}</td>
                            <td>{element.commision_type}</td>
                            <td>{element.membership_plan.name}</td>
                            <td onClick={() => openModal(element.id)} className='pointer'>
                              <BiSolidEditAlt /></td>
                          </tr>
                        )
                      })}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colspan='6'>
                          <p className='font-mulish'>
                            No user found
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  )}

                </table>
              </div>

            </div>
            <div className='mx-3'>
              <div className='row'>
                {aepsSlab.length > 0 ? (
                  <>
                    <div className="col-sm-12 col-md-6">
                      <div className="dataTables_info text-primary" id="example2_info" role="status" aria-live="polite">
                        *Showing <b>{aepsSlab.length}</b> of <b>
                          {totalItemsCount}</b> entries
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 text-right ">
                      <div className="float-end dataTables_paginate paging_simple_numbers" id="example2_paginate">
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={totalItemsCount}
                          pageRangeDisplayed={10}
                          onChange={handlePageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  " "
                )}

              </div>
            </div>
          </div>
        )}
      </div>
      {(show || isOpen) && (
      <div className={style.overlay}> </div>
      )}
    </div>
  )
}

export default AepsSlab