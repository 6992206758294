import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import CardComponent from '../../../components/sections/CardBody'
import Card from '../../../components/sections/Card';
import Table from '../../../components/sections/Table';
import Button from '../../../components/elements/Button';
import { Form, Formik } from 'formik'
import FormikControl from '../../../components/FormikControl';
import { FaDownload } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import { TransactionReportSchema } from '../validation'

const NsdlStatement = () => {
  const initialValues = {
    start_date: "",
    end_date: "",
    keyword: "",
    search: "",
  }
  const handleSubmit = (value, { resetForm }) => {
    toast.success('Data read successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    console.log('submit button clicked');
    resetForm()
  }
  return (
    <div className='content-wrapper'>
      <ToastContainer />
      <Breadcrumbs title='Statement' path='/retailer-dashboard' />
      <div className='container'>
        <Card title='Statement'>
          <Formik 
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={TransactionReportSchema}
            validateOnChange={false}
          >
            <Form>
              <div className='row'>
                <div className='col-md-6'>
                  <FormikControl
                    control='datepicker'
                    label='Start Date'
                    name='start_date'
                  />
                </div>
                <div className='col-md-6'>
                  <FormikControl
                    control='datepicker'
                    label='End Date'
                    name='end_date'
                  />
                </div>
                <div className='col-md-6'>
                  <FormikControl
                    control='select'
                    label='Search By'
                    name='search'
                    options={[
                      { key: '1', value: '--Select--' },
                      { key: '2', value: 'TXNID' },
                      { key: '3', value: 'ACKNO' }
                    ]}
                  />
                </div>
                <div className='col-md-6'>
                  <FormikControl
                    control='input'
                    label='Keyword'
                    name='keyword'
                  />
                </div>
              </div>
              <div className='text-center'>
                <button className='btn btn-dark px-4 rounded-pill' type='submit'>Submit</button>
              </div>
            </Form>
          </Formik>
          <div className='mt-4'>
          <Table
            tableHeading={[
              'TXNID', 'Ack No.', 'PAN Name', 'Amount', 'RComm', 'TDS', 'Date', 'Status', 'PAN Type', 'Remarks', 'PAN Status'
            ]}
            tableBody='No data right now'
            colSpan='12'
          />
          </div>
          <div>
            <Button className='btn btn-dark rounded-pill' value={<>Download <FaDownload /></>} />
          </div>
        </Card>
      </div>
    </div>
  )
}

export default NsdlStatement