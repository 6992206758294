import { AiOutlineHistory, AiOutlineUnorderedList, AiOutlineTransaction, AiFillFile, AiFillBank } from 'react-icons/ai';
import { BsFillDatabaseFill, BsFillLightningChargeFill, BsCashCoin, BsFillFileSpreadsheetFill } from 'react-icons/bs';
import { FaUsers,FaWallet, FaMoneyBill, FaBookOpen, FaUserCircle, FaRegUserCircle, FaFileInvoiceDollar,FaRegIdCard, FaRegCreditCard, FaList  } from 'react-icons/fa';
import { FiUserPlus, FiEdit } from 'react-icons/fi';
import { PiMoneyDuotone, PiUsersFourFill, PiShieldPlusBold, PiCertificateDuotone, PiTelevisionSimpleBold,PiUserListBold } from 'react-icons/pi';
import { LiaUserPlusSolid } from 'react-icons/lia';
import { MdOutlineCardMembership, MdOutlineAddCard, MdOutlinePassword,  MdCable, MdOutlineShoppingCartCheckout, MdShoppingCart, MdOutlineCreditScore, MdSupport  } from 'react-icons/md'
import { TbReportAnalytics, TbDeviceLandlinePhone, TbDeviceMobileDollar, TbReport   } from 'react-icons/tb';
import GradingIcon from '@mui/icons-material/Grading';
import {GoPersonAdd} from 'react-icons/go'
import {BsQrCodeScan, BsPiggyBank, BsCurrencyRupee} from 'react-icons/bs'
import {BiTransfer, BiSolidTagAlt, BiSolidCylinder, BiSolidGasPump, BiBroadcast, BiMoney} from 'react-icons/bi';
import {FcMoneyTransfer} from 'react-icons/fc';
import {RiRefund2Line, RiUserShared2Fill } from 'react-icons/ri';
import {GiMoneyStack, GiPiggyBank, GiReceiveMoney, GiPayMoney, GiTap, GiCash } from 'react-icons/gi';
import {LuUtilityPole} from 'react-icons/lu';
import {SiDatabricks} from 'react-icons/si';
import { FaDownload } from "react-icons/fa6";
import { CgProfile } from "react-icons/cg";
import { SiYourtraveldottv } from "react-icons/si";

export const adminSidebar = [
  {
    name: 'Dashboard',
    icon: <i className="bi bi-palette"></i>,
    path: '/dashboard'
  },
  {
    name: 'Users',
    icon: <FaUsers />,
    path: '/users'
  },
  {
    name: 'Customer Banks',
    icon: <BsCashCoin />,
    path: '/bank-module'
  },
  {
    name: 'AEPS Slab',
    icon: <BsFillDatabaseFill />,
    path: '/aeps-slab'
  },
  {
    name: 'Membership Plans',
    icon: <MdOutlineCardMembership  />,
    path: '/membership-plans'
  },
  {
    name: 'M-ATM',
    icon: <i className="bi bi-credit-card-2-back-fill"></i>,
    path: '/orders/m-atm'
  },
  {
    name: 'Transaction History',
    icon: <AiOutlineHistory />,
    submenu: [
      {
        name: 'DMT',
        path: '/transaction-history/dmt',
        icon: <TbReport />,
      },
      {
        name: 'AEPS',
        path: '/transaction-history/aeps',
        icon: <TbReport />,
      },
      {
        name: 'Topup Transfer',
        path: '/transaction-history/topup',
        icon: <TbReport />,
      }
    ]
  },
  {
    name: 'Logout',
    icon:  <i className="fas fa-sign-out-alt"></i>,
    path: '/'
  },
]

export const distributorSidebar = [
  {
    name: 'Dashboard',
    path: '/distributor-dashboard',
    icon: <i className="bi bi-palette"></i>,
  },
  {
    name: 'GST No.',
    path: '/distributor/gst-number',
    icon: <FiEdit />,
  },
  {
    name: 'Help & Support',
    path: '/distributor/support',
    icon: <MdSupport />,
  },
  {
    name: 'Bulk Orders',
    path: '/bulk-orders',
    icon: <GradingIcon />,
  },
  {
    name: 'Retailers',
    icon: <FaUsers />,
    submenu: [
      {
        name: 'Add Retailer',
        path: '/distributor/add-retailer',
        icon: <GoPersonAdd />,
      },
      {
        name: 'User List',
        path: '/get-retailers',
        icon: <i className="bi bi-people"></i>,
      },
      {
        name: 'Activate QR',
        path: '/activate-qr-code',
        icon: <BsQrCodeScan />,
      },
      {
        name: 'Activate NSDL Pan',
        path: '/activate-nsdl-plan',
        icon: <AiFillFile />,
      },
      {
        name: 'Activate Finolet',
        path: '/activate-finolet',
        icon: <BsPiggyBank />,
      },
      {
        name: 'Activate Axis Bank',
        path: '/activate-axis-bank',
        icon: <AiFillBank />,
      },
      {
        name: 'Activate DMT KYC',
        path: '/activate-dmt',
        icon: <FcMoneyTransfer />,
      },
      {
        name: 'Activate EMI',
        path: '/activate-emi',
        icon: <BiMoney />,
      },
      {
        name: 'Activate AEPS Plus',
        path: '/activate-aeps-plus',
        icon: <BiTransfer />,
      },
    ],
  },
  {
    name: 'Top-Up Transfer',
    icon: <i className="bi bi-currency-rupee"></i>,
    submenu: [
      {
        name: 'Add Fund',
        path: '/top-up/add-fund',
        icon: <i className="bi bi-database-add"></i>,
      },
      {
        name: 'User List',
        path: '/top-up/list',
        icon: <LiaUserPlusSolid />,
      },
      {
        name: 'Get Adavance Funding',
        path: '/advance-fund',
        icon: <AiFillBank />,
      },
      {
        name: 'Transfer Report',
        path: '/top-up/report',
        icon: <i className="bi bi-card-checklist"></i>,
      },
      {
        name: 'Top-Up Timing',
        path: '/fund-timing',
        icon: <AiFillFile />,
      },
    ],
  },
  {
    name: 'MATM',
    icon: <i className='bi bi-credit-card'></i>,
    submenu: [
      {
        name: 'Order M-ATM',
        path: '/matm/order',
        icon: <MdOutlineAddCard />,
      },
      {
        name: 'All Order',
        path: '/matm/allorder',
        icon: <BsFillFileSpreadsheetFill />,
      },
      {
        name: 'Assign M-ATM',
        path: '/matm/assign',
        icon: <MdOutlineCreditScore />,
      },
    ],
  },
  {
    name: 'DMT Commission',
    icon: <BsCurrencyRupee />,
    submenu: [
      {
        name: 'Retailer List',
        path: '/dmt-commssion-list',
        icon: <PiUserListBold />,
      },
    ],
  },
  {
    name: 'Statement',
    icon: <FaUsers />,
    submenu: [
      {
        name: 'Commission',
        path: '/statement/commission',
        icon: <GoPersonAdd />,
      },
      {
        name: 'TXN Wise Commission ',
        path: '/statement/txn-commission',
        icon: <i className="bi bi-people"></i>,
      },
      {
        name: 'Wallet Statement',
        path: '/statement/wallet',
        icon: <BsQrCodeScan />,
      },
      {
        name: 'DMT Transaction',
        path: '/statement/dmt-transaction',
        icon: <AiFillFile />,
      },
      {
        name: 'AEPS Transaction',
        path: '/statement/aeps-transaction',
        icon: <BsPiggyBank />,
      },
      {
        name: 'Recharge Transaction',
        path: '/statement/recharge-transaction',
        icon: <AiFillBank />,
      },
      {
        name: 'Bill Payment Transaction',
        path: '/statement/bill-transaction',
        icon: <FcMoneyTransfer />,
      },
    ],
  },
  {
    name: 'My Profile',
    icon: <FaUserCircle />,
    submenu: [
      {
        name: 'Profile',
        path: '/profile',
        icon: <FaRegUserCircle />,
      },
      {
        name: 'Balance Certificate',
        path: '/profile/balance-certificate',
        icon: <i className="bi bi-people"></i>,
      },
      {
        name: 'Certificate',
        path: '/profile/certificate',
        icon: <BsQrCodeScan />,
      },
      {
        name: 'Change Password',
        path: '/profile/change-password',
        icon: <AiFillFile />,
      },
      {
        name: 'Commission Earned',
        path: '/profile/commission-earned',
        icon: <BsPiggyBank />,
      },
      {
        name: 'Invoices',
        path: '/profile/invoices',
        icon: <AiFillBank />,
      },
      {
        name: 'TDS Certificate',
        path: '/profile/tds-certificate',
        icon: <FcMoneyTransfer />,
      },
    ],
  },
  {
    name: 'Product',
    icon: <MdShoppingCart />,
    submenu: [
      {
        name: 'Product List',
        path: '/product/list',
        icon: <AiOutlineUnorderedList />,
      },
      {
        name: 'Order Status',
        path: '/product/order-status',
        icon: <MdOutlineShoppingCartCheckout />,
      },
    ],
  },
  {
    name: 'Activate Axis Card',
    path: '/distributor/activate-axis-card',
    icon: <i className="bi bi-palette"></i>,
  },
  {
    name: 'Logout',
    icon:  <i className="fas fa-sign-out-alt"></i>,
    path: '/'
  },
];

export const retailerSidebar = [
  {
    name: 'Dashboard',
    path: '/retailer-dashboard',
    icon: <i className="bi bi-palette"></i>,
  },
  {
    name: 'GST No.',
    path: '/gst-number',
    icon: <FiEdit />,
  },
  {
    name: 'Help & Support',
    path: '/support',
    icon: <MdSupport />,
  },
  {
    name: 'AEPS',
    path: '/aeps',
    icon: <AiOutlineTransaction />,
  },
  {
    name: ' DMT Service',
    icon: <PiMoneyDuotone />,
    submenu: [
      {
        name: 'Customer Login',
        path: '/dmt/customer-login',
        icon: <FiUserPlus />,
      },
      {
        name: 'Add Customer',
        path: '/dmt/add-customer',
        icon: <LiaUserPlusSolid />,
      },
      {
        name: 'Customers',
        path: '/dmt/all-customers',
        icon: <FaUsers />,
      },
      {
        name: 'Transfer Report',
        path: '/dmt/transfer-report',
        icon: <TbReportAnalytics />,
      },
      {
        name: 'Refund & Pending',
        path: '/dmt/refund-pending',
        icon: <RiRefund2Line />,
      },
    ],
  },
  {
    name: 'DMT Yesbank ',
    icon: <PiMoneyDuotone />,
    submenu: [
      {
        name: 'Money Transfer',
        path: '/dmt/yesbank/money-transfer',
        icon: <FiUserPlus />,
      },
      {
        name: 'Transfer Report',
        path: '/dmt/yesbank/transfer-report',
        icon: <TbReportAnalytics />,
      },
      {
        name: 'Refund & Pending',
        path: '/dmt/yesbank/refund-pending',
        icon: <RiRefund2Line />,
      },
    ],
  },
  {
    name: 'NEOFAM Wallet ',
    icon: <FaWallet />,
    path: '/neofam-wallet',
  },
  {
    name: 'AadharPay ',
    icon: <BsCurrencyRupee />,
    submenu: [
      {
        name: 'AadharPay',
        path: '/adharpay',
        icon: <BsCurrencyRupee />,
      },
      {
        name: 'Transaction Report',
        path: '/adharpay/transaction-report',
        icon: <TbReportAnalytics />,
      },
    ],
  },
  {
    name: 'EMI Collection',
    icon: <GiMoneyStack />,
    submenu: [
      {
        name: 'EMI Collection',
        path: '/emi-collection',
        icon: <GiMoneyStack />,
      },
      {
        name: 'Transaction Report',
        path: '/emi/transaction-report',
        icon: <TbReportAnalytics />,
      },
    ],
  },
  {
    name: 'Axis Bank',
    icon: <AiFillBank />,
    submenu: [
      {
        name: 'Axis Bank A/C',
        path: '/axis-bank-account-open',
        icon: <FaBookOpen />,
      },
    ],
  },
  {
    name: 'ICICI Cash Deposit',
    icon: <PiMoneyDuotone />,
    submenu: [
      {
        name: 'Cash Deposit',
        path: '/icici/cash-deposit',
        icon: <BsCurrencyRupee />,
      },
      {
        name: 'Transaction Report',
        path: '/icici/transaction-report',
        icon: <TbReportAnalytics />,
      },
    ],
  },
  {
    name: 'Recharge',
    icon: <BsFillLightningChargeFill />,
    submenu: [
      {
        name: 'Prepaid',
        path: '/recharge-prepaid',
        icon: <TbDeviceMobileDollar />,
      },
      {
        name: 'DTH',
        path: '/recharge-dth',
        icon: <PiTelevisionSimpleBold />,
      },
      {
        name: 'Transaction Report',
        path: '/recharge-report',
        icon: <TbReportAnalytics />,
      },
    ],
  },
  {
    name: 'Utility',
    icon: <LuUtilityPole />,
    submenu: [
      {
        name: 'BroadBand',
        path: '/utility/broadband',
        icon: <BiBroadcast />,
      },
      {
        name: 'Cable',
        path: '/utility/cable',
        icon: <MdCable />,
      },
      {
        name: 'Datacard Postpaid',
        path: '/utility/dataCard-postpaid',
        icon: <SiDatabricks />,
      },
      {
        name: 'Datacard Prepaid',
        path: '/utility/dataCard-prepaid',
        icon: <SiDatabricks />,
      },
      {
        name: 'Electricity',
        path: '/utility/electricity',
        icon: <BsFillLightningChargeFill />,
      },
      {
        name: 'EMI',
        path: '/utility/emi',
        icon: <GiPayMoney />,
      },
      {
        name: 'Gas',
        path: '/utility/gas',
        icon: <BiSolidGasPump />,
      },
      {
        name: 'Insurance',
        path: '/utility/insurance',
        icon: <PiShieldPlusBold />,
      },
      {
        name: 'Landline',
        path: '/utility/landline',
        icon: <TbDeviceLandlinePhone />,
      },
      {
        name: 'LPG',
        path: '/utility/lpg',
        icon: <BiSolidCylinder />,
      },
      {
        name: 'Municipality',
        path: '/utility/municipality',
        icon: <PiUsersFourFill />,
      },
      {
        name: 'Prepaid',
        path: '/recharge-prepaid',
        icon: <BsCurrencyRupee />,
      },
      // {
      //   name: 'PostPaid',
      //   path: '/dmt/add-customer',
      //   icon: <BsCurrencyRupee />,
      // },
      {
        name: 'Water',
        path: '/utility/water',
        icon: <GiTap />,
      },
      {
        name: 'Transaction Report',
        path: '/utility/transaction-report',
        icon: <TbReportAnalytics />,
      },
    ],
  },
  {
    name: 'FastTag',
    icon: <BiSolidTagAlt />,
    submenu: [
      {
        name: 'FastTag',
        path: '/fast-tag',
        icon: <BiSolidTagAlt />,
      },
      {
        name: 'Transaction Report',
        path: '/fast-tag/transaction-report',
        icon: <TbReportAnalytics />,
      },
    ],
  },
  {
    name: 'Credit Card Payment',
    icon: <MdOutlineCreditScore />,
    submenu: [
      {
        name: 'Make Payment',
        path: '/credit-card/bill',
        icon: <MdOutlineCreditScore />,
      },
      {
        name: 'Transaction Report',
        path: '/credit-card/transaction-report',
        icon: <TbReportAnalytics />,
      },
    ],
  },
  {
    name: 'QR Code',
    icon: <BsQrCodeScan />,
    submenu: [
      {
        name: 'Dowload QR-Code',
        path: '/qr-code',
        icon: <BsQrCodeScan />,
      },
      {
        name: 'Transaction Report',
        path: '/qr-code/transaction-report',
        icon: <TbReportAnalytics />,
      },
    ],
  },
  {
    name: 'NSDL Payment',
    icon: <BsCurrencyRupee />,
    submenu: [
      {
        name: 'Apply',
        path: '/nsdl/apply',
        icon: <MdOutlineAddCard />,
      },
      {
        name: 'Statement',
        path: '/nsdl/statement',
        icon: <TbReportAnalytics />,
      },
    ],
  },
  {
    name: 'Travel Booking',
    icon: <SiYourtraveldottv />,
    submenu: [
      {
        name: 'Book Now',
        path: '/book-now',
        icon: <SiYourtraveldottv />,
      },
    ],
  },
  {
    name: 'Finequs(Finolet) Loan',
    icon: <BsCurrencyRupee />,
    submenu: [
      {
        name: 'Apply Now',
        path: '/loan/apply',
        icon: <MdOutlineAddCard />,
      },
      {
        name: 'All Applied Loan',
        path: '/loan/all-loans',
        icon: <FaList />,
      },
    ],
  },
  {
    name: 'MATM',
    icon: <FaRegCreditCard />,
    submenu: [
      {
        name: 'Order Matm',
        path: '/retailer/order-matm',
        icon: <MdOutlineAddCard />,
      },
      {
        name: 'Activate Matm',
        path: '/retailer/activate-matm',
        icon: <FaRegCreditCard />,
      },
      
    ],
  },
  {
    name: 'Wallet',
    icon: <FaWallet />,
    submenu: [
      {
        name: 'Transaction Report',
        path: '/wallet/transaction-report',
        icon: <TbReportAnalytics />,
      },
    ],
  },
  {
    name: 'Pan Card',
    path: '/pancard',
    icon: <FaRegIdCard />,
  },
  {
    name: 'Payment gateway',
    icon: <FcMoneyTransfer />,
    submenu: [
      {
        name: 'PG',
        path: '/payment-gateway',
        icon: <FaMoneyBill />,
      },
      {
        name: 'Transfer Report',
        path: '/payment-gateway/transfer-report',
        icon: <TbReportAnalytics />,
      },
    ],
  },
  {
    name: 'Cash-In Settlement',
    icon: <GiCash />,
    submenu: [
      {
        name: 'Move to Bank',
        path: '/settlement/bank',
        icon: <GiPiggyBank />,
      },
      {
        name: 'Move to Distributor',
        path: '/settlement/distributor',
        icon: <RiUserShared2Fill />,
      },
      {
        name: 'Move to Main Wallet',
        path: '/settlement/main-wallet',
        icon: <FaWallet />,
      },
      {
        name: 'Transfer Report',
        path: '/settlement/transfer-report',
        icon: <TbReportAnalytics />,
      },
    ],
  },
  {
    name: 'Statements',
    icon: <TbReport />,
    submenu: [
      {
        name: 'Wallet Statement',
        path: '/statement/wallet',
        icon: <TbDeviceMobileDollar />,
      },
      {
        name: 'Cash In Statement',
        path: '/statement/cash-in',
        icon: <TbDeviceMobileDollar />,
      },
      {
        name: 'PG Ledger',
        path: '/statement/pg-ledger',
        icon: <TbReportAnalytics />,
      },
    ],
  },
  {
    name: 'My Profile',
    icon: <CgProfile />,
    submenu: [
      {
        name: 'Certificate',
        path: '/certificate',
        icon: <PiCertificateDuotone />,
      },
      {
        name: 'Commision Earned',
        path: '/profile/commision-earned',
        icon: <GiReceiveMoney />,
      },
      {
        name: 'My Profile',
        path: '/my-profile',
        icon: <CgProfile />,
      },
      {
        name: 'Change password',
        path: '/retailer/change-password',
        icon: <MdOutlinePassword />,
      },
      {
        name: 'Buisness Report',
        path: '/buisness-report',
        icon: <TbReportAnalytics />,
      },
      {
        name: 'Balance Report',
        path: '/balance-report',
        icon: <TbReportAnalytics />,
      },
      {
        name: 'Invoices',
        path: '/retailer/invoices',
        icon: <FaFileInvoiceDollar />,
      },
      {
        name: 'ID Card',
        path: '/profile/id-card',
        icon: <FaRegIdCard />,
      },
    ],
  },
  {
    name: 'Downloads',
    path: '/downloads',
    icon: <FaDownload />,
  },
  {
    name: 'Logout',
    icon:  <i className="fas fa-sign-out-alt"></i>,
    path: '/'
  },
]