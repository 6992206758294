import AdminMain from '../pages/admin/users/AdminMain.js';
import AdminDashboard from '../pages/admin/dashboard/AdminDashboard.js'
import UserDetail from '../pages/admin/users/User.js';
import BMList from '../pages/admin/bankmodule/List.js';
import BankDetail from '../pages/admin/users/BankDetail.js';
import UserFormElements from '../pages/admin/users/FormElements.js';
import MembershipPlans from '../pages/admin/membership/MembershipPlans.js';
import AepsSlab from '../pages/admin/aepsslabs/AepsSlab.js';
import EditAeps from '../pages/admin/aepsslabs/EditAeps.js';
import AepsTransactionHistory from '../pages/admin/transactionhistory/AepsTransactionHistory.js';
import DmtTransactionHistory from '../pages/admin/transactionhistory/DmtTransactionHistory.js';
import TopupTransactionHistory from '../pages/admin/transactionhistory/TopupTransactionHistory.js';
import AllMatm from '../pages/admin/matm/Matm.js';
import DistributorDashboard from '../pages/distributors/dashboard/DistributorDashboard.js';
import DistributorGst from '../pages/distributors/DistributorGst.js';
import Help from '../pages/distributors/Help.js';
import BulkOrders from '../pages/distributors/bulkorder/BulkOrders.js';
import FinoletLoan from '../pages/distributors/bulkorder/submenus/FinoletLoan.js';
import Emi from '../pages/distributors/bulkorder/submenus/Emi.js';
import Axis from '../pages/distributors/bulkorder/submenus/AxisBank.js';
import QrCode from '../pages/distributors/bulkorder/submenus/QrCode.js';
import AepsPlus from '../pages/distributors/bulkorder/submenus/AepsPlus.js';
import RetailerList from '../pages/distributors/retailers/RetailerList.js';
import Distributor from '../pages/distributors/retailers/AddRetailer.js';
import ActivateQr from '../pages/distributors/retailers/ActivateQr.js';
import ActivateNsdl from '../pages/distributors/retailers/ActivateNsdl.js';
import ActivateAxisBank from '../pages/distributors/retailers/ActivateAxisBank.js';
import ActivateFinolet from '../pages/distributors/retailers/ActivateFinolet.js';
import ActivateDmt from '../pages/distributors/retailers/ActivateDmt.js';
import ActivateEmi from '../pages/distributors/retailers/ActivateEmi.js';
import ActivateAepsPlus from '../pages/distributors/retailers/ActivateAepsPlus.js';
import AllFund from '../pages/distributors/getTopUp/AllFund.js';
import AccountDetails from '../pages/distributors/getTopUp/AccountDetails.js';
import TopUpTiming from '../pages/distributors/top-up/TopUpTiming.js';
import Statement from '../pages/distributors/getTopUp/Statement.js';
import AdvanceLimit from '../pages/distributors/getTopUp/AdvanceLimit.js';
import CommissionList from '../pages/distributors/dmtCommission/CommissionList.js';
import Commission from '../pages/distributors/statement/Commission.js';
import TxnCommission from '../pages/distributors/statement/TxnCommission.js';
import Wallet from '../pages/distributors/statement/Wallet.js';
import DmtCommission from '../pages/distributors/statement/DmtCommission.js';
import AepsCommission from '../pages/distributors/statement/AepsCommission.js';
import RechargeCommission from '../pages/distributors/statement/RechargeCommission.js';
import BillCommission from '../pages/distributors/statement/BillCommission.js';
import Profile from '../pages/distributors/myProfile/Profile.js';
import BalanceCert from '../pages/distributors/myProfile/BalanceCert.js';
import Password from '../pages/distributors/myProfile/Password.js';
import MyCommission from '../pages/distributors/myProfile/MyCommission.js';
import Invoices from '../pages/distributors/myProfile/Invoices.js';
import TdsCertificate from '../pages/distributors/myProfile/TdsCertificate.js';
import ProductList from '../pages/distributors/product/ProductList.js';
import OrderStatus from '../pages/distributors/product/OrderStatus.js';
import ActivateAxisCard from '../pages/distributors/AcivateAxisCard.js';
import Matm from '../pages/distributors/matmOrder/Matm.js';
import MatmAllOrder from '../pages/distributors/matmOrder/AllOrder.js';
import AssignMatm from '../pages/distributors/matmOrder/AssignMatm.js';
import AddFund from '../pages/distributors/top-up/AddFund.js';
import TopUp from '../pages/distributors/top-up/TopUp.js';
import User from '../pages/distributors/top-up/UserList.js';
import RetailerDetails from '../pages/distributors/RetailerDetail.js';
import RetailerDashboard from '../pages/retailer/dashboard/RetailerDashboard.js';
import GstNumber from '../pages/retailer/GstNumber.js';
import Support from '../pages/retailer/Support.js';
import AepsPage from '../pages/retailer/aeps/Aeps.js';
import ConfirmAeps from '../pages/retailer/aeps/Confirm.js';
import CustomerLogin from '../pages/retailer/dmt/CustomerLogin.js';
import Customer from '../pages/retailer/dmt/Customer.js';
import AllCustomers from '../pages/retailer/AllCustomers.js';
import AddCustomer from '../pages/retailer/dmt/AddCustomer.js';
import AddCustomerBank from '../pages/retailer/dmt/AddCustomerBank.js';
import ConfirmDmt from '../pages/retailer/dmt/ConfirmDmt.js';
import TransferReport from '../pages/retailer/dmt/TransferReport.js';
import Refund from '../pages/retailer/dmt/Refund.js';
import Yesbanktransfer from '../pages/retailer/yesbankDmt/Yesbanktransfer.js';
import YesbankReport from '../pages/retailer/yesbankDmt/YesbankReport.js';
import YesbankRefund from '../pages/retailer/yesbankDmt/YesbankRefund.js';
import NeoFam from '../pages/retailer/NeoFam.js';
import AdharPay from '../pages/retailer/adharpay/AdharPay.js';
import AdharpayReport from '../pages/retailer/adharpay/AdharpayReport.js';
import EmiCollection from '../pages/retailer/emi_collection/EmiCollection.js';
import EmiTransactionReport from '../pages/retailer/emi_collection/EmiTransactionReport.js';
import AxisbankAccount from '../pages/retailer/axisbank/AxisbankAccount.js';
import CashDeposit from '../pages/retailer/iciciCashDeposit/CashDeposit.js';
import IciciTransactionReport from '../pages/retailer/iciciCashDeposit/IciciTransactionReport.js';
import Prepaid from '../pages/retailer/recharge/Prepaid.js';
import Dth from '../pages/retailer/recharge/Dth.js';
import RechargeReport from '../pages/retailer/recharge/RechargeReport.js';
import Broadband from '../pages/retailer/utility/Broadband.js';
import Cable from '../pages/retailer/utility/Cable.js';
import Electricity from '../pages/retailer/utility/Electricity.js';
import UtilityEmi from '../pages/retailer/utility/UtilityEmi.js';
import Gas from '../pages/retailer/utility/Gas.js';
import Landline from '../pages/retailer/utility/Landline.js';
import Lpg from '../pages/retailer/utility/Lpg.js';
import Municipality from '../pages/retailer/utility/Municipality.js';
import Water from '../pages/retailer/utility/Water.js';
import UtilityTransactionReport from '../pages/retailer/utility/UtilityTransactionReport.js';
import FastTag from '../pages/retailer/fastTag/FastTag.js';
import FasttagTransactionReport from '../pages/retailer/fastTag/FasttagTransactionReport.js';
import CreditCardBill from '../pages/retailer/creditCard/CreditCardBill.js';
import CardTransactionReport from '../pages/retailer/creditCard/CardTransactionReport.js';
import DownloadQr from '../pages/retailer/qrcode/DownloadQr.js';
import QrTransactionReport from '../pages/retailer/qrcode/QrTransactionReport.js';
import ApplyNsdl from '../pages/retailer/nsdlPlan/ApplyNsdl.js';
import NsdlStatement from '../pages/retailer/nsdlPlan/NsdlStatement.js';
import BookNow from '../pages/retailer/travelbooking/BookNow.js';
import ApplyLoan from '../pages/retailer/finoletloan/ApplyLoan.js';
import { createBrowserRouter } from 'react-router-dom';
import HomePage from '../pages/home/HomePage.js';
import Login from '../pages/login/Login.js';
import Contact from '../components/Contact.js';
import PrivateRoute from '../privateroutes/PrivateRoute';
import WalletStatement from '../pages/retailer/statements/Wallet.js';
import CashIn from '../pages/retailer/statements/CashIn.js';
import PgLedger from '../pages/retailer/statements/PGLedger.js';
import EarnedCommision from '../pages/retailer/myprofile/EarnedCommision.js';
import Certificate from '../pages/retailer/myprofile/Certificate.js';
import RetailerProfile from '../pages/retailer/myprofile/RetailerProfile.js';
import RetailerPassword from '../pages/retailer/myprofile/RetailerPassword.js';
import Buisnessreport from '../pages/retailer/myprofile/Buisnessreport.js';
import BalanceCertificate from '../pages/retailer/myprofile/BalanceCertificate.js';
import RetailerInvoices from '../pages/retailer/myprofile/Invoices.js';
import IdCards from '../pages/retailer/myprofile/IdCards.js';
import Downloads from '../pages/retailer/Downloads.js';
import RetailerMatm from '../pages/retailer/matm/OrderMatmR.js';
import ActivateMatm from '../pages/retailer/matm/ActivateMatm.js';
import WalletTransferReport from '../pages/retailer/WalletTransferReport.js';
import PaymentG from '../pages/retailer/paymentGateway/PaymentG.js';
import PaymentTransfer from '../pages/retailer/paymentGateway/PaymentTransfer.js';
import SettleBank from '../pages/retailer/settlements/SettleBank.js';
import SettleDistributor from '../pages/retailer/settlements/SettleDistributor.js';
import SettleMainWallet from '../pages/retailer/settlements/SettleMainWallet.js';
import SettleTransferReport from '../pages/retailer/settlements/SettleTransferReport.js';
import Confirm from '../pages/retailer/adharpay/elements/Confirm.js';
import DatacardPostpaid from '../pages/retailer/utility/DatacardPostpaid.js';
import DatacardPrepaid from '../pages/retailer/utility/DatacardPrepaid.js';
import Insurance from '../pages/retailer/utility/Insurance.js';
import PanCard from '../pages/retailer/PanCard.jsx';

function loader() {
  return 'loading'
}

export const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <HomePage />
    },
    {
      path: "/master",
      element: <Login role="admin" />,
      role: "admin"
    },
    {
      path: "/dist-login",
      element: <Login role="distributor" />,
      role: "distributor"
    },
    {
      path: "/retailer-login",
      element: <Login role="retailer" />,
      role: "retailer"
    },
    {
      path: '*',
      element: <HomePage />
    },
    {
      element: <PrivateRoute allowedRoles={['admin']} />,
      path: "/",
      loader: loader,
      children: [
        {
          role: 'admin',
          path: "/contact",
          element: <Contact />
        },
        {
          role: 'distributor',
          path: "/contact",
          element: <Contact />
        },
        {
          role: 'retailer',
          path: "/contact",
          element: <Contact />
        },
        {
          role: 'admin',
          path: '/dashboard',
          element: <AdminDashboard />
        },
        {
          role: 'admin',
          path: '/users',
          element: <AdminMain />
        },
        {
          role: 'admin',
          path: '/users/:id',
          element: <UserDetail />
        },
        {
          role: 'admin',
          path: '/bank-module',
          element: <BMList />
        },
        {
          role: 'admin',
          path: '/bank-detail',
          element: <BankDetail />
        },
        {
          role: 'admin',
          path: '/user-form',
          element: <UserFormElements />
        },
        {
          role: 'admin',
          path: '/membership-plans',
          element: <MembershipPlans />
        },
        {
          role: 'admin',
          path: '/aeps-slab',
          element: <AepsSlab />
        },
        {
          role: 'admin',
          path: '/aeps-slab/edit/:id',
          element: <EditAeps />
        },
        {
          role: 'admin',
          path: '/transaction-history/aeps',
          element: <AepsTransactionHistory />
        },
        {
          role: 'admin',
          path: '/transaction-history/dmt',
          element: <DmtTransactionHistory />
        },
        {
          role: 'admin',
          path: '/transaction-history/topup',
          element: <TopupTransactionHistory />
        },
        {
          role: 'admin',
          path: '/orders/m-atm',
          element: <AllMatm />
        },
        // {
        //   role: 'distributor',
        //   path: '/distributor-dashboard',
        //   element: <DistributorDashboard />
        // },
        // {
        //   role: 'distributor',
        //   path: '/distributor/gst-number',
        //   element: <DistributorGst />
        // },
        // {
        //   role: 'distributor',
        //   path: '/distributor/support',
        //   element: <Help />
        // },
        // {
        //   role: 'distributor',
        //   path: '/bulk-orders',
        //   element: <BulkOrders />
        // },
        // {
        //   role: 'distributor',
        //   path: '/bulk-orders/finequs-loan',
        //   element: <FinoletLoan />
        // },
        // {
        //   role: 'distributor',
        //   path: '/bulk-orders/emi-id',
        //   element: <Emi />
        // },
        // {
        //   role: 'distributor',
        //   path: '/bulk-orders/axis-bank',
        //   element: <Axis />
        // },
        // {
        //   role: 'distributor',
        //   path: '/bulk-orders/qr-code',
        //   element: <QrCode />
        // },
        // {
        //   role: 'distributor',
        //   path: '/bulk-orders/aeps-plus',
        //   element: <AepsPlus />
        // },
        // {
        //   role: 'distributor',
        //   path: '/distributor/add-retailer',
        //   element: <Distributor />
        // },
        // {
        //   role: 'distributor',
        //   path: '/get-retailers',
        //   element: <RetailerList />
        // },
        // {
        //   role: 'distributor',
        //   path: '/activate-qr-code',
        //   element: <ActivateQr />
        // },
        // {
        //   role: 'distributor',
        //   path: '/activate-nsdl-plan',
        //   element: <ActivateNsdl />
        // },
        // {
        //   role: 'distributor',
        //   path: '/activate-axis-bank',
        //   element: <ActivateAxisBank />
        // },
        // {
        //   role: 'distributor',
        //   path: '/activate-finolet',
        //   element: <ActivateFinolet />
        // },
        // {
        //   role: 'distributor',
        //   path: '/activate-dmt',
        //   element: <ActivateDmt />
        // },
        // {
        //   role: 'distributor',
        //   path: '/activate-emi',
        //   element: <ActivateEmi />
        // },
        // {
        //   role: 'distributor',
        //   path: '/activate-aeps-plus',
        //   element: <ActivateAepsPlus />
        // },
        // {
        //   role: 'distributor',
        //   path: '/all-top-up',
        //   element: <AllFund />
        // },
        // {
        //   role: 'distributor',
        //   path: '/account-details',
        //   element: <AccountDetails />
        // },
        // {
        //   role: 'distributor',
        //   path: '/fund-timing',
        //   element: <TopUpTiming />
        // },
        // {
        //   role: 'distributor',
        //   path: '/statement',
        //   element: <Statement />
        // },
        // {
        //   role: 'distributor',
        //   path: '/advance-fund',
        //   element: <AdvanceLimit />
        // },
        // {
        //   role: 'distributor',
        //   path: '/dmt-commssion-list',
        //   element: <CommissionList />
        // },
        // {
        //   role: 'distributor',
        //   path: '/statement/commission',
        //   element: <Commission />
        // },
        // {
        //   role: 'distributor',
        //   path: '/statement/txn-commission',
        //   element: <TxnCommission />
        // },
        // {
        //   role: 'distributor',
        //   path: '/statement/wallet',
        //   element: <Wallet />
        // },
        // {
        //   role: 'distributor',
        //   path: '/statement/dmt-transaction',
        //   element: <DmtCommission />
        // },
        // {
        //   role: 'distributor',
        //   path: '/statement/aeps-transaction',
        //   element: <AepsCommission />
        // },
        // {
        //   role: 'distributor',
        //   path: '/statement/recharge-transaction',
        //   element: <RechargeCommission />
        // },
        // {
        //   role: 'distributor',
        //   path: '/statement/bill-transaction',
        //   element: <BillCommission />
        // },
        // {
        //   role: 'distributor',
        //   path: '/profile',
        //   element: <Profile />
        // },
        // {
        //   role: 'distributor',
        //   path: '/profile/balance-certificate',
        //   element: <BalanceCert />
        // },
        // {
        //   role: 'distributor',
        //   path: '/profile/certificate',
        //   element: <Wallet />
        // },
        // {
        //   role: 'distributor',
        //   path: '/profile/change-password',
        //   element: <Password />
        // },
        // {
        //   role: 'distributor',
        //   path: '/profile/commission-earned',
        //   element: <MyCommission />
        // },
        // {
        //   role: 'distributor',
        //   path: '/profile/invoices',
        //   element: <Invoices />
        // },
        // {
        //   role: 'distributor',
        //   path: '/profile/tds-certificate',
        //   element: <TdsCertificate />
        // },
        // {
        //   role: 'distributor',
        //   path: '/product/list',
        //   element: <ProductList />
        // },
        // {
        //   role: 'distributor',
        //   path: '/product/order-status',
        //   element: <OrderStatus />
        // },
        // {
        //   role: 'distributor',
        //   path: '/distributor/activate-axis-card',
        //   element: <ActivateAxisCard />
        // },
        // {
        //   role: 'distributor',
        //   path: '/matm/order',
        //   element: <Matm />
        // },
        // {
        //   role: 'distributor',
        //   path: '/matm/allorder',
        //   element: <MatmAllOrder />
        // },
        // {
        //   role: 'distributor',
        //   path: '/matm/assign',
        //   element: <AssignMatm />
        // },
        // {
        //   role: 'distributor',
        //   path: '/top-up/add-fund',
        //   element: <AddFund />
        // },
        // {
        //   role: 'distributor',
        //   path: '/top-up/report',
        //   element: <TopUp />
        // },
        // {
        //   role: 'distributor',
        //   path: '/top-up/list',
        //   element: <User />
        // },
        // {
        //   role: 'distributor',
        //   path: '/retailer-details/:id',
        //   element: <RetailerDetails />
        // },
        // {
        //   role: 'retailer',
        //   path: '/retailer-dashboard',
        //   element: <RetailerDashboard />
        // },
        // {
        //   role: 'retailer',
        //   path: '/gst-number',
        //   element: <GstNumber />
        // },
        // {
        //   role: 'retailer',
        //   path: '/support',
        //   element: <Support />
        // },
        // {
        //   role: 'retailer',
        //   path: '/aeps',
        //   element: <AepsPage />
        // },
        // {
        //   role: 'retailer',
        //   path: '/aeps/confirm',
        //   element: <ConfirmAeps />
        // },
        // {
        //   role: 'retailer',
        //   path: '/dmt/customer-login',
        //   element: <CustomerLogin />
        // },
        // {
        //   role: 'retailer',
        //   path: '/dmt/customer-detail',
        //   element: <Customer />
        // },
        // {
        //   role: 'retailer',
        //   path: '/dmt/all-customers',
        //   element: <AllCustomers />
        // },
        // {
        //   role: 'retailer',
        //   path: '/dmt/add-customer',
        //   element: <AddCustomer />
        // },
        // {
        //   role: 'retailer',
        //   path: '',
        //   element: ''
        // },
        // {
        //   role: 'retailer',
        //   path: '/add-bank',
        //   element: <AddCustomerBank />
        // },
        // {
        //   role: 'retailer',
        //   path: '/dmt/confirm-txns',
        //   element: <ConfirmDmt />
        // },
        // {
        //   role: 'retailer',
        //   path: '/dmt/transfer-report',
        //   element: <TransferReport />
        // },
        // {
        //   role: 'retailer',
        //   path: '/dmt/refund-pending',
        //   element: <Refund />
        // },
        // {
        //   role: 'retailer',
        //   path: '/dmt/yesbank/money-transfer',
        //   element: <Yesbanktransfer />
        // },
        // {
        //   role: 'retailer',
        //   path: '/dmt/yesbank/transfer-report',
        //   element: <YesbankReport />
        // },
        // {
        //   role: 'retailer',
        //   path: '/dmt/yesbank/refund-pending',
        //   element: <YesbankRefund />
        // },
        // {
        //   role: 'retailer',
        //   path: '/neofam-wallet',
        //   element: <NeoFam />
        // },
        // {
        //   role: 'retailer',
        //   path: '/adharpay',
        //   element: <AdharPay />
        // },
        // {
        //   role: 'retailer',
        //   path: '/adharpay/transaction-report',
        //   element: <AdharpayReport />
        // },
        // {
        //   role: 'retailer',
        //   path: '/aadharpay/confirm',
        //   element: <Confirm />
        // },
        // {
        //   role: 'retailer',
        //   path: '/emi-collection',
        //   element: <EmiCollection />
        // },
        // {
        //   role: 'retailer',
        //   path: '/emi/transaction-report',
        //   element: <EmiTransactionReport />
        // },
        // {
        //   role: 'retailer',
        //   path: '/axis-bank-account-open',
        //   element: <AxisbankAccount />
        // },
        // {
        //   role: 'retailer',
        //   path: '/icici/cash-deposit',
        //   element: <CashDeposit />
        // },
        // {
        //   role: 'retailer',
        //   path: '/icici/transaction-report',
        //   element: <IciciTransactionReport />
        // },
        // {
        //   role: 'retailer',
        //   path: '/recharge-prepaid',
        //   element: <Prepaid />
        // },
        // {
        //   role: 'retailer',
        //   path: '/recharge-dth',
        //   element: <Dth />
        // },
        // {
        //   role: 'retailer',
        //   path: '/recharge-report',
        //   element: <RechargeReport />
        // },
        // {
        //   role: 'retailer',
        //   path: '/utility/broadband',
        //   element: <Broadband />
        // },
        // {
        //   role: 'retailer',
        //   path: '/utility/cable',
        //   element: <Cable />
        // },
        // {
        //   role: 'retailer',
        //   path: '/utility/dataCard-postpaid',
        //   element: <DatacardPostpaid />
        // },
        // {
        //   role: 'retailer',
        //   path: '/utility/dataCard-prepaid',
        //   element: <DatacardPrepaid />
        // },
        // {
        //   role: 'retailer',
        //   path: '/utility/electricity',
        //   element: <Electricity />
        // },
        // {
        //   role: 'retailer',
        //   path: '/utility/emi',
        //   element: <UtilityEmi />
        // },
        // {
        //   role: 'retailer',
        //   path: '/utility/gas',
        //   element: <Gas />
        // },
        // {
        //   role: 'retailer',
        //   path: '/utility/insurance',
        //   element: <Insurance />
        // },
        // {
        //   role: 'retailer',
        //   path: '/utility/landline',
        //   element: <Landline />
        // },
        // {
        //   role: 'retailer',
        //   path: '/utility/lpg',
        //   element: <Lpg />
        // },
        // {
        //   role: 'retailer',
        //   path: '/utility/municipality',
        //   element: <Municipality />
        // },
        // {
        //   role: 'retailer',
        //   path: '/utility/water',
        //   element: <Water />
        // },
        // {
        //   role: 'retailer',
        //   path: '/utility/transaction-report',
        //   element: <UtilityTransactionReport />
        // },
        // {
        //   role: 'retailer',
        //   path: '/fast-tag',
        //   element: <FastTag />
        // },
        // {
        //   role: 'retailer',
        //   path: '/fast-tag/transaction-report',
        //   element: <FasttagTransactionReport />
        // },
        // {
        //   role: 'retailer',
        //   path: '/credit-card/bill',
        //   element: <CreditCardBill />
        // },
        // {
        //   role: 'retailer',
        //   path: '/credit-card/transaction-report',
        //   element: <CardTransactionReport />
        // },
        // {
        //   role: 'retailer',
        //   path: '/qr-code',
        //   element: <DownloadQr />
        // },
        // {
        //   role: 'retailer',
        //   path: '/qr-code/transaction-report',
        //   element: <QrTransactionReport />
        // },
        // {
        //   role: 'retailer',
        //   path: '/nsdl/apply',
        //   element: <ApplyNsdl />
        // },
        // {
        //   role: 'retailer',
        //   path: '/nsdl/statement',
        //   element: <NsdlStatement />
        // },
        // {
        //   role: 'retailer',
        //   path: '/book-now',
        //   element: <BookNow />
        // },
        // {
        //   role: 'retailer',
        //   path: '/loan/apply',
        //   element: <ApplyLoan />
        // },
        // {
        //   role: 'retailer',
        //   path: '/loan/all-loans',
        //   element: <ApplyLoan />
        // },
        // {
        //   role: 'retailer',
        //   path: '/retailer/order-matm',
        //   element: <RetailerMatm />
        // },
        // {
        //   role: 'retailer',
        //   path: '/retailer/activate-matm',
        //   element: <ActivateMatm />
        // },
        // {
        //   role: 'retailer',
        //   path: '/wallet/transaction-report',
        //   element: <WalletTransferReport />
        // },
        // {
        //   role: 'retailer',
        //   path: '/pancard',
        //   element: <PanCard />
        // },
        // {
        //   role: 'retailer',
        //   path: '/payment-gateway',
        //   element: <PaymentG />
        // },
        // {
        //   role: 'retailer',
        //   path: '/payment-gateway/transfer-report',
        //   element: <PaymentTransfer />
        // },
        // {
        //   role: 'retailer',
        //   path: '/settlement/bank',
        //   element: <SettleBank />
        // },
        // {
        //   role: 'retailer',
        //   path: '/settlement/distributor',
        //   element: <SettleDistributor />
        // },
        // {
        //   role: 'retailer',
        //   path: '/settlement/main-wallet',
        //   element: <SettleMainWallet />
        // },
        // {
        //   role: 'retailer',
        //   path: '/settlement/transfer-report',
        //   element: <SettleTransferReport />
        // },
        // {
        //   role: 'retailer',
        //   path: '/statement/wallet',
        //   element: <WalletStatement />
        // },
        // {
        //   role: 'retailer',
        //   path: '/statement/cash-in',
        //   element: <CashIn />
        // },
        // {
        //   role: 'retailer',
        //   path: '/statement/pg-ledger',
        //   element: <PgLedger />
        // },
        // {
        //   role: 'retailer',
        //   path: '/profile/commision-earned',
        //   element: <EarnedCommision />
        // },
        // {
        //   role: 'retailer',
        //   path: '/certificate',
        //   element: <Certificate />
        // },
        // {
        //   role: 'retailer',
        //   path: '/my-profile',
        //   element: <RetailerProfile />
        // },
        // {
        //   role: 'retailer',
        //   path: '/retailer/change-password',
        //   element: <RetailerPassword />
        // },
        // {
        //   role: 'retailer',
        //   path: '/buisness-report',
        //   element: <Buisnessreport />
        // },
        // {
        //   role: 'retailer',
        //   path: '/balance-report',
        //   element: <BalanceCertificate />
        // },
        // {
        //   role: 'retailer',
        //   path: '/retailer/invoices',
        //   element: <RetailerInvoices />
        // },
        // {
        //   role: 'retailer',
        //   path: '/profile/id-card',
        //   element: <IdCards />
        // },
        // {
        //   role: 'retailer',
        //   path: '/downloads',
        //   element: <Downloads />
        // },
      ]
    },
    {
      element: <PrivateRoute allowedRoles={['distributor']} />,
      path: "/",
      loader: loader,
      children: [
        {
          role: 'distributor',
          path: '/distributor-dashboard',
          element: <DistributorDashboard />
        },
        {
          role: 'distributor',
          path: '/distributor/gst-number',
          element: <DistributorGst />
        },
        {
          role: 'distributor',
          path: '/distributor/support',
          element: <Help />
        },
        {
          role: 'distributor',
          path: '/bulk-orders',
          element: <BulkOrders />
        },
        {
          role: 'distributor',
          path: '/bulk-orders/finequs-loan',
          element: <FinoletLoan />
        },
        {
          role: 'distributor',
          path: '/bulk-orders/emi-id',
          element: <Emi />
        },
        {
          role: 'distributor',
          path: '/bulk-orders/axis-bank',
          element: <Axis />
        },
        {
          role: 'distributor',
          path: '/bulk-orders/qr-code',
          element: <QrCode />
        },
        {
          role: 'distributor',
          path: '/bulk-orders/aeps-plus',
          element: <AepsPlus />
        },
        {
          role: 'distributor',
          path: '/distributor/add-retailer',
          element: <Distributor />
        },
        {
          role: 'distributor',
          path: '/get-retailers',
          element: <RetailerList />
        },
        {
          role: 'distributor',
          path: '/activate-qr-code',
          element: <ActivateQr />
        },
        {
          role: 'distributor',
          path: '/activate-nsdl-plan',
          element: <ActivateNsdl />
        },
        {
          role: 'distributor',
          path: '/activate-axis-bank',
          element: <ActivateAxisBank />
        },
        {
          role: 'distributor',
          path: '/activate-finolet',
          element: <ActivateFinolet />
        },
        {
          role: 'distributor',
          path: '/activate-dmt',
          element: <ActivateDmt />
        },
        {
          role: 'distributor',
          path: '/activate-emi',
          element: <ActivateEmi />
        },
        {
          role: 'distributor',
          path: '/activate-aeps-plus',
          element: <ActivateAepsPlus />
        },
        {
          role: 'distributor',
          path: '/all-top-up',
          element: <AllFund />
        },
        {
          role: 'distributor',
          path: '/account-details',
          element: <AccountDetails />
        },
        {
          role: 'distributor',
          path: '/fund-timing',
          element: <TopUpTiming />
        },
        {
          role: 'distributor',
          path: '/statement',
          element: <Statement />
        },
        {
          role: 'distributor',
          path: '/advance-fund',
          element: <AdvanceLimit />
        },
        {
          role: 'distributor',
          path: '/dmt-commssion-list',
          element: <CommissionList />
        },
        {
          role: 'distributor',
          path: '/statement/commission',
          element: <Commission />
        },
        {
          role: 'distributor',
          path: '/statement/txn-commission',
          element: <TxnCommission />
        },
        {
          role: 'distributor',
          path: '/statement/wallet',
          element: <Wallet />
        },
        {
          role: 'distributor',
          path: '/statement/dmt-transaction',
          element: <DmtCommission />
        },
        {
          role: 'distributor',
          path: '/statement/aeps-transaction',
          element: <AepsCommission />
        },
        {
          role: 'distributor',
          path: '/statement/recharge-transaction',
          element: <RechargeCommission />
        },
        {
          role: 'distributor',
          path: '/statement/bill-transaction',
          element: <BillCommission />
        },
        {
          role: 'distributor',
          path: '/profile',
          element: <Profile />
        },
        {
          role: 'distributor',
          path: '/profile/balance-certificate',
          element: <BalanceCert />
        },
        {
          role: 'distributor',
          path: '/profile/certificate',
          element: <Wallet />
        },
        {
          role: 'distributor',
          path: '/profile/change-password',
          element: <Password />
        },
        {
          role: 'distributor',
          path: '/profile/commission-earned',
          element: <MyCommission />
        },
        {
          role: 'distributor',
          path: '/profile/invoices',
          element: <Invoices />
        },
        {
          role: 'distributor',
          path: '/profile/tds-certificate',
          element: <TdsCertificate />
        },
        {
          role: 'distributor',
          path: '/product/list',
          element: <ProductList />
        },
        {
          role: 'distributor',
          path: '/product/order-status',
          element: <OrderStatus />
        },
        {
          role: 'distributor',
          path: '/distributor/activate-axis-card',
          element: <ActivateAxisCard />
        },
        {
          role: 'distributor',
          path: '/matm/order',
          element: <Matm />
        },
        {
          role: 'distributor',
          path: '/matm/allorder',
          element: <MatmAllOrder />
        },
        {
          role: 'distributor',
          path: '/matm/assign',
          element: <AssignMatm />
        },
        {
          role: 'distributor',
          path: '/top-up/add-fund',
          element: <AddFund />
        },
        {
          role: 'distributor',
          path: '/top-up/report',
          element: <TopUp />
        },
        {
          role: 'distributor',
          path: '/top-up/list',
          element: <User />
        },
        {
          role: 'distributor',
          path: '/retailer-details/:id',
          element: <RetailerDetails />
        },
      ]
    },
    {
      element: <PrivateRoute allowedRoles={['retailer']} />,
      path: "/",
      loader: loader,
      children: [
        {
          role: 'retailer',
          path: '/retailer-dashboard',
          element: <RetailerDashboard />
        },
        {
          role: 'retailer',
          path: '/gst-number',
          element: <GstNumber />
        },
        {
          role: 'retailer',
          path: '/support',
          element: <Support />
        },
        {
          role: 'retailer',
          path: '/aeps',
          element: <AepsPage />
        },
        {
          role: 'retailer',
          path: '/aeps/confirm',
          element: <ConfirmAeps />
        },
        {
          role: 'retailer',
          path: '/dmt/customer-login',
          element: <CustomerLogin />
        },
        {
          role: 'retailer',
          path: '/dmt/customer-detail',
          element: <Customer />
        },
        {
          role: 'retailer',
          path: '/dmt/all-customers',
          element: <AllCustomers />
        },
        {
          role: 'retailer',
          path: '/dmt/add-customer',
          element: <AddCustomer />
        },
        {
          role: 'retailer',
          path: '',
          element: ''
        },
        {
          role: 'retailer',
          path: '/add-bank',
          element: <AddCustomerBank />
        },
        {
          role: 'retailer',
          path: '/dmt/confirm-txns',
          element: <ConfirmDmt />
        },
        {
          role: 'retailer',
          path: '/dmt/transfer-report',
          element: <TransferReport />
        },
        {
          role: 'retailer',
          path: '/dmt/refund-pending',
          element: <Refund />
        },
        {
          role: 'retailer',
          path: '/dmt/yesbank/money-transfer',
          element: <Yesbanktransfer />
        },
        {
          role: 'retailer',
          path: '/dmt/yesbank/transfer-report',
          element: <YesbankReport />
        },
        {
          role: 'retailer',
          path: '/dmt/yesbank/refund-pending',
          element: <YesbankRefund />
        },
        {
          role: 'retailer',
          path: '/neofam-wallet',
          element: <NeoFam />
        },
        {
          role: 'retailer',
          path: '/adharpay',
          element: <AdharPay />
        },
        {
          role: 'retailer',
          path: '/adharpay/transaction-report',
          element: <AdharpayReport />
        },
        {
          role: 'retailer',
          path: '/aadharpay/confirm',
          element: <Confirm />
        },
        {
          role: 'retailer',
          path: '/emi-collection',
          element: <EmiCollection />
        },
        {
          role: 'retailer',
          path: '/emi/transaction-report',
          element: <EmiTransactionReport />
        },
        {
          role: 'retailer',
          path: '/axis-bank-account-open',
          element: <AxisbankAccount />
        },
        {
          role: 'retailer',
          path: '/icici/cash-deposit',
          element: <CashDeposit />
        },
        {
          role: 'retailer',
          path: '/icici/transaction-report',
          element: <IciciTransactionReport />
        },
        {
          role: 'retailer',
          path: '/recharge-prepaid',
          element: <Prepaid />
        },
        {
          role: 'retailer',
          path: '/recharge-dth',
          element: <Dth />
        },
        {
          role: 'retailer',
          path: '/recharge-report',
          element: <RechargeReport />
        },
        {
          role: 'retailer',
          path: '/utility/broadband',
          element: <Broadband />
        },
        {
          role: 'retailer',
          path: '/utility/cable',
          element: <Cable />
        },
        {
          role: 'retailer',
          path: '/utility/dataCard-postpaid',
          element: <DatacardPostpaid />
        },
        {
          role: 'retailer',
          path: '/utility/dataCard-prepaid',
          element: <DatacardPrepaid />
        },
        {
          role: 'retailer',
          path: '/utility/electricity',
          element: <Electricity />
        },
        {
          role: 'retailer',
          path: '/utility/emi',
          element: <UtilityEmi />
        },
        {
          role: 'retailer',
          path: '/utility/gas',
          element: <Gas />
        },
        {
          role: 'retailer',
          path: '/utility/insurance',
          element: <Insurance />
        },
        {
          role: 'retailer',
          path: '/utility/landline',
          element: <Landline />
        },
        {
          role: 'retailer',
          path: '/utility/lpg',
          element: <Lpg />
        },
        {
          role: 'retailer',
          path: '/utility/municipality',
          element: <Municipality />
        },
        {
          role: 'retailer',
          path: '/utility/water',
          element: <Water />
        },
        {
          role: 'retailer',
          path: '/utility/transaction-report',
          element: <UtilityTransactionReport />
        },
        {
          role: 'retailer',
          path: '/fast-tag',
          element: <FastTag />
        },
        {
          role: 'retailer',
          path: '/fast-tag/transaction-report',
          element: <FasttagTransactionReport />
        },
        {
          role: 'retailer',
          path: '/credit-card/bill',
          element: <CreditCardBill />
        },
        {
          role: 'retailer',
          path: '/credit-card/transaction-report',
          element: <CardTransactionReport />
        },
        {
          role: 'retailer',
          path: '/qr-code',
          element: <DownloadQr />
        },
        {
          role: 'retailer',
          path: '/qr-code/transaction-report',
          element: <QrTransactionReport />
        },
        {
          role: 'retailer',
          path: '/nsdl/apply',
          element: <ApplyNsdl />
        },
        {
          role: 'retailer',
          path: '/nsdl/statement',
          element: <NsdlStatement />
        },
        {
          role: 'retailer',
          path: '/book-now',
          element: <BookNow />
        },
        {
          role: 'retailer',
          path: '/loan/apply',
          element: <ApplyLoan />
        },
        {
          role: 'retailer',
          path: '/loan/all-loans',
          element: <ApplyLoan />
        },
        {
          role: 'retailer',
          path: '/retailer/order-matm',
          element: <RetailerMatm />
        },
        {
          role: 'retailer',
          path: '/retailer/activate-matm',
          element: <ActivateMatm />
        },
        {
          role: 'retailer',
          path: '/wallet/transaction-report',
          element: <WalletTransferReport />
        },
        {
          role: 'retailer',
          path: '/pancard',
          element: <PanCard />
        },
        {
          role: 'retailer',
          path: '/payment-gateway',
          element: <PaymentG />
        },
        {
          role: 'retailer',
          path: '/payment-gateway/transfer-report',
          element: <PaymentTransfer />
        },
        {
          role: 'retailer',
          path: '/settlement/bank',
          element: <SettleBank />
        },
        {
          role: 'retailer',
          path: '/settlement/distributor',
          element: <SettleDistributor />
        },
        {
          role: 'retailer',
          path: '/settlement/main-wallet',
          element: <SettleMainWallet />
        },
        {
          role: 'retailer',
          path: '/settlement/transfer-report',
          element: <SettleTransferReport />
        },
        {
          role: 'retailer',
          path: '/statement/wallet',
          element: <WalletStatement />
        },
        {
          role: 'retailer',
          path: '/statement/cash-in',
          element: <CashIn />
        },
        {
          role: 'retailer',
          path: '/statement/pg-ledger',
          element: <PgLedger />
        },
        {
          role: 'retailer',
          path: '/profile/commision-earned',
          element: <EarnedCommision />
        },
        {
          role: 'retailer',
          path: '/certificate',
          element: <Certificate />
        },
        {
          role: 'retailer',
          path: '/my-profile',
          element: <RetailerProfile />
        },
        {
          role: 'retailer',
          path: '/retailer/change-password',
          element: <RetailerPassword />
        },
        {
          role: 'retailer',
          path: '/buisness-report',
          element: <Buisnessreport />
        },
        {
          role: 'retailer',
          path: '/balance-report',
          element: <BalanceCertificate />
        },
        {
          role: 'retailer',
          path: '/retailer/invoices',
          element: <RetailerInvoices />
        },
        {
          role: 'retailer',
          path: '/profile/id-card',
          element: <IdCards />
        },
        {
          role: 'retailer',
          path: '/downloads',
          element: <Downloads />
        },
      ]
    }
  ]
);

  {/* 
        <Route path="otpverification"
          element={
            <React.Suspense fallback={<>...</>}>
              <PrivateRoute role={'admin'}>
                <OtpVerification />
              </PrivateRoute>
            </React.Suspense>
          } /> */}