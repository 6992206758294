import React from 'react';
import styles from './Services.module.scss';
import dmt from '../../assets/images/moneytransaction.png';
import matm from '../../assets/images/matm.png';
import aeps from '../../assets/images/aeps.png'

const Services = () => {
  return (
    <div className='container-fluid px-4 py-5'>
      <h1 className='text-white'>Our Services</h1>
      <div className={styles.primaryBackground}>
        <div className='p-md-5'>
          <div className='row'>
            <div className='col-md-3'>
              <div className={styles.icon}>
                <img src={dmt} className='w-50' />
              </div>
              <div className={styles.border}>
                <h1 className="font-nova fw-bold">DMT</h1>
                <p className='text-secondary font-merriweather'>
                Domestic Money Transfer (DMT) facilitates quick, secure, and cost-effective transfer of money within a country, enabling financial inclusion and enhancing access to various banking services.
                </p>
              </div>
            </div>
            <div className='col-md-3'>
              <div className={styles.icon}>
                <img src={aeps} className='w-50' />
              </div>
              <div className={styles.border}>
                <h1 className="font-nova fw-bold">AEPS</h1>
                <p className='text-secondary font-merriweather'>AEPS (Aadhaar Enabled Payment System) empowers financial transactions using Aadhaar authentication, ensuring secure, convenient, and inclusive digital banking services.</p>
              </div>
            </div>
            <div className='col-md-3'>
              <div className={styles.icon}>
                <img src={matm} className='w-50' />
              </div>
              <div className={styles.border}>
                <h1 className="font-nova fw-bold">M-ATM</h1>
                <p className='text-secondary font-merriweather'>
                Micro ATMs (MATM) are devices that provide banking services like cash withdrawals, deposits, and balance inquiries, especially in remote areas, ensuring financial accessibility.
                </p>
              </div>
            </div>
            <div className='col-md-3'>
              <div className={styles.icon}>
                <img src={aeps} className='w-50' />
              </div>
              <div className={styles.border}>
                <h1 className='font-nova fw-bold'>Fast Tag</h1>
                <p className='text-secondary font-merriweather'>AEPS (Aadhaar Enabled Payment System) empowers financial transactions using Aadhaar authentication, ensuring secure, convenient, and inclusive digital banking services.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services