import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import { Form, Formik } from 'formik'
import FormikControl from '../../../components/FormikControl';
import { BsFillEyeFill } from 'react-icons/bs';
import { CardHeader } from '../../../components/sections/CardHeader';
import { ToastContainer, toast } from 'react-toastify';
import { creditCardBillSchema } from '../validation'

const CreditCardBill = () => {
  const initialValues = {
    card_number: "",    
  }

  const handleSubmit = (value, { resetForm }) => {
    toast.success('Data read successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    console.log('submit button clicked');
    resetForm()
  }

  return (
    <div className='content-wrapper'>
      <ToastContainer/>
      <Breadcrumbs title='Credit Card Bill' path='/retailer-dashboard' />
      <div className='container'>
        <div className='card'>
          <CardHeader title="Credit Card Bill" />
          <div className='card-body'>
              <Formik 
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={creditCardBillSchema}
                validateOnBlur={false}
                validateOnChange={false}
              >
                <Form>
                  <div className='d-md-flex'>
                    <div className='w-100'>
                    <FormikControl
                      control='input'
                      label='Credit Card Number'
                      placeholder='Enter credit card number (only visa card accepted here)'
                      name='card_number'
                    />
                    </div>
                    <div className='w-100 m-auto pl-5'>
                      <button className='btn btn-secondary rounded-pill' type='submit'>
                        View Bill <BsFillEyeFill />
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreditCardBill