import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { UpdateStatus, getRetailerById } from '../../api/instance_method';
import { ToastContainer, toast } from 'react-toastify';
import Edit from './retailers/Edit';
import FormikControl from '../../components/FormikControl';
import { Form, Formik } from 'formik';
import { userStatus } from '../../helpers/constant';
import Breadcrumbs from '../../components/sections/Breadcrumbs';

const RetailerDetails = (props) => {
  const [user, setUser] = useState([]);
  const [error, setError] = useState('');
  const [userId, setUserId] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [usersStatus, setUserStatus] = useState('');
  const navigate = useNavigate();
  //const {id} = useParams();
  const { id } = useParams();
  const value = {
    id: id
  }
  const initialValues = {
    status: '',
    id: id
  }
  useEffect(() => {
    getRetailerById(value).then(
      (response) => {
        console.log(response.data)
        setUser(response.data.data)
      }
    )
  }, [isOpen, usersStatus]);
  const openModal = (id) => {
    setUserId(id)
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleStatus = (val) => {
    console.log(val)
 
    UpdateStatus(val).then(
      (response) => {
        toast.success('Status Changed Successfully')
        setUserStatus(val)
        setTimeout(() => {
          navigate('/get-retailers')
        }, 4000)
      }
    )
  }
  return (
    <div className='content-wrapper'>
      <ToastContainer position="top-center" />
      {isOpen && (
        <>
          <div className='overlay'></div>
          <Edit closeModal={closeModal} user={user} />
        </>
      )}
      {
        user ? (
          <div className=''>
            <div className='margin-header-table'>
            <Breadcrumbs title='User Details' path='/distributor-dashboard' />

              <div className='text-center'>
                <div className=''>
                  <div className='d-flex justify-content-center mx-5 mt-3'>
                    <table className="table table-hover table-bordered mb-5 caption-top">
                      <caption className='caption-top'>
                        <div className='d-flex justify-content-between'>
                          <h4>
                            {/* <AccountCircleIcon /> */}
                            &nbsp;{user.first_name}&nbsp; </h4>
                          <div className='d-flex'>
                            <span className='badge badge-success'>
                              {user.status}</span>&nbsp;&nbsp;
                              <button className='btn-info badge' onClick={() => openModal(user)}>
                              Edit
                            </button>&nbsp;&nbsp;
                            <div>
                            </div>
                          </div>
                        </div>
                      </caption>

                      <tbody>
                        <tr>
                          <th scope="row">First Name</th>
                          <td>{user.first_name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Middle Name</th>
                          <td>{user.middle_name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Last Name</th>
                          <td>{user.last_name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Email</th>
                          <td>{user.email}</td>
                        </tr>
                        <tr>
                          <th scope="row">Mobile Number</th>
                          <td>{user.mobile_number}</td>
                        </tr>
                        <tr>
                          <th scope="row">Address One</th>
                          <td>{user.address_one}</td>
                        </tr>
                        <tr>
                          <th scope="row">Address two</th>
                          <td>{user.address_second}</td>
                        </tr>
                        <tr>
                          <th scope="row">Pan Number</th>
                          <td>{user.pan}</td>
                        </tr>
                        <tr>
                          <th scope="row">Adhar Number</th>
                          <td>{user.adhar}</td>
                        </tr>
                        <tr>
                          <th scope="row">Added By</th>
                          <td>{user.added_by}</td>
                        </tr>

                      </tbody>
                    </table>

                  </div>
                  <div className='ml-5'>
                      <Formik initialValues={initialValues} onSubmit={handleStatus}>
                        <Form>
                          <div className='row d-flex'>
                            <div className='col-md-3'>
                              <FormikControl
                                control='select'
                                options={userStatus}
                                name='status'
                              />
                            </div>
                            <div className='col-md-1 my-3 py-2'>
                              <button type='submit' className='btn btn-dark'>save</button>
                            </div>
                          </div>
                        </Form>
                      </Formik>
                    </div>
                </div>
              </div>
              <style jsx='true'>
                {`
          form{
            margin-bottom: 3rem;
          }
        .badge-success{
          padding-top: 12px !important;
        }
        .btn-info{
          color: #fff;
        }
        .showList {
          position: absolute;
        }
          `}
              </style>
            </div>
          </div>
        ) : (
          <p>{error}</p>
        )
      }
      <style jsx='true'>
        {`
.overlay {
  opacity: 0.5;
  background-color: #000;
  height: 100vh;
  z-index: 1050;
  position: relative;
}
.margin-header-table{
  position: absolute;
  top: 0;
  width: 81%;
}
`
        }
      </style>
    </div>
  )
}

export default RetailerDetails