import React from 'react'
import { Form, Formik } from 'formik'
import FormikControl from '../../../components/FormikControl'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { bankSchema } from '../validation'
import { addCustomerBank } from '../../../api/instance_method'
import { ToastContainer, toast } from 'react-toastify'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Breadcrumbs from '../../../components/sections/Breadcrumbs'

const Add = () => {
  const { state } = useLocation();
  const { id } = state;
  const navigate = useNavigate();
  const initialValues = {
    customer_id: "",
    account_no: "",
    name: "",
    ifsc_code: "",
    bank_name: "",
    beneficiary_name: "",
    address: ""
  }

  const onSubmit = (values) => {
    const updatedValues = { ...values, customer_id: id }
    addCustomerBank(updatedValues).then(
      (response) => {
        setTimeout(() => {
          toast.success('Bank added successfully');
          setTimeout(() => {
            navigate("/dmt/customer-login");
          }, 4000);
        })
      },
      (error) => {
        console.log(error)
      }
    )
  }
  return (
    <>
      <div className='margin-header-table content-wrapper'>
        <ToastContainer />
        <Breadcrumbs title='Bank' path='/retailer-dashboard' />
        
        <div className='card mx-5 mb-5'>
          <div className='card-header pt-3 pb-0 d-flex '>
            <p className="font-weight-bold">Add Bank Details</p>
            <span className='mx-2'><AddCircleIcon /></span>
          </div>
          <div className='card-body px-5'>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={bankSchema}
            >
              <Form>
                <div className='row'>
                  <div className='col-md-6 col-sm-12'>
                    <FormikControl
                      type="text"
                      control="input"
                      id="name"
                      name="name"
                      label="Acount name"
                      placeholder="Acount name"
                    />
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <FormikControl
                      type="text"
                      control="input"
                      id="account_no"
                      name="account_no"
                      label="Account number"
                      placeholder="Account number"
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-sm-12'>
                    <FormikControl
                      type="text"
                      control="input"
                      id="ifsc_code"
                      name="ifsc_code"
                      label="IFSC code"
                      placeholder="IFSC code"
                    />
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <FormikControl
                      type="text"
                      control="input"
                      id="bank_name"
                      name="bank_name"
                      label="Bank name"
                      placeholder="Bank name"
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-sm-12'>
                    <FormikControl
                      type="text"
                      control="input"
                      id="address"
                      name="address"
                      label="Address"
                      placeholder="Address"
                    />
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <FormikControl
                      type="text"
                      control="input"
                      id="beneficiary_name"
                      name="beneficiary_name"
                      label="Beneficiary name"
                      placeholder="Beneficiary name"
                    />
                  </div>
                </div>
                <div className='row d-flex text-center'>
                  <div className='col-12'>
                    <button
                      type="submit"
                      className="btn btn-dark m-4"
                    >
                      Save
                    </button>
                    <Link
                      className="btn btn-dark m-4"
                      to="/retailer-dashboard"
                      id="id-1"
                    >
                      Back
                    </Link>
                  </div>
                </div>

              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}

export default Add;