import instance from "./api_instance";

// global api to select banks
export const selectBanks = async () => {
    return await instance.get('/select-banks')
}

export const postLoginCall = async (data) => {
    return await instance.post('/admin-login', data)
}

export const getPage = async (pageNumber) => {
    return await instance.get(`/users?page=${pageNumber}`)
}

export const getUserCall = async (id) => {
    return await instance.get(`/users/${id}`)
}

export const getUsers = async (pageNumber) => {
    return await instance.post(`/get-users?page=${pageNumber}`)
}

export const getUserByRole = async (role, pageNumber) => {
    return await instance.post(`/get-users?role=${role}&page=${pageNumber}`)
}
export const searchUser = async (data, pageNumber) => {
    return await instance.post(`/get-users?search_key=${data.search_key}&search_value=${data.search_value}&page=${pageNumber}`)
}

export const updateUserCall = async (data, id) => {
    return await instance.put(`/users/${id}`, JSON.stringify(data), {
        headers: {
            'Content-Type': 'application/json',
        }
    });
};

export const addNewUser = async (data) => {
    return await instance.post('/users', data)
}

export const getBankById = async (data) => {
    return await instance.post('/get-bank-by-user-id', data)
}

export const updateBank = async (data) => {
    return await instance.post('/update-bank-details', data)
}

export const searchDistributor = async (data) => {
    return await instance.post('/distributor/search-by-mobile-number', data)
}

export const addRetailer = async (data) => {
    return await instance.post('/distributor/distributor-users', data)
}

export const changePassword = async (data) => {
    return await instance.post('/change-user-password', data)
}

export const getByToken = async () => {
    return await instance.get('/get-admin-by-token');
};

export const getRetailer = async (data, id) => {
    return await instance.post(`/distributor/get-retailers`)
}
export const approvedRetailers = async () => {
    return await instance.get('/distributor/select-approved-retailer')
}

export const getRetailerPage = async (pageNumber) => {
    return await instance.post(`distributor/get-retailers?page=${pageNumber}`)
}

export const deleteRetailer = async (data) => {
    return await instance.delete(`/distributor/get-retailers/${data}`)
}

export const getRetailerById = async (id) => {
    return await instance.post(`distributor/get-retailer?id=${id}`)
}

export const updateRetailer = async (data, id) => {
    return await instance.put(`/distributor/update-retailer/${id}`, JSON.stringify(data), {
        headers: {
            'Content-Type': 'application/json',
        }
    });
};

export const UpdateStatus = async (data) => {
    return await instance.post('/distributor/update-retailer-status', data)
}

export const postDistLoginCall = async (data) => {
    return await instance.post('/login', data)
}

export const addUser = async (data) => {
    return await instance.post('/users', data);
}

export const getDistributorByToken = async () => {
    return await instance.get('/distributor/get-distributor-by-token');
};

export const getRetailerByToken = async () => {
    return await instance.get('/retailer/get-retailer-by-token');
};

export const deleteUser = async (data) => {
    return await instance.delete(`/users/${data}`)
}

export const addCustomer = async (data) => {
    return await instance.post('/retailer/add-customer', data)
}

export const fetchCustomers = async (data) => {
    return await instance.post(`/retailer/select-customers`,data)
}

export const selectCustomer = async (pageNumber,data) => {
    return await instance.post(`/retailer/get-customer-banks?page=${pageNumber}`, data)
}

export const addTopFund = async (data) => {
    return await instance.post('/distributor/add-top-up-transfer', data)
}

export const getTopupTransfer = async (pagenumber) => {
    return await instance.post(`/distributor/get-top-up-transfers?page=${pagenumber}`)
}
export const getTopupReport = async (data, pageNumber) => {
    return await instance.post(`/distributor/get-top-up-transfers?page=${pageNumber}`,data)
}
export const addBank = async (data) => {
    return await instance.post('/bank-details', data)
}

export const getBank = async (data) => {
    return await instance.post('/get-bank-by-user-id', data)
}

export const getBanksByAdmin = async (pageNumber) => {
    return await instance.post(`/get-banks?page=${pageNumber}`)
}

export const addCustomerBank = async (data) => {
    return await instance.post('/retailer/add-customer-bank', data)
}

export const changeUserStatus = async (id, status) => {
    return await instance.post(`/update-user-status?id=${id}&status=${status}`)
}

export const getAepsSlab = async (pageNumber) => {
    return await instance.get(`/aeps-slabs?page=${pageNumber}`)
}

export const getAepsSlabById = async (id) => {
    return await instance.get(`/aeps-slabs/${id}`)
}

export const addAepsSlab = async (data) => {
    return await instance.post('/aeps-slabs', data)
}

export const updateAepsSlab = async (data, id) => {
    return await instance.put(`/aeps-slabs/${id}`, JSON.stringify(data), {
        headers: {
            'Content-Type': 'application/json',
        }
    });
}

export const getUserWallet = async (data) => {
    return await instance.post(`/get-wallet-by-user-type`, data)
}

export const getDistributorWallet = async (data) => {
    return await instance.post(`/distributor/get-wallet-by-user-type`, data)
}

export const makeAeps = async (data) => {
    return await instance.post('/retailer/make-aeps', data)
}

export const aepsTransactionHistory = async (pageNumber) => {
    return await instance.post(`/get-aeps-transactions?page=${pageNumber}`)
}

export const aepsTransactionHistoryFilters = async (pageNumber,start, end, row) => {
    return await instance
    .post(`/get-aeps-transactions?page=${pageNumber}&start_date=${start}&end_date=${end}&row_type=${row}`)
}

export const dmtTransactionHistory = async (pageNumber) => {
    return await instance.post(`/get-dmt-transactions?page=${pageNumber}`)
}
export const dmtTransactionHistoryFilters = async (pageNumber,start, end, row) => {
    return await instance
    .post(`/get-dmt-transactions?page=${pageNumber}&start_date=${start}&end_date=${end}&row_type=${row}`)
}

export const topupTransactionHistory = async (pageNumber, data) => {
    return await instance.post(`/get-top-up-transfers?page=${pageNumber}`, data)
}
export const makeDmt = async (data) => {
    return await instance.post('/retailer/make-dmt', data)
}

export const getMemberShips = async () => {
    return await instance.get('/membership-plans');
}
 
export const addMembershipPlans = async (data) => {
    return await instance.post('membership-plans', data)
}

export const editMembershipPlan = async (data, id) => {
    return await instance.put(`/membership-plans/${id}`, JSON.stringify(data), {
        headers: {
            'Content-Type': 'application/json',
        }
    });
}

export const getMemberShipsPlansById = async (id) => {
    return await instance.get(`/membership-plans/${id}`)
}

export const orderMatm = async (data) => {
    return await instance.post('distributor/save-matm-order', data);
}
export const getMatm = async (pagenumber) => {
    return await instance.post(`distributor/get-matm-order?page=${pagenumber}`);
}
export const getAllMatms = async (pagenumber) => {
    return await instance.post(`/get-matm-orders?page=${pagenumber}`)
}
export const getMatmById = async (id) => {
    return await instance.get(`/matm-orders/${id}`);
}
export const editMatm = async (data, id) => {
    return await instance.put(`/matm-orders/${id}`,JSON.stringify(data), {
        headers: {
            'Content-Type': 'application/json',
        }
    });
}
export const getCustomers = async () => {
    return await instance.post('/retailer/get-customers')
}