import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import { CardHeader } from '../../../components/sections/CardHeader'
import Table from '../../../components/sections/Table'
import { ImUpload2 } from "react-icons/im";
import { MdPrint } from "react-icons/md";

const Invoices = () => {
  return (
    <div className='content-wrapper'>
         <Breadcrumbs title="Invoices" path="/retailer-dashboard" />
         <div className='container'>
            <div className='card'>
                <CardHeader title="Invoices" />
                <div className='card-body'>
                    <Table tableHeading={['Invoice No.', 'Date of Invoice', 'Sale', 'CGST', 'SGST', 'IGST', 'Invoice Type', 'Print', 'Upload']}>
                    <tr>
                        <td>VD/2324/CM133899</td>
                        <td>Sept-2023</td>
                        <td>71.00</td>
                        <td>6.39</td>
                        <td>6.39</td>
                        <td>12.78</td>
                        <td>COMM</td>
                        <td>
                            <button className='btn btn-secondary rounded-pill'>
                            <MdPrint /> Print</button>
                        </td>
                        <td>
                        <button className='btn btn-secondary rounded-pill'>
                        <ImUpload2 /> Upload Your Invoice
                        </button>
                        </td>
                    </tr>
                    </Table>
                    
                </div>
            </div>
         </div>
    </div>
  )
}

export default Invoices