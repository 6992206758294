import React from 'react'

const CardComponent = (props) => {
  return (
    <div className={`'card' ${props.className}`}  style={{ backgroundColor: '#e4f1f1', borderRadius: '1rem'}}>
        <div className='card-body'>
            {props.body}
            {props.children}
        </div>
    </div>
  )
}

export default CardComponent