import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import { topupTransactionHistory } from '../../../api/instance_method';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';

const TopupTransactionHistory = () => {
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    topupTransactionHistory(1).then(
      (response) => {
        setData(response.data.data.data);
        setTotalItems(response.data.data.total);
      },
      (error) => {
        toast.error('Topup transaction history cannot be loaded.');
      }
    )
      .catch(
        (error) => {
          console.log(error)
        }
      )
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
  }, [])
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber)
    topupTransactionHistory(pageNumber).then(
      (response) => {
        setData(response.data.data.data);
        console.log(response)
        setTotalItems(response.data.data.total);
      },
      (error) => {
        if (error) {
          navigate('/')
          console.log(error)
        }
      }
    )
  }
  return (
    <div className='content-wrapper'>
      <Breadcrumbs title='Topup Transaction History' path='/dashboard' />
    
      <div className='container'>
        {isLoading ? (
          <Loader />
        ) : (
          <div className='card'>
            <div className='card-header'>
              <div className='card-title'>
                Transaction History - Topup
              </div>
            </div>
            <div className='card-body table-responsive'>
              <div id="example2_wrapper" className="dataTables_wrapper dt-bootstrap4">
                <table
                  id="example2"
                  className='table table-bordered table-hover dataTable dtr-inline'
                  aria-describedby="example2_info"
                >
                  <thead>
                    <tr className='text-center'>
                      <th>Distributor (From)</th>
                      <th>Retailer (To)</th>
                      <th>Amount</th>
                      <th>Transfered On</th>
                    </tr>
                  </thead>
                  {data.length ? (
                    <tbody>
                      {data && data.map((item) => {
                        return (
                          <tr className='text-center' key={item.id}>
                            <td>
                              {item.from_user && item.from_user.first_name ? item.from_user.first_name : 'N/A'}&nbsp;
                            </td>
                            <td>
                              {item.to_user && item.to_user.first_name ? item.to_user.first_name : '-'}&nbsp;
                            </td>
                            <td>{item.amount}</td>
                            <td>{item.created_at}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colspan='4'>
                          <p className='font-mulish'>
                            No data found
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  )}

                </table>
                <div className='mx-3 mb-3'>
                  <div className='row'>
                    <div className="col-sm-12 col-lg-6">
                      <div className="dataTables_info text-primary" id="example2_info" role="status" aria-live="polite">
                        *Showing <span className='fw-bold'>{data.length} </span>
                        of <span className='fw-bold'>{totalItems}</span> entries
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                      <div className="float-lg-end pagination-sm pagination justify-content-start dataTables_paginate paging_simple_numbers" id="example2_paginate">
                        {data.length >
                          0 ? (
                          <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={totalItems}
                            pageRangeDisplayed={10}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      </div>
    </div>
  )
}

export default TopupTransactionHistory