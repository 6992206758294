import React from 'react'
import { Formik, Form } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { BsFillEyeFill } from "react-icons/bs";
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import { CardHeader } from '../../../components/sections/CardHeader';
import FormikControl from '../../../components/FormikControl';
import { municipalitySchema } from '../validation';
import RetailerHeader from '../RetailerHeader'

const Municipality = () => {
  const initialValues = {
    operator: '',
    ulb_code: '',
    property_id: ''
  }
  const handleSubmit = (val) => {
    if (val) {
      toast.success('Successful')
    }
  }
  return (
    <div className='content-wrapper'>
      <RetailerHeader />
      <Breadcrumbs title='Municipality' path='/retailer-dashboard' />
      <ToastContainer />
      <div className='container'>
        <div className='card'>
          <CardHeader title="Municipality" />
          <div className='card-body'>
            <Formik
              initialValues={initialValues}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={municipalitySchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className='row'>
                  <div className='col-md-6'>
                    <FormikControl
                      control='input'
                      label='Operator'
                      placeholder='Enter operator'
                      name='operator'
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormikControl
                      control='input'
                      label='ULB Code'
                      placeholder='Enter ulb code'
                      name='ulb_code'
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormikControl
                      control='input'
                      label='Property Id'
                      placeholder='Enter property id'
                      name='property_id'
                    />
                  </div>
                </div>
                <div className='text-center'>
                  <button type='submit' className='btn btn-secondary my-3 px-3 rounded-pill'>
                    View Bill <BsFillEyeFill />
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Municipality