import { Form, Formik } from 'formik';
import React from 'react';
import FormikControl from '../../../components/FormikControl';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import { CardHeader } from '../../../components/sections/CardHeader';
import { activateSchema } from '../Validation';
import { BiRightArrowAlt } from "react-icons/bi";

const AssignMatm = () => {
  const initialValues = { retailer_id: '' }

  const handleSubmit = () => {
    console.log('assign matm')
  }
  return (
    <div className="content-wrapper">
      <Breadcrumbs title="Assign Matm" path="/distributor-dashboard" />
      <div className="container">
        <div className='card'>
          <CardHeader title="Assign Matm" />
          <div className='card-body'>
          <Formik
              initialValues={initialValues}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={activateSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className="row d-flex align-items-center">
                  <div className="col-md-6">
                    <FormikControl
                      control="input"
                      label="Retailer ID"
                      placeholder="Enter Retailer ID"
                      name='retailer_id'
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="mx-4">
                      <button className="btn btn-dark rounded-pill">
                        Search <BiRightArrowAlt className="font-1" />
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssignMatm