import { Formik, Form } from "formik";
import React from "react";
import FormikControl from "../../FormikControl";
// import Table from "../Table";

const FormComponent = (props) => {

  return (
    <div className="card">
      <div className="card-header">
        <div className="card-title">{props.title}</div>
      </div>
      <div className="card-body">
        <Formik 
          onSubmit={props.onSubmit}
          initialValues={props.initialValues}
          validationSchema={props.validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          
        >
          <Form>
            <div className="row">
              <div className="col-md-6">
                <FormikControl
                  control={props.controlOne}
                  placeHolder={props.placeholderOne}
                  name={props.nameOne}
                  label={props.labelOne}                  
                />
              </div>
              <div className="col-md-6">
                <FormikControl
                  control={props.controlTwo}
                  placeHolder={props.placeholderTwo}
                  name={props.nameTwo}
                  label={props.labelTwo}            
                />
              </div>
            </div>
            <div className="justify-content-center d-flex">
              <button className="btn btn-dark m-3" type="submit">Submit</button>
              <button className="btn btn-danger m-3" type="reset" onClick={props.onClick} >Cancel</button>
            </div>
          </Form>
        </Formik>
        {props.children}
      </div>
    </div>
  );
};

export default FormComponent;
