import React from 'react';
import Breadcrumbs from '../../components/sections/Breadcrumbs';

const NeoFam = () => {
  return (
    <div className='content-wrapper'>
        <Breadcrumbs title='NeoFam Wallet' path='retailer-dashboard' />
        <div className='container'>
            NO UI
        </div>
    </div>
  )
}

export default NeoFam