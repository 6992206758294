import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import MoneyTransferReport from '../../../components/sections/retailer/MoneyTransferReport'

const YesbankReport = () => {
    return (
        <div className='content-wrapper'>
            <Breadcrumbs title='Money Transfer Report' path='retailer-dashboard' />
            <div className='container'>
                <MoneyTransferReport />
            </div>
        </div>
    )
}

export default YesbankReport