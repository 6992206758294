import React from 'react';
import DateView from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Field, ErrorMessage } from 'formik';
import { BsInfoCircle } from 'react-icons/bs';
import { format, parse } from 'date-fns';

function DatePicker(props) {
  const { id, name, label, ...rest } = props;
  return (
    <div className="form-group">
      <label htmlFor={name} className='font-mulish'>{label}</label><br />
      <Field name={name} className="form-control">
        {
          ({ form, field }) => {
            const { setFieldValue } = form;
            const { value } = field;
            const parsedValue = value ? parse(value, 'dd-MM-yyyy', new Date()) : null;
            return (
              <>
                <DateView
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD-MM-YYYY"
                  id={name}
                  {...field}
                  {...rest}
                  selected={parsedValue}
                  onChange={(val) => {
                    const formattedDate = val ? format(val, 'dd-MM-yyyy') : '';
                    setFieldValue(name, formattedDate);
                  }}
                  className='form-control custom-calendar'
                  value={value}
                />
              </>
            );
          }}
      </Field>

      {/* <div className='input-group form-control'>
        <div className='row'>
          <div className='col-md-12'>
            <Field name={name}>
              {({ form, field }) => {
                const { setFieldValue } = form;
                const { value } = field;
                const parsedValue = value ? parse(value, 'dd-MM-yyyy', new Date()) : null;
                return (
                  <DateView
                    dateFormat="dd-MM-yyyy"
                    placeholderText="DD-MM-YYYY"
                    id={name}
                    {...field}
                    {...rest}
                    selected={parsedValue}
                    onChange={(val) => {

                      const formattedDate = val ? format(val, 'dd-MM-yyyy') : '';
                      setFieldValue(name, formattedDate);
                    }}
                    className='w-100  background-transparent '
                    value={value}
                  />
                );
              }}
            </Field>
          </div>
        </div>
      </div> */}
      <ErrorMessage name={name} className='my-0 py-0'>
        {errorMsg =>
          <div
            className="invalid-feedback my-0 error d-block"
            style={{
              position: 'absolute',
              right: '0%',
              top: '45%',
              backgroundColor: '#fff0f1',
              width: '70%',
              opacity: 0.8,
              transition: '2s ease-in-out',
              padding: '8px',
              borderTopRightRadius: '8px',
              borderBottomRightRadius: '8px'
            }}>
            <BsInfoCircle /> &nbsp;{errorMsg}</div>}
      </ErrorMessage>
    </div>
  );
}

export default DatePicker;
