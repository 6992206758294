import React, { useState , useEffect } from 'react';
import { useDispatch ,useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import { postLoginCall, postDistLoginCall } from '../../api/instance_method';
import { login, setRole } from '../../redux/slices/authSlice';
import { Formik } from 'formik';
import FormikControl from '../../components/FormikControl';
import { Form } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import banner from '../../assets/images/mainBanner.png'
import { AiOutlineUnlock } from 'react-icons/ai'

const Login = ({ role }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showOTP, setShowOTP] = useState(true);
  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  // const UserDetail = useSelector((state) => state.auth.user)
  // const token = sessionStorage.getItem('token');
  // useEffect(() => {
  //     if (UserDetail && role === 'admin' && token ) {
  //       navigate('/dashboard')
  //     }
  //     if (UserDetail && role === 'retailer' && token) {
  //       navigate('/retailer-dashboard')
  //     }
  //     if (UserDetail && role === 'distributor' && token) {
  //       navigate('/distributor-dashboard')
  //     }
  // }, [])

  const handleSubmit = async (values) => {
    if (role === 'admin') {
      postLoginCall(values).then(
        (response) => {
          if (response.data.status === true) {
            sessionStorage.setItem('token', response.data.token);
            localStorage.setItem('auth', 'this is authenticated')
            dispatch(login(values));
            navigate('/dashboard');
            dispatch(setRole('admin'));
          }
        },
        (error) => {
          if (error.response.status) {
            toast.error('Wrong Email and password')
          }
        }
      );
    } else if (role === 'distributor') {
      postDistLoginCall(values).then(
        (response) => {
          const distributor = response.data.user.role;
          const userStatus = response.data.user.status;
          if (distributor === 1 && userStatus === 'Active') {
            sessionStorage.setItem('token', response.data.token);
            dispatch(login(values));
            navigate('/distributor-dashboard');
            dispatch(setRole('distributor'));
          } else {
            toast.error('Wrong email and password')
          }
        },
        (error) => {
          if (error.response.status) {
            toast.error('Wrong Email and password')
          }
        }
      );
    } else if (role === 'retailer') {
      postDistLoginCall(values).then(
        (response) => {
          const retailer = response.data.user.role;
          const userStatus = response.data.user.status;
          if (retailer === 2 && userStatus === 'Active') {
            sessionStorage.setItem('token', response.data.token);
            dispatch(login(values));
            navigate('/retailer-dashboard');
            dispatch(setRole('retailer'));
          } else {
            toast.error('Wrong email and password')
          }
        },
        (error) => {
          if (error.response.status) {
            toast.error('Wrong Email and password')
          }
        }
      );
    }
  }



  return (
    <div style={{ backgroundColor: '#021430', height: '100vh' }}>
      <ToastContainer />
      <nav className="navbar navbar-light navbar-background font-mulish ">
        <div className="container-fluid">
          <Link to='/' className="navbar-brand pointer h1 text-white font-nova fw-bold">MT PORTAL</Link>

        </div>
      </nav>
      <div className="container">
        <div className='row mt-5 pt-md-5 d-flex'>
          <div className='col-md-6 m-auto'>

            <img src={banner}
              className="img-fluid banner-image d-none d-md-block"
              alt="Sample"
            />
          </div>
          <div className='col-md-6 px-md-5 justify-content-center'>
            <div className="card primarybackground text-center">
              {showOTP ? (
                <div className="card-body">
                  <p className="h3 primary-color fw-bold font-oswald">
                    <AiOutlineUnlock className='mb-1' /> Sign In
                  </p>
                  <Formik
                    initialValues={{
                      email: '',
                      password: '',
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                  >
                    <Form>
                      <div className="d-flex row mt-md-5">
                        <div className="col-md-10 text-left mx-auto">
                          <FormikControl
                            label='Email '
                            control='input'
                            type="text"
                            name="email"
                            placeholder="Enter Email"
                          />

                          {/* <ErrorMessage component={Toast} name="email">{msg => <div>{msg}
                         {console.log(msg)}</div>}</ErrorMessage> */}
                        </div>
                      </div>
                      <div className="row mb-md-5 mt-md-3">
                        <div className="col-md-10 text-left mx-auto">
                          <FormikControl
                            label='Password'
                            control='input'
                            type="password"
                            name="password"
                            placeholder="Enter Password" />
                        </div>
                      </div>
                      <button className='btn btn-dark px-4 py-md-2 font-oswald  mb-3' type="submit">
                        Login
                      </button>
                    </Form>
                  </Formik>
                  <p className='py-md-2 pointer text-danger font-mulish'>Forget Password</p>
                </div>
              ) : (
                // <OtpVerification otpVerify={value} />
                ''
              )}

            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Login