import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FormikControl from '../../../components/FormikControl';
import { Formik, Form } from 'formik';
import { services } from '../../../helpers/constant';
import { aepsTransactionHistory, aepsTransactionHistoryFilters } from '../../../api/instance_method';
import Pagination from 'react-js-pagination';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';

const AepsTransactionHistory = () => {
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState('');
  const [activePage, setActivePage] = useState('');
  const [filterValues, setFilterValues] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const initialValues = {
    start_date: '',
    end_date: '',
    transaction: '',
    row_type: ''
  }

  useEffect(() => {
    fetchData(1, initialValues);

    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
  }, []);

  // api call
  const fetchData = (pageNumber, filters) => {
    setActivePage(pageNumber);
    const apiCall = filters
      ? aepsTransactionHistoryFilters(pageNumber, filters.start_date, filters.end_date, filters.row_type)
      : aepsTransactionHistory(pageNumber);

    apiCall
      .then((response) => {
        setData(response.data.data.data);
        setTotalItems(response.data.data.total);
        setFilterValues(filters);
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  // filter data 
  const handleFilters = (val) => {
    fetchData(1, val);
  }

  // pagination
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber)
    fetchData(pageNumber, filterValues);
  }

  // to clear all filters
  const handleClearFilters = () => {
    setFilterValues(null);
    fetchData(1, null)
  }

  return (
    <div className='content-wrapper'>
      <ToastContainer />

      <Breadcrumbs title='AEPS Transaction History' path='/dashboard' />
     
      <div className='container-fluid'>
        {isLoading ? (
         <Loader />
        ) : (
          <>
            <div className='card'>
              <div className='card-body'>
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleFilters}
                >
                  <Form>
                    <div className='row'>
                      <div className='col-md-6'>
                        <FormikControl
                          control="datepicker"
                          name="start_date"
                          label="Start Date :"
                        />
                      </div>
                      <div className='col-md-6'>
                        <FormikControl
                          control="datepicker"
                          name="end_date"
                          label="End Date :"
                        />
                      </div>
                      <div className='col-md-6'>
                        <FormikControl
                          control="input"
                          name="transaction"
                          label="Enter Transaction"
                          placeholder="Enter Transaction ID"
                        />
                      </div>
                      <div className='col-md-6'>
                        <FormikControl
                          control="select"
                          name="row_type"
                          label="Select Services"
                          options={services}
                        />
                      </div>
                      <div className='row'>
                        <div className='col-md-6 text-md-end text-center'>
                          <button
                            type='submit'
                            className='btn btn-outline-dark'
                            style={{
                              height: '2.5rem',
                              marginTop: '31px'
                            }}
                          >
                            Apply Filters
                          </button>
                        </div>
                        <div className='col-md-6 text-md-start text-center'>
                          <button
                            type='reset'
                            className='btn btn-xl btn-outline-danger'
                            style={{
                              height: '2.5rem',
                              marginTop: '31px'
                            }}
                            onClick={handleClearFilters}
                          >
                            Clear Filters
                          </button>
                        </div>
                      </div>

                    </div>
                  </Form>
                </Formik>
              </div>
            </div>

            <div className='card'>
              <div className='card-header'>
                <div className='card-title'>
                  Aeps Transactions
                </div>
              </div>
              <div className='card-body table-responsive'>
                <div id="example2_wrapper" className="dataTables_wrapper dt-bootstrap4">

                  <table
                    id="example2"
                    className='table table-bordered table-hover dataTable dtr-inline'
                    aria-describedby="example2_info"
                    style={{ minHeight: '20rem' }}
                  >
                    <thead>
                      <tr>
                        <th>Name (From)</th>
                        <th>Statement</th>
                        <th>Wallet ID</th>
                        <th>Wallet Cash-In Amount</th>
                        <th>Service Type</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    {data.length ? (
                      <tbody>
                        {data && data.map((item) => {
                          return (
                            <tr key={item.id}>
                              <td>{item.user.first_name} {item.user.last_name}</td>
                              <td> &#x20B9; {item.amount} transaction is made by {item.customer_adhar_number} with {item.bank.name}</td>
                              <td>{item.wallet_id}</td>
                              <td>{item.wallet.amount}</td>
                              <td>
                                {item.transfer_type === 0 && 'AEPS'}
                              </td>
                              <td>{item.amount}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={6}>
                            <p className='font-mulish'>
                              No data found
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>

                </div>
              </div>
              <div className='mx-3 mb-3'>
                <div className='row'>
                  <div className="col-sm-12 col-lg-6">
                    <div className="dataTables_info text-primary" id="example2_info" role="status" aria-live="polite">
                      *Showing <span className='fw-bold'>{data.length} </span>
                      of <span className='fw-bold'>{totalItems}</span> entries
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6">
                    <div className="float-lg-end pagination-sm pagination justify-content-start dataTables_paginate paging_simple_numbers" id="example2_paginate">
                      {data.length >
                        0 ? (
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={totalItems}
                          pageRangeDisplayed={10}
                          onChange={handlePageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}




      </div>
    </div>
  )
}

export default AepsTransactionHistory