export const five = 5
export const seven = 7

export const gender = [
    {key: '1', value: '--Select Gender--'},
    {key: '2', value: 'Female'},
    {key: '3', value: 'Male'},
    {key: '4', value: 'Other'},
]

export const salutation = [
    {key: '1', value: 'MR.'},
    {key: '2', value: 'MS.'},
    {key: '3', value: 'MRS.'},
    {key: '4', value: 'Dr.'},
]
export const roles = [
    { key: "0", value: "--Select--" },
    { key: "1", value: "Distributor" },
    { key: "2", value: "Retailer" },
];
export const aepsService = [
    { key: "--Select--", value: "--Select--" },
    { key: "Withdrawl", value: "Cash Withdrawl" },
    { key: "Balance", value: "Balance Enquiry" },
    { key: "Mini", value: "Mini Statement" },
]
export const userStatus = [
    { key: "--Select--", value: "--Select--" },
    { key: "Pending", value: "Pending" },
    { key: "Active", value: "Active" },
    { key: "Inactive", value: "Inactive" },
];
export const transferType = [
    { key: '1', value: 'IMPS'},
    { key: '2', value: 'NEFT'}
]
export const wallet = [
    { key: '1', value: 'Main Wallet'},
    { key: '2', value: 'Cash-In'},
    { key: '3', value: 'PG'}
]

export const services = [
    { key: '0', value: 'Select Service'},
    { key: '1', value: 'Statement'},
    { key: '2', value: 'Commission'},
    { key: '3', value: 'TDS'}
]

export const userSearchParams = [
    { key: 'filter', value: '--Filter--'},
    { key: 'first_name', value: 'First Name'},
    { key: 'last_name', value: 'Last Name' },
    { key: 'email', value: 'Email' },
    { key: 'mobile_number', value: 'Mobile Number' },
    { key: 'pan', value: 'Pan' },
    { key: 'adhar', value: 'Adhar' },
]