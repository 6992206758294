import React, { useEffect, useState } from 'react'
import { getDistributorWallet, getDistributorByToken } from '../../api/instance_method'
import { useDispatch } from 'react-redux';
import { setRole } from '../../redux/slices/authSlice';
import {GoDotFill} from 'react-icons/go';

const DistributorHeader = (props) => {
  const [wallet, setWallet] = useState([]);
  const [user, setUser] = useState([]);
  const token = sessionStorage.getItem('token')

  const values = {
    type: 1,
    id: user.id
  }
  useEffect(() => {
    handleWallet()
  }, [])

  const handleWallet = () => {
    getDistributorByToken().then(
      (response) => {
        setUser(response.data.user);
        setWallet(response.data.user.wallet)
      }
    )
  }

  return (
    <div>
      <div
        className=' justify-content-end text-right text-white d-flex'
        style={{ backgroundColor: '#bcc6cd', minHeight: '4.5rem' }}
      >
        <table className='my-2'>
          <tbody>
            <tr>
              <td className='px-3 retailer-header font-oswald'>Main Wallet</td>
              <td className='px-3 font-oswald'>Welcome</td>
            </tr>
            <tr>
              {wallet.map((el, id) => {
                return (
                  <td className='px-3 retailer-header font-mulish'>{el.amount}</td>
                )
              })}
              <td className='px-3 font-mulish'><GoDotFill className='mb-1' style={{color: '#6bd425'}} /> {user.first_name}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default DistributorHeader;