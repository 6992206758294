import React from 'react'
import { useState } from 'react';
import { changeUserStatus } from '../../api/instance_method';
import { toast } from 'react-toastify';
import { TbEdit } from 'react-icons/tb';

const EditStatus = (props) => {
	const [usersStatus, setUserStatus] = useState('');
	const initialValues = {
		id: props.userId,
		status: props.status
	}
	const handleStatus = (val) => {
		changeUserStatus(props.userId, val).then(
			(response) => {
				toast.success('Status Changed Successfully')
				setUserStatus(val);
				props.onStatusChange(val);
			},
			(error) => {
				toast.error('Status cannot be changed', error);
			}
		)
	}
	return (
		<div>
			<div className='d-flex justify-content-between align-items-center'>
				{(props.status == 'Active') ? (
					<div className='btn btn-success rounded-pill p-0 px-3'>
						{props.status}
					</div>
				) : props.status == 'Pending' ? (
					<div className='btn btn-danger rounded-pill p-0 px-3'>
						{props.status}
					</div>
				) : (
					<div className='btn btn-warning text-white rounded-pill p-0 px-3'>
						{props.status}
					</div>
				)}

				<div className='dropdown pointer'>
					<div data-bs-toggle='dropdown'>
						<h6 className='mt-1 ml-1'>
							<TbEdit />
						</h6>
					</div>
					<div className="dropdown-menu m-0 p-0 dropdown-menu-sm dropdown-menu-right">
						<a className='nav-link dropdown-item p-2'
							onClick={() => handleStatus('Active')}
						>
							Active
						</a>
						<a className='nav-link p-2 dropdown-item'
							onClick={() => handleStatus('Inactive')}
						>
							Inactive
						</a>
					</div>

				</div>
			</div>
		</div>
	)
}

export default EditStatus