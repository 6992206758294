import Input from "./components/elements/Input";
import Select from "./components/elements/Select";
import RadioButton from "./components/elements/RadioButton";
import CheckBox from "./components/elements/CheckBox";
import DatePicker from "./components/elements/DatePicker";
import FileUpload from "./components/elements/FileUpload";

function FormikControl(props) {
    const {control,...rest} = props;
    switch(control){
        case 'input':
            return <Input {...rest}/>;
        case 'select':
            return <Select {...rest}/>;
        case 'radio':
            return <RadioButton {...rest}/>;
        case 'checkbox':
            return <CheckBox {...rest}/>;
        case 'datepicker':
            return <DatePicker {...rest}/>;
        case 'file':
            return <FileUpload {...rest}/>;
        default :
        return null    
    }
}

export default FormikControl;