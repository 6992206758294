import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getAllMatms } from '../../../api/instance_method';
import { toast } from 'react-toastify';
import Pagination from 'react-js-pagination';
import { TbEdit } from 'react-icons/tb'
import Editmatm from './Editmatm';
import { ToastContainer } from 'react-toastify';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';

const Matm = () => {
  const [data, setData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [userId, setUserId] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllMatms(1).then(
      (response) => {
        setData(response.data.data.data);
        setTotalItemsCount(response.data.data.total);

        setTimeout(() => {
          setIsLoading(false);
        }, 2000)
      },
      (error) => {
        toast.error('Error fetching data')
      }
    ).catch(
      (error) => {
        console.log('Error', error)
      }
    )
  }, [isOpen]);

  const handlePageChange = (pagenumber) => {
    setActivePage(pagenumber);
    getAllMatms(pagenumber).then(
      (response) => {
        setData(response.data.data.data);
        setTotalItemsCount(response.data.total);
      },
      (error) => {
        toast.error('Error fetching data')
      }
    ).catch(
      (error) => {
        console.log('Error', error)
      }
    )
  }
  const openModal = (id) => {
    setUserId(id)
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <div className='content-wrapper'>
      <ToastContainer />
      {/* open modal to edit matm */}

      {isOpen && (
        <Editmatm closeModal={closeModal} userId={userId} />
      )}
      <Breadcrumbs title='M-ATM' path='/dashboard' />

      <div className='container-fluid'>
        {isLoading ? (
          <div className='d-flex align-items-center justify-content-center pt-5 mt-5'>
            <span className="loader"></span>
          </div>
        ) : (
          <div className='card'>
            <div className='card-header'>
              <div className='card-title'>
                M-ATM
              </div>
            </div>
            <div className="card-body table-responsive">
              <div id="example2_wrapper" className="dataTables_wrapper dt-bootstrap4">
                <table
                  id="example2"
                  className="table table-bordered table-hover dataTable dtr-inline"
                  aria-describedby="example2_info"
                >
                  <thead>
                    <tr>
                      <th>txnId</th>
                      <th>Username</th>
                      <th>Firmname</th>
                      <th>Mobile</th>
                      <th>ASM</th>
                      <th>ASM Phone</th>
                      <th>Dispatch Address</th>
                      <th>Registered Address</th>
                      <th>Product</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  {data.length ? (
                    <tbody>
                      {data.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td></td>
                            <td></td>
                            <td>{item.mobile_number}</td>
                            <td></td>
                            <td></td>
                            <td>{item.address}, {item.city}, {item.state}</td>
                            <td>{item.address}, {item.city}, {item.state}</td>
                            <td></td>
                            <td className='text-center pointer' onClick={() => openModal(item.id)}><TbEdit /></td>
                          </tr>
                        )
                      })}

                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colspan='9'>
                          <p className='font-mulish'>
                            No user found
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  )}

                </table>
                <div className=''>
                  <div className='row'>
                    <div className="col-sm-12 col-md-6">
                      {data.length ? (<div className="dataTables_info text-primary" id="example2_info" role="status" aria-live="polite">
                        *Showing <span className='fw-bold'>{data.length} </span>
                        of <span className='fw-bold'>{totalItemsCount} </span>entries
                      </div>) : (
                        ''
                      )}

                    </div>
                    <div className="col-sm-12 col-md-6 text-right ">
                      <div className="float-end dataTables_paginate paging_simple_numbers" id="example2_paginate">
                        {data.length >
                          0 ? (
                          <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={totalItemsCount}
                            pageRangeDisplayed={10}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        )}

      </div>
    </div>
  )
}

export default Matm