import React, { useState, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Formik, Form } from "formik";
import FormikControl from "../../../components/FormikControl";
import { userStatus } from '../../../helpers/constant'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { addRetailer } from "../../../api/instance_method";
import { validationSchema } from "../Validation";
import Breadcrumbs from '../../../components/sections/Breadcrumbs';

function Add(props) {
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [confirmKyc, setConfirmKyc] = useState(false);

  const initialValues = {
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    address_one: "",
    address_second: "",
    mobile_number: "",
    pan: "",
    adhar: "",
    role: "",
    status: "",
  };

  const onSubmit = (values) => {
    localStorage.setItem('values', JSON.stringify(values))
    if (!confirmKyc) {
      setShow(true);
    }
  }
  const handleConfirm = async () => {
    const val = await JSON.parse(localStorage.getItem('values'));
    addRetailer(val).then(
      (response) => {
        // document.getElementById('add-user').disabled = true;
        setShow(false)
        toast.success('Saved successfully');
        setTimeout(() => {
          navigate("/get-retailers");
          localStorage.removeItem('values')
        }, 4000);
      },
      (error) => {
        toast.error('User cannot be added.')
        if (error.response.data.status === false) {
          toast.error('Please try again later')
        }
      }
    );

  }


  return (
    <>
      <div className="content-wrapper mb-5">

        <ToastContainer />
        <Breadcrumbs title='Add Retailer' path='/distributor-dashboard' />
        
        <section style={{ paddingRight: '6px' }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card ">
                  <div className="card-header">
                    <h3 className="card-title d-flex align-items-center">
                      <AddCircleIcon className="primary-color" />Add User Detail</h3>
                  </div>
                  <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    validateOnBlur={false}
                    validateOnChange={false}
                  >
                    <Form>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <FormikControl
                              type="text"
                              control="input"
                              id="first_name"
                              name="first_name"
                              label="First name"
                              placeholder="First name"
                            />
                          </div>
                          <div className="col-md-6">
                            <FormikControl
                              type="text"
                              control="input"
                              id="middle_name"
                              name="middle_name"
                              label="Middle name"
                              placeholder="Middle name"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <FormikControl
                              type="text"
                              control="input"
                              id="last_name"
                              name="last_name"
                              label="Last name"
                              placeholder="Last Name"
                            />
                          </div>

                          <div className="col-md-6">
                            <FormikControl
                              type="email"
                              control="input"
                              id="email"
                              name="email"
                              label="Email"
                              placeholder="Email"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <FormikControl
                              type="text"
                              control="input"
                              id="mobile_number"
                              name="mobile_number"
                              label="Mobile number"
                              placeholder="Mobile number"
                            />
                          </div>
                          <div className="col-md-6">

                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <FormikControl
                              type="text"
                              control="input"
                              id="pan"
                              name="pan"
                              label="Pan"
                              placeholder="Pan"
                            />
                          </div>
                          <div className="col-md-6">
                            <FormikControl
                              type="text"
                              control="input"
                              id="adhar"
                              name="adhar"
                              label="Adhar"
                              placeholder="Adhar"
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <FormikControl
                              type="text"
                              control="input"
                              id="address_one"
                              name="address_one"
                              label="Address one"
                              placeholder="Address one"
                            />
                          </div>
                          <div className="col-md-6">
                            <FormikControl
                              type="text"
                              control="input"
                              id="address_second"
                              name="address_second"
                              label="Address second"
                              placeholder="Address second"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <FormikControl
                              control="select"
                              id="status"
                              name="status"
                              label="Status"
                              options={userStatus}
                            />
                          </div>
                        </div>
                        <div className="row text-center">
                          <div className="col">
                            <button
                              type="submit"
                              className="btn btn-secondary m-4 rounded-pill"
                              id="add-retailer"
                            >
                              Save
                            </button>
                            <Link
                              className="btn btn-danger rounded-pill m-4"
                              to='/get-retailers'
                            >
                              Back
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </section>
        {show && (
          <div className='text-center bg-light kyc-modal'>
            <p>Are you sure you want to add new retailer?</p>
            <h1 className='text-secondary'>
              <i className="bi bi-check-circle"></i></h1><br />
            <button
              className='mx-3 btn btn-outline-success'
              onClick={handleConfirm}>Yes</button>
            <button
              className='mx-3 btn btn-outline-danger'
              onClick={() => { setConfirmKyc(false); setShow(false) }}>Cancel
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default Add;
