import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Formik } from 'formik';
import { Form } from 'formik';
import { editMembershipPlan, getMemberShipsPlansById, getUserCall, updateUserCall } from '../../../api/instance_method';
import { toast } from 'react-toastify';
import FormikControl from '../../../components/FormikControl';

const EditMembership = (props) => {
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({
    name: '',
    price: ''
  })
  useEffect(() => {
    getMemberShipsPlansById(props.userId).then(
      (response) => {
        setInitialValues({
          id: response.data.data.id,
          name: response.data.data.name,
          price: response.data.data.price
        })
      },
    ).catch(
      (error) => {
        toast.error('User data cannot be fetched');
        console.log(error)
      }
    )

  }, [props.userId, id])

  const updatePlan = (values) => {
    editMembershipPlan(values,props.userId).then(
      (response) => {
        toast.success('Plan updated successfully');
        document.getElementById('update-user').disabled = true;
        setTimeout(() => {
          props.closeModal();
        },2000)
      }
    ).catch(
      (error) => {
        toast.error('Plan cannot be updated')
      }
    )
  };
  return (
    <div className={`modal fade show ${props.isEditOpenModal ? 'show' : 'hide'}`}

      style={{ display: 'block' }} >
      <div className='modal-dialog  modal-dialog-scrollable modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header bg-light'>
            <h5 className='modal-title'>Edit Details</h5>
            <button type="button" className="close color-white" onClick={props.closeModal}>x</button>
          </div>
          <div className='modal-body'>
            <Formik
              initialValues={initialValues}
              onSubmit={updatePlan}
              enableReinitialize
            >
              <Form>
                <FormikControl
                  type="text"
                  control="input"
                  id="name"
                  name="name"
                  label="Membership Plan Name"
                  placeholder="Membership Name"
                />
                  <FormikControl
                  type="text"
                  control="input"
                  id="price"
                  name="price"
                  label="Membership Plan Price"
                  placeholder="Membership Price"
                />
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-dark mx-2"
                    id='update-user'
                  >
                    Update
                  </button>
                  <button
                    className="btn btn-danger mx-2"
                    onClick={props.closeModal}
                     >
                    Back
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
        <div className='overlay'> </div>
      </div>
      <style jsx='true'>
        {`
        .modal {
          overflow-y: hidden;
        }
        `}
      </style>
    </div>
  )
}

export default EditMembership