import React from "react";

const Card = (props) => {
  return (
    <div className="card">
      <div className="card-header">
        <div className="card-title">{props.title}</div>
      </div>
      <div className="card-body">
        {props.body}
        {props.children}
      </div>
    </div>
  );
};

export default Card;
