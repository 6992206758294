import React, { useEffect, useState } from "react";
import { addBank, getBankById, updateBank } from "../../../api/instance_method";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Formik, Form } from "formik";
import FormikControl from "../../../components/FormikControl";
import { ToastContainer, toast } from 'react-toastify';
import { bankSchema } from './Validation'
import 'react-toastify/dist/ReactToastify.css';

const BankDetail = (props) => {
  const [flag, setFlag] = useState(false);
  let navigate = useNavigate();
  const { state } = useLocation();
  const { id } = state;
  const [initialValues, setInitialValues] = useState({
    id: "",
    user_id: "",
    account: "",
    name: "",
    ifsc: "",
    bank_name: "",
  })

  useEffect(() => {
    let payload = {
      "user_id": id
    }
    getBankById(payload).then(
      (response) => {
        if (response.data.status === true) {
          setFlag(true)
          setInitialValues({
            id: response.data.data.id,
            account: response.data.data.account,
            name: response.data.data.name,
            ifsc: response.data.data.ifsc,
            bank_name: response.data.data.bank_name,
          })
        }

      },
      (error) => { }
    );
  }, [id]);

  const onSubmit = (values, { setFieldError }) => {
    const updatedValues = { ...values, user_id: state.id }
    if (flag === true) {
      updateBank(values).then(
        (response) => {
          toast.success('Bank detail updated succeessfully');
          setTimeout(() => {
            navigate("/users");
          }, 3000);
        },
        (error) => {
          if (error.response.data.status === false) {
            let serverError = error.response.data.errors;
            Object.keys(serverError).forEach((key) => {
              setFieldError(key, serverError[key][0]);
            });
          }
        }
      );
    } else {
      addBank(updatedValues).then(
        (response) => {
          toast.success('Bank detail saved successfully');
          setTimeout(() => {
            navigate("/users");
          }, 3000);

        },
        (error) => {
          if (error.response.data.status === false) {
            let serverError = error.response.data.errors;
            Object.keys(serverError).forEach((key) => {
              setFieldError(key, serverError[key][0]);
            });
          }
        }
      );
    }

  };

  return (
    <div className="content-wrapper">
      <ToastContainer />
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">

            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to='/users'>Home</Link>
                </li>
                <li className="breadcrumb-item active">Add Bank</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Add Bank Detail</div>
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={bankSchema}
              enableReinitialize
            >
              <Form>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <FormikControl
                        type="text"
                        control="input"
                        id="name"
                        name="name"
                        label="Acount name"
                        placeholder="Acount name"
                      />
                    </div>
                    <div className="col-md-6">
                      <FormikControl
                        type="text"
                        control="input"
                        id="account"
                        name="account"
                        label="Account number"
                        placeholder="Account number"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <FormikControl
                        type="text"
                        control="input"
                        id="ifsc"
                        name="ifsc"
                        label="IFSC code"
                        placeholder="IFSC code"
                      />
                    </div>

                    <div className="col-md-6">
                      <FormikControl
                        type="text"
                        control="input"
                        id="bank_name"
                        name="bank_name"
                        label="Bank name"
                        placeholder="Bank name"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 text-right">
                      <button type="submit" className="btn rounded-pill primary_button px-3">Save</button>
                    </div>
                    <div className="col-md-6">
                      <Link className="btn rounded-pill btn-danger px-3" to="/users" id="id-1" >
                        Back
                      </Link>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BankDetail;
