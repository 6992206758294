import React from 'react'
import CardComponent from '../../../../components/sections/CardBody';
import Breadcrumbs from '../../../../components/sections/Breadcrumbs';
import BulkOrderComponent from '../../../../components/sections/BulkOrderComponent';

const FinoletLoan = () => {
    return (
        <div className='content-wrapper'>
            <Breadcrumbs
                title='Finequs (Finolet) Loan'
                path='/distributor-dashboard'
            />
            <div className='container'>
                <BulkOrderComponent
                    title='Hello Partner, Finequs (Finolet) Loan Are Available Now At below Slabs. Click Activate To Avail Services.'
                    tableHeading={['SLAB', 'INR (Incl. Tax)']}
                    tableData={
                        [{ firstItem: '5 To 5', secondItem: '500 /- (inclusive of GST)' },
                        { firstItem: '6 To 10', secondItem: '475 /- (inclusive of GST)' },]
                      }
                    button='Activate Finequs (Finolet) Loan'
                />
            </div>
        </div>
    )
}

export default FinoletLoan