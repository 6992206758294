import React, { useEffect, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import Pagination from 'react-js-pagination';
import { getTopupReport } from '../../../api/instance_method';

const Report = (props) => {
  const [users, setUsers] = useState([]);
  const [totalItems, setTotalItems] = useState('')
  const [activepage, setActivepage] = useState(1);

  useEffect(() => {
    setUsers(props.data)
    console.log(props.data)
  }, [])

  const close = () => {
    props.setShow(false)
  }
  const handlePageChange = (pageNumber) => {
    setActivepage(pageNumber)
    getTopupReport(props.nextPageData, pageNumber).then(
       (response) => {
          setUsers(response.data.data.data)
       },
       (error) => {
          console.log(error)
       }
    )
 }
  return (
    <div>
      <div className='card'>
        <div className='card-header'>
          <div className='card-title'>
            Report
          </div>
          <div className='card-tools' onClick={close}>
            <div className='pointer'>
              <AiOutlineCloseCircle />
            </div>
          </div>
        </div><div className="card-body table-responsive">
          <div id="example2_wrapper" className="dataTables_wrapper dt-bootstrap4">
            <table
              id="example2"
              className="table table-bordered table-hover dataTable dtr-inline"
              aria-describedby="example2_info"
            >
              <thead>
                <tr>
                  <th>Retailer (To)</th>
                  <th>Amount</th>
                  <th>Transfer Date</th>
                  <th>Transfered By</th>
                </tr>
              </thead>
              <tbody>
                {users && users.map((element, id) => {
                  return (
                    <tr key={id}>
                      <td>{element.to_user.first_name}</td>
                      <td>{element.amount}</td>
                      <td>{element.created_at}</td>
                      <td>
                        {element.from_user.first_name} {element.from_user.middle_name} {element.from_user.last_name}
                      </td>
                    </tr>
                  )
                })}
              </tbody>

            </table>
            <div className='mt-4'>
              <div className='row'>
                <div className="col-sm-12 col-md-6">
                  <div className="dataTables_info text-primary" id="example2_info" role="status" aria-live="polite">
                    *Showing <span className='fw-bold'>
                      {users.length}&nbsp;
                    </span>
                    of <span className='fw-bold'>
                      {props.totalItems}&nbsp;
                    </span>entries
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 text-right ">
                  <div className="float-end dataTables_paginate paging_simple_numbers" id="example2_paginate">
                    {users.length >
                      0 ? (
                      <Pagination
                        activePage={activepage}
                        itemsCountPerPage={10}
                        totalItemsCount={props.totalItems}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}




export default Report