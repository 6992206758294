import React from 'react';
import DistributorHeader from './DistributorHeader';
import Breadcrumbs from '../../components/sections/Breadcrumbs';
import Gst from '../../components/sections/Gst';

const DistributorGst = () => {
  return (
    <div className='content-wrapper'>
      <DistributorHeader />
      <Breadcrumbs path='/distributor-dashboard' title='GST Number' />
      <Gst />
  </div>
  )
}

export default DistributorGst