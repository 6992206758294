import React from 'react'
import Card from '../../../components/sections/Card'
import {HiDownload} from 'react-icons/hi'
import { Form, Formik } from 'formik'
import FormikControl from '../../../components/FormikControl'
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import Table from '../../../components/sections/Table'

const Statement = () => {
    return (
        <div className='content-wrapper'>
            <Breadcrumbs title='Top-Up Statement' path='/ditributor-dashboard' />
            <div className='container'>
                <Card title='Top-Up Statement' body={<>
                    <Formik>
                        <Form>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <FormikControl name='start_date' control='datepicker' label='Start Date' />
                                </div>
                                <div className='col-md-4'>
                                    <FormikControl name='end_date' control='datepicker' label='End Date' />
                                </div>
                                <div className='col-md-4'>
                                    <FormikControl
                                        name='retailer'
                                        control='select'
                                        label='Select Retailers'
                                        options={[
                                            { key: 'Select', value: 'Select Retailer' }
                                        ]} />
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </>} />
                <div>
                    <Table tableHeading={['TXNID', 'Date', 'Opening', 'Credit', 'Debit', 'Closing', 'Code', 'Name', 'FirmName', 'Narration', 'Remarks', 'Type']}
                        tableBody='No data right now' colSpan='12' />
                </div>
                <button className='btn btn-dark rounded-pill'>Download <HiDownload /></button>
            </div>
        </div>
    )
}

export default Statement;