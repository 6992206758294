import React from 'react'
import CardComponent from '../../../components/sections/CardBody';
import Button from '../../../components/elements/Button';
import { Form, Formik } from 'formik'
import FormikControl from '../../../components/FormikControl'
import Breadcrumbs from '../../../components/sections/Breadcrumbs';

const RetailerPassword = () => {
  return (
    <div className='content-wrapper'>
      <Breadcrumbs title='Change Password' path='/retailer-dashboard' />
      <div className='container'>
        <CardComponent body={
          <Formik>
            <Form>
              <div className='row'>
                <div className='col-md-4'>
                  <FormikControl
                    control='input'
                    placeholder='Enter old password'
                    label='Old Password'
                    name='old_password'
                  />
                </div>
                <div className='col-md-4'>
                  <FormikControl
                    control='input'
                    placeholder='Enter New password'
                    label='New Password'
                    name='new_password'
                  />
                </div>
                <div className='col-md-4'>
                  <FormikControl
                    control='input'
                    placeholder='Confirm new password'
                    label='New Password'
                    name='new_password'
                  />
                </div>
              </div>
              <div className='text-center mt-3'>
                <Button className='btn-dark' type='submit' value='Submit' />
              </div>
            </Form>
          </Formik>
        }
          className='p-5'
        />
      </div>
    </div>
  )
}

export default RetailerPassword;