import React, { useState } from 'react'
import GroupsIcon from '@mui/icons-material/Groups';
import { Form, Formik } from 'formik';
import FormikControl from '../../../FormikControl';
import { fetchCustomers } from '../../../api/instance_method';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { ToastContainer, toast } from 'react-toastify';
import Add from './AddCustomerBank';
import RetailerHeader from '../RetailerHeader';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';

const CustomerLogin = () => {
  const [users, setUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const token = sessionStorage.getItem('token');
  const [value, setValue] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [usedId, setUserId] = useState('');

  const initialValues = {
    search: '',
  }

  // const handleSubmit = (value) => {
  //   fetchCustomers(value).then(
  //     (response) => {
  //       const customer = response.data.data;
  //       if (customer.length) {
  //         navigate('/dmt/customer-detail', {
  //           state: {
  //             customer: customer
  //           },
  //         });
         
  //       } else {
  //         toast.error('Customer does not exist');
  //         setTimeout(() => {
  //           navigate('/dmt/all-customers');
  //         }, 2000)
  //       }
  //     }
  //   ).catch(
  //     (error) => {
  //       console.log(error);
  //     }
  //   )
  // }

  const handleSubmit = async (values) => {
    const { search } = values;
    console.log()
    let dataToSend = {};

  
    if (!isNaN(search)) {
      console.log("in if")
      dataToSend = {
        search: parseInt(search)
      };
    } else {
      console.log("in else")
      dataToSend = {
        search: search
      };
    }
    console.log(dataToSend)
    try {
      console.log(typeof dataToSend.search)
      console.log("line 72")
      const response = await fetchCustomers(dataToSend);
      const customer = response.data.data;
      console.log(response.data.data);
      navigate('/dmt/customer-detail', {
            state: {
              customer: customer
            },
          });
      if (customer) {
        navigate('/dmt/customer-detail', {
          state: {
            customer: customer
          },
        });
      } else {
        toast.error('Customer does not exist');
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className='content-wrapper'>
      <RetailerHeader />
      <div className='container-fluid pb-5' style={{ minHeight: '80vh' }}>
        <ToastContainer />
        {isOpen && (
          <Add closeModal={closeModal} usedId={usedId} />
        )
        }
          <Breadcrumbs title='Login' path='/retailer-dashboard' />
      
        <div>
          <div className='card mx-auto'>
            <div className='card-header'>
              <h5>Customer Login</h5>
            </div>
            <div className='card-body'>
              <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}>
                <Form>
                  <div className='row mt-5'>
                    <div className='col-md-4 my-md-2 text-md-end text-center icon'>
                      <PersonSearchIcon style={{ color: '#021430' }} />
                    </div>
                    <div className='col-md-4'>
                      <FormikControl
                        type='search'
                        name='search'
                        control='input'
                        placeholder='Search Customer...'
                      />
                    </div>
                    <div className='col-md-4 my-md-2 text-center text-md-start'>
                      <button className='btn fw-bold main-button' type='submit'>Search</button>
                    </div>
                  </div>
                </Form>
              </Formik>
              <p className='hr my-5'><span>OR</span></p>
              <div className='row text-center'>
                <div className='col'>
                  <Link to='/dmt/all-customers' className='btn main-button-outline fw-bold mb-5 btn-sm'>
                    <GroupsIcon className='font-2' /> View My Customers
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <style jsx>
          {`
            .card{
              max-width: 80%;
            }
            .icon {
              font-size: 1.5rem;
            }
            .hr {
              width: 80%; 
              text-align: center; 
              border-bottom: 1px solid #000; 
              line-height: 0.1em; 
              margin: 2% 9% 2%;
            } 
     
            .hr span { 
              background:#fff; 
              padding:0 10px; 
            }
            .font-2{
              font-size: 1.2rem;
            }
            .margin-header-table{
              margin-bottom: 3rem;
              padding-bottom: 2rem
            }
        `}
        </style>
      </div>
    </div>
  )
}

export default CustomerLogin