import { Form, Formik } from 'formik';
import React from 'react'
import FormikControl from '../../../components/FormikControl';
import { addMembershipPlans } from '../../../api/instance_method';
import { toast } from 'react-toastify';

const AddMembership = (props) => {
  const handleSubmit = (values) => {
    addMembershipPlans(values).then(
      (response) => {
        document.getElementById('membership').disabled = true;
       toast.success('Plan added Successfully');
       setTimeout(() => {
        props.closeModal();
       }, 2000)
      }
    ).catch(
      (error) => {
        toast.error('New Plan cannot be added');
      }
    )
  }

  const initialValues = {
    name: '',
    price: ''
  }
  return (
    <div className={`modal fade show ${props.isOpen ? 'show' : 'hide'}`} style={{ display: 'block' }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content ">
          <div className="modal-header bg-light">
            <h5 className="modal-title ">Add Membership Plans</h5>
            <button type="button" className="close color-white" onClick={props.closeModal}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body text-center">
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <Form>
                <FormikControl
                  name='name'
                  control='input'
                  placeholder='Enter membership plan'
                  label='Membership Plan Name'
                />
                <FormikControl
                  name='price'
                  control='input'
                  placeholder='Enter membership plan price'
                  label='Membership Plan Price'
                />
                <button 
                id='membership'
                className='btn btn-dark mx-2' 
                type='submit'>Submit</button>
                <button className='btn btn-danger mx-2' onClick={props.closeModal}>
                  Cancel
                </button>
              </Form>
            </Formik>
          </div>
        </div>
        <div className='overlay'> </div>
      </div>
    </div>
  )
}

export default AddMembership;