import React from 'react'
import Card from '../../../components/sections/Card'
import Button from '../../../components/elements/Button'
import { useState } from 'react';
import { Formik, Form } from 'formik';
import FormikControl from '../../../components/FormikControl';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import Table from '../../../components/sections/Table';
import {MdCloudDownload} from 'react-icons/md'
import { datePckerValidaton } from '../Validation';
import { ToastContainer, toast } from 'react-toastify';

const BillCommission = () => {
  const [show, setShow] = useState(false);

  const initialValues = {
    start_date: '', 
    end_date: '' 
  }

  const handleSubmitWallet = (value , {resetForm}) => {    
    toast.success('DMT Transaction successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,   
      progress: undefined,
      theme: "light",
    });
    resetForm();
  }

  return (
    <div className='content-wrapper'>
      <ToastContainer />
      <Breadcrumbs title='Bill Payment Commission' path='/distributor-dashboard' />
      <div className='container'>
        <Card
          title='Bill Payment Transaction'
          body={
            <Formik 
              initialValues = {initialValues}
              validationSchema={datePckerValidaton} 
              onSubmit={handleSubmitWallet}
            >
              <Form>
                <div className='row'>
                  <div className='col-md-6'>
                    <FormikControl
                      name='start_date'
                      control='datepicker'
                      label='Start Date'
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormikControl
                      name='end_date'
                      control='datepicker'
                      label='End Date'
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormikControl
                      name='search'
                      control='select'
                      label='Search By'
                      options={[
                        { key: 'Select', value: '--Search By--' }
                      ]}
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormikControl
                      name='end_date'
                      control='datepicker'
                      label='End Date'
                    />
                  </div>
                  {show ? (
                    <>
                      <div className='col-md-6'>
                        <FormikControl
                          name='status'
                          control='select'
                          label='End Date'
                          options={[
                            { key: 'Select', value: '--Search By--' },
                            { key: 'Refund', value: 'Refund' },
                            { key: 'Complete', value: 'Complete' },
                            { key: 'Process', value: 'In Process' }
                          ]}
                        />
                      </div>
                      <div className='col-md-6'>
                        <FormikControl
                          name='retailer'
                          control='input'
                          label='Retailer'
                          placeholder='Retailer'
                        />
                      </div>
                    </>
                  ) : ('')}
                </div>
                <div className='justify-content-center my-3 d-flex'>
                  <Button className='btn-dark mx-3' value='Submit' type='Submit' />
                  <Button className='btn-secondary mx-3' type='button' value={<>More Filter +</>} onClick={() => setShow(!show)} />
                </div>
              </Form>
            </Formik>
          }
        >
          <Table
            tableHeading={['Agent', 'TXNID', 'Operator Name', 'Mobile', 'Amount', 'Operator ID', 'Date', 'RComm', 'DComm', 'Status']}
            tableBody='No data right now'
            colSpan='7'
            className='mt-5 mb-3'
          />
          <Button className='btn-secondary rounded-pill' value={<>Download <MdCloudDownload className='mb-1'/></>} />
        </Card>
      </div>
    </div>
  )
}

export default BillCommission