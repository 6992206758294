import React, { useState } from 'react';
import List from './List';
import Add from './Add';

const AdminMain = () => {
	const [showForm, setShowForm] = useState(false);
	const toggleForm = (show) => {
		setShowForm(show);
	}

	return (
		<div>
			{
				showForm ? (
					<Add  showForm={showForm} toggleForm={toggleForm} />
				) : (
					<List showForm={showForm} toggleForm={toggleForm} />
				)
			}
		</div>
	)
}

export default AdminMain