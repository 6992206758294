import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import Nav from 'react-bootstrap/Nav';
import CashWithdrawl from './elements/CashWithdrawl';
import Balance from './elements/Balance';
import MiniStatement from './elements/MiniStatement';
import ScannerSetting from './elements/ScannerSetting';
import { useState } from 'react';

const AdharPay = () => {
  const [element, setElement] = useState('cash_withdrawl');
  const setBalanceEnquiry = () => {
    setElement('balance_enquiry')
  }
  const setCashWithdrawl = () => {
    setElement('cash_withdrawl')
  }
  const setMiniStatement = () => {
    setElement('mini_statement')
  }
  const setScannerSetting = () => {
    setElement('scanner_setting')
  }

  return (
    <div className='content-wrapper'>
      <Breadcrumbs title='AadharPay' path='/retailer-dashboard' />
      <div className='container'>
        <div className='card'>
          <Nav justify variant="tabs" defaultActiveKey="link-0">
            <Nav.Item>
              <Nav.Link
                eventKey="link-0"
                onClick={setCashWithdrawl}
              >
                Cash Withdrawl
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-1" onClick={setBalanceEnquiry}>
                Balance Enquiry
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-2" onClick={setMiniStatement}>
                Mini Statement
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-3" onClick={setScannerSetting}>
                Scanner Settings
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <div className='p-4'>
            {element === 'cash_withdrawl' ? (
              <CashWithdrawl />
            ) : element === 'balance_enquiry' ? (
              <Balance />
            ) : element === 'mini_statement' ? (
              <MiniStatement />
            ) : element === 'scanner_setting' ? (
              <ScannerSetting />
            ) :
              ('')
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdharPay