import { Link, useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { login } from "../../redux/slices/authSlice";
import { adminSidebar, distributorSidebar, retailerSidebar } from "../../data/sidebar";
import React, { useState } from "react";
import { AiOutlineUser } from 'react-icons/ai'
import { MdAdminPanelSettings } from 'react-icons/md'

const Sidebar = (props) => {
  const [openSubmenu, setOpenSubmenu] = useState('')
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.auth.role);
  const token = sessionStorage.getItem('token');
  const location = useLocation(); // Get the current route location

  const isButtonActive = (path) => {
    return location.pathname === path;
  };

  const getButtonClassName = (menuItem, path) => {
    return isButtonActive(path) ? 'active-button' : '';
  };

  const isSubmenuItemActive = (path) => {
    return location.pathname === path;
  };

  const logout = () => {
    sessionStorage.removeItem('token');
    dispatch(login(''))
    navigate('/')
  }
  const toggleSubmenu = (id) => {
    setOpenSubmenu(openSubmenu === id ? null : id);
  };
  return (
    <>

      {
        token && userRole ? (
          <div>
            <aside className="
                main-sidebar 
                navbar-background 
                elevation-4 
                sidebar-dark-info 
                text-white 
                pb-5
                sidebar-no-expand
                "
            >
              <p className="brand-link">
                <span className="brand-text font-weight-bold pl-1 font-nova">
                  {userRole === 'admin' ? (
                    <Link to='/dashboard' className="text-decoration-none text-white">
                      MT PORTAL
                    </Link>
                  ) : userRole === 'distributor' ? (
                    <Link to='/distributor-dashboard' className="text-decoration-none text-white">
                      MT PORTAL
                    </Link>
                  ) : userRole === 'retailer' ? (
                    <Link to='/retailer-dashboard' className="text-decoration-none text-white">
                      MT PORTAL
                    </Link>
                  ) : ''}
                </span><br />
                <span className="brand-text text-capitalize pl-1 text-white">
                  {userRole === 'admin' ? (
                    <MdAdminPanelSettings className="mb-1" />
                  ) : (
                    <AiOutlineUser className="mb-1" />
                  )}
                  <em>&nbsp;{userRole}</em>
                </span>
              </p>
              <div className="sidebar">
                <nav className="mt-2">
                  <ul
                    className="nav nav-pills nav-sidebar mb-auto flex-column "
                    data-widget="treeview"
                    role="menu"
                    data-accordion="true"
                  >
                    {userRole === 'admin' && (
                      <>
                        {
                          adminSidebar.map((menuItem, id) => (
                            <li className="nav-item font-oswald" key={id}>
                              {menuItem.submenu ? (
                                <>
                                  <div
                                    className={`pointer nav-link collapsed ${getButtonClassName(menuItem, menuItem.path)}`}
                                    data-bs-target="#submenuCollapse"
                                    data-bs-toggle="collapse"
                                    aria-expanded={openSubmenu === id ? "true" : "false"}
                                    onClick={() => toggleSubmenu(id)}
                                  >
                                    {menuItem.icon}&nbsp;
                                    <p>{menuItem.name}
                                      {openSubmenu === id ? (
                                        <i className="right fas fa-angle-down"></i>
                                      ) : (
                                        <i className="right fas fa-angle-left"></i>
                                      )}</p>
                                  </div>
                                  <div className="collapse" id="submenuCollapse">
                                    <ul className="btn-toggle-nav list-unstyled">
                                      {menuItem.submenu.map((subItem, subIndex) => (
                                        <li className="nav-item" key={subIndex}>
                                          <Link to={subItem.path}
                                            className={`nav-link sidebar-link ${isSubmenuItemActive(subItem.path) ? 'active-button' : ''}`}
                                          >
                                            {subItem.icon} &nbsp;
                                            <p className="link_text">{subItem.name}</p>
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </>
                              ) : (
                                menuItem.name === 'Logout' ? (
                                  <div
                                    className={`nav-link pointer`}
                                    onClick={logout}
                                  >
                                    {menuItem.icon}&nbsp;
                                    <p>{menuItem.name}</p>
                                  </div>
                                ) : (
                                  <Link
                                    to={menuItem.path}
                                    className={`nav-link ${getButtonClassName(menuItem, menuItem.path)}`}
                                  >
                                    {menuItem.icon} &nbsp;
                                    <p>{menuItem.name}</p>
                                  </Link>
                                )
                              )}
                            </li>
                          ))
                        }
                      </>
                    )}

                    {userRole === 'distributor' && (
                      <>
                        {distributorSidebar.map((menuItem, index) => (
                          <li className="nav-item font-oswald" key={index}>
                            {menuItem.submenu ? (
                              <>
                                <div
                                  className={`pointer nav-link collapsed ${getButtonClassName(menuItem, menuItem.path)}`}
                                  data-bs-target={`#submenuCollapse${index}`}
                                  data-bs-toggle="collapse"
                                  aria-expanded={openSubmenu === index ? "true" : "false"}
                                  onClick={() => toggleSubmenu(index)}
                                >
                                  {menuItem.icon}&nbsp;
                                  <p>
                                    {menuItem.name}
                                    {openSubmenu === index ? (
                                      <i className="right fas fa-angle-down"></i>
                                    ) : (
                                      <i className="right fas fa-angle-left"></i>
                                    )}
                                  </p>
                                </div>
                                <div className="collapse" id={`submenuCollapse${index}`}>
                                  <ul className="btn-toggle-nav list-unstyled">
                                    {menuItem.submenu.map((subItem, subIndex) => (
                                      <li className="nav-item" key={subIndex}>
                                        <Link to={subItem.path}
                                          className={`nav-link sidebar-link ${isSubmenuItemActive(subItem.path) ? 'active-button' : ''}`}
                                        >
                                          {subItem.icon} &nbsp;
                                          <p className="link_text">{subItem.name}</p>
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </>
                            ) : (
                              menuItem.name === 'Logout' ? (
                                <div
                                  className={`nav-link pointer`}
                                  onClick={logout}
                                >
                                  {menuItem.icon}&nbsp;
                                  <p>{menuItem.name}</p>
                                </div>
                              ) : (
                                <Link
                                  to={menuItem.path}
                                  className={`nav-link ${getButtonClassName(menuItem, menuItem.path)}`}
                                >
                                  {menuItem.icon} &nbsp;
                                  <p>{menuItem.name}</p>
                                </Link>
                              )
                            )}
                          </li>
                        ))}
                      </>
                    )}

                    {userRole === 'retailer' && (
                      <>
                        {retailerSidebar.map((menuItem, index) => (
                          <li className="nav-item font-oswald" key={index}>
                            {menuItem.submenu ? (
                              <>
                                <div
                                  className={`pointer nav-link collapsed ${getButtonClassName(menuItem, menuItem.path)}`}
                                  data-bs-target={`#submenuCollapse${index}`}
                                  data-bs-toggle="collapse"
                                  aria-expanded={openSubmenu === index ? "true" : "false"}
                                  onClick={() => toggleSubmenu(index)}
                                >
                                  {menuItem.icon}&nbsp;
                                  <p>
                                    {menuItem.name}
                                    {openSubmenu === index ? (
                                      <i className="right fas fa-angle-down"></i>
                                    ) : (
                                      <i className="right fas fa-angle-left"></i>
                                    )}
                                  </p>
                                </div>
                                <div className="collapse" id={`submenuCollapse${index}`}>
                                  <ul className="btn-toggle-nav list-unstyled">
                                    {menuItem.submenu.map((subItem, subIndex) => (
                                      <li className="nav-item" key={subIndex}>
                                        <Link to={subItem.path}
                                          className={`nav-link sidebar-link ${isSubmenuItemActive(subItem.path) ? 'active-button' : ''}`}
                                        >
                                          {subItem.icon} &nbsp;
                                          <p className="link_text">{subItem.name}</p>
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </>
                            ) : (
                              menuItem.name === 'Logout' ? (
                                <div
                                  className={`nav-link pointer`}
                                  onClick={logout}
                                >
                                  {menuItem.icon}&nbsp;
                                  <p>{menuItem.name}</p>
                                </div>
                              ) : (
                                <Link
                                  to={menuItem.path}
                                  className={`nav-link ${getButtonClassName(menuItem, menuItem.path)}`}
                                >
                                  {menuItem.icon} &nbsp;
                                  <p>{menuItem.name}</p>
                                </Link>
                              )
                            )}
                          </li>
                        ))}
                      </>
                    )}
                  </ul>
                </nav>
              </div>
            </aside >
          </div >
        ) : (
          ''
        )
      }
    </>
  );
}


export default Sidebar;


