import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getMemberShips } from '../../../api/instance_method';
import Pagination from 'react-js-pagination';
import { GrTableAdd } from 'react-icons/gr';
import AddMembership from './AddMembership';
import EditMembership from './EditMembership';
import { ToastContainer } from 'react-toastify';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';

const MembershipPlans = () => {
  const [data, setData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalItems, setTotalItems] = useState('');
  const [userId, setUserId] = useState('')
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isEditOpenModal, setIsEditOpenModal] = useState(false);

  const openModal = (id) => {
    setIsOpenModal(true);
  }

  const closeModal = () => {
    setIsOpenModal(false);
  }

  const handleEditModal = (id) => {
    setIsEditOpenModal(true);
    setUserId(id)
  }
  const closeEditModal = () => {
    setIsEditOpenModal(false);
  }
  useEffect(() => {
    getMemberShips().then(
      (response) => {
        console.log(response.data.data.data)
        const sortedData = response.data.data.data.sort((a, b) => a.id - b.id)
        setData(sortedData);
        setTotalItems(response.data.data.total)
      }
    )
  }, [isEditOpenModal]);
  const handlePageChange = () => {

  }
  return (
    <div className='content-wrapper'>
      <ToastContainer />
      {isOpenModal && (
        <AddMembership closeModal={closeModal} userId={userId} />
      )}
      {isEditOpenModal && (
        <EditMembership closeModal={closeEditModal} userId={userId} />
      )}

      <Breadcrumbs title='Membership Plans' path='/dashboard' />
     
      <div className='container'>
        {totalItems ? (
          <div className='card mx-auto' style={{ minHeight : '10rem'}}>
            <div className='card-header'>
              <div className='card-title'>
                Membership Plans
              </div>
              <div className='card-tools'>
                <div className='me-2 pointer'>
                  <GrTableAdd onClick={() => openModal()} />
                </div>
              </div>
            </div>
            <div className='card-body table-responsive'>
              <div id="example2_wrapper" className="dataTables_wrapper dt-bootstrap4">
                <table
                  id="example2"
                  className='table table-bordered table-hover dataTable dtr-inline'
                  aria-describedby="example2_info"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data.map((item) => {
                      return (
                        <tr key={item.id} onClick={() => handleEditModal(item.id)}>
                          <td>{item.id}</td>
                          <td>{item.name} <i className="bi bi-pencil"></i></td>
                          <td>{item.price}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <div className=''>
                  <div className='row'>
                    <div className="col-sm-12 col-md-6">
                      <div className="dataTables_info text-primary" id="example2_info" role="status" aria-live="polite">
                        *Showing&nbsp;
                        <span className='fw-bold'>{data.length}</span>&nbsp;
                        of&nbsp;
                        <span className='fw-bold'>{totalItems}</span>&nbsp;
                        entries
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 text-right ">
                      <div className="float-end dataTables_paginate paging_simple_numbers" id="example2_paginate">
                        {data.length >
                          0 ? (
                          <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={totalItems}
                            pageRangeDisplayed={10}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='d-flex align-items-center justify-content-center pt-5 mt-5'>
            <span className='loader'></span>
          </div>
        )}
      </div>
    </div>
  )
}

export default MembershipPlans