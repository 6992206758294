import { Form, Formik } from 'formik';
import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import FormikControl from '../../../components/FormikControl';
import RetailerHeader from '../RetailerHeader';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import { prepaidRechargeSchema } from '../validation';

const Prepaid = () => {
  const initialValues = {
    mobile_number : '',
    operator: '',
    amount: ''
  };

  const handleSubmit = (val) => {
    if(val) {
      toast.success('Recharge successful')
    } else {
      toast.error('Recharge unsuccessful')
    }
  }

  return (
    <div className='content-wrapper'>
      <RetailerHeader />
      <ToastContainer />
      <Breadcrumbs title='Prepaid' path='/retailer-dashboard' />
      
      <div className='container'>
        <div className='card'>
          <div className='card-header'>
            <div className='card-title'>
              Prepaid Mobile Recharge
            </div>
          </div>
          <div className='card-body'>
            <Formik 
              initialValues={initialValues}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={prepaidRechargeSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className='row'>
                  <div className='col-md-4'>
                    <FormikControl
                      type='text'
                      name='mobile_number'
                      control='input'
                      label='Enter Mobile Number'
                      placeholder='Mobile Number'
                    />
                  </div>
                  <div className='col-md-4'>
                    <FormikControl
                      type='text'
                      name='operator'
                      control='input'
                      label='Enter Your Operator'
                      placeholder='Operator'
                    />
                  </div>
                  <div className='col-md-4'>
                    <FormikControl
                      type='text'
                      name='amount'
                      control='input'
                      label='Enter Amount'
                      placeholder='Amount'
                    />
                  </div>
                </div>
                <div className='d-flex justify-content-center'>
                  <button className='btn btn-secondary rounded-pill mx-3 px-3' type='submit'>Submit</button>
                  <button className='btn btn-danger rounded-pill mx-3 px-3' type='button'>Cancel</button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Prepaid