import Input from "./elements/Input";
import Select from "./elements/Select";
import RadioButton from "./elements/RadioButton";
import CheckBox from "./elements/CheckBox";
import DatePicker from "./elements/DatePicker";
import FileUpload from "./elements/FileUpload";
import { useFormikContext } from "formik";

function FormikControl(props) {
    const {control,...rest} = props;
    const { setFieldValue } = useFormikContext();
    switch(control){
        case 'input':
            return  <Input
            className={props.className}
            {...rest}
            // value={rest.value} 
             onChange={(e) => setFieldValue(rest.name, e.target.value)} // Update field value using setFieldValue
          />;
        case 'select':
            return <Select {...rest}/>;
        case 'radio':
            return <RadioButton {...rest}/>;
        case 'checkbox':
            return <CheckBox {...rest} />;
        case 'datepicker':
            return <DatePicker {...rest}/>;
        case 'file':
            return <FileUpload {...rest}/>;
        default :
        return null    
    }
}

export default FormikControl;