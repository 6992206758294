import React, { useEffect, useState } from 'react'
import { getUserByRole, getUsers } from '../../../api/instance_method';
import { Link } from 'react-router-dom';
import { FaUsers } from 'react-icons/fa';
import { HiUsers } from 'react-icons/hi';
import style from './AdminDashboard.module.scss'
import Loader from '../../../components/Loader';
import { adminData } from '../../../data/admindashboard';


const AdminDashboard = () => {
  const [totalUsers, setTotalUsers] = useState();
  const [distributors, setDistributors] = useState();
  const [retailers, setRetailers] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const token = sessionStorage.getItem('token');

  useEffect(() => {
    if (token) {
      getUsers(1).then(
        (response) => {
          const totalUsers = response.data?.data?.total ?? 0;
          setTotalUsers(totalUsers)
        }
      )
      getUserByRole(1).then(
        (response) => {
          setDistributors(response.data.data.total)
        }
      )
      getUserByRole(2).then(
        (response) => {
          setRetailers(response.data.data.total);
        }
      );

      setTimeout(() => {
        setIsLoading(false);
      }, 2000)
    }
  }, [token]);

  return (
    <div className='content-wrapper primarybackground py-4'>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={`${style.padding}`}>
          <div className="row container-fluid mx-auto">
            <div className="col-lg-3 col-12">
              <div className="small-box bg-purple">
                <div className="inner">
                  <h4 className='font-merriweather'>{totalUsers ? totalUsers : '0'}</h4>
                  <p className='font-mulish'>Total Users</p>
                </div>
                <div className={`icon ${style.icon}`}>
                  <FaUsers style={{ height: '3.5rem', width: '3.5rem' }} />
                </div>
                <Link to="/users" className={"text-black small-box-footer"}>
                  <span className=''>
                    More info <i className="fas fa-arrow-circle-right"></i>
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="small-box bg-success">
                <div className="inner">
                  <h4  className='font-merriweather'>{distributors ? distributors : '0'}</h4>
                  <p className='font-mulish'>Distributors</p>
                </div>
                <div className="icon">
                  <HiUsers style={{ height: '3.5rem', width: '3.5rem' }} />
                </div>
                <Link to="/users" className={"text-black small-box-footer"}>
                  <span className=''>
                    More info <i className="fas fa-arrow-circle-right"></i>
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="small-box bg-info">
                <div className="inner">
                  <h4 className='font-merriweather'>{retailers ? retailers : '0'}</h4>
                  <p className='font-mulish'>Retailers</p>
                </div>
                <div className="icon">
                  <HiUsers style={{ height: '3.5rem', width: '3.5rem' }} />
                </div>
                <Link to="/users" className={"text-black small-box-footer"}>
                  <span className=''>
                    More info <i className="fas fa-arrow-circle-right"></i>
                  </span>
                </Link>
              </div>
            </div>
            {adminData.map((data, id) => (
              <div className="col-lg-3 col-12" key={id}>
                <div className={`small-box text-white ${data.backgroundColor}`}>
                  <div className="inner">
                    <h4 className='font-merriweather'>{data.heading}</h4>
                    <p className='font-mulish'>{data.subHeading}</p>
                  </div>
                  <div className="icon">
                    {data.icon}
                  </div>
                  <Link to={data.path} className="small-box-footer">
                    <span className='text-white'>More info  </span>
                    <i className="fas fa-arrow-circle-right text-white"></i></Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      )
      }
    </div >
  )
}

export default AdminDashboard