import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import RefundAndPending from '../../../components/sections/retailer/RefundAndPending'

const YesbankRefund = () => {
  return (
    <div className='content-wrapper'>
        <Breadcrumbs title='Refund and Pending' path='retailer-dashboard' />
            <div className='container'>
                <RefundAndPending title='Refund and Pending' />
            </div>
    </div>
  )
}

export default YesbankRefund