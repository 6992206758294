import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FormikControl from '../../../components/FormikControl';
import { Formik, Form } from 'formik';
import RetailerHeader from '../RetailerHeader';
import { beneficiarySchema, dmtSchema } from '../validation';
import { ToastContainer, toast } from 'react-toastify';
import { addCustomerBank, selectCustomer } from '../../../api/instance_method';

const Customer = () => {
  const { state } = useLocation();
  const [beneficiaries, setBeneficiaries] = useState('');

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', 
    });
  };
  const {
    customer
  } = state || {};

  const val = Array.isArray(customer)
    ? customer.map((element) => ({
      name: element.name,
      mobile_number: element.mobile_number,
      id: element.id,
    }))
    : [];

  const dmtInitialvalues = {
    customer_bank_accounts_id: '',
    amount: ''
  }


  const navigate = useNavigate();
  const initialValues = {
    id: "",
    customer_id: "",
    account_no: "",
    name: "",
    ifsc_code: "",
    bank_name: "",
    beneficiary_name: "",
    address: ""
  }

  const onSubmit = (values) => {
    console.log(values)
    const updatedValues = { ...values, customer_id: val[0].id }
    addCustomerBank(updatedValues).then(
      (response) => {
        document.getElementById('register').disabled = true;
        setTimeout(() => {
          toast.success('Bank added successfully');
          selectCustomer(1,{customer_id: val[0].id}).then(
            (response) => {
              setBeneficiaries(response.data.data.data);
            }
          )
        })
      },
      (error) => {
        toast.error('Bank cannot be added.')
      }
    )
      .catch(
        (error) => {
          console.log(error)
        }
      )
  }
  useEffect(() => {
    selectCustomer(1).then(
      (response) => {
        setBeneficiaries(response.data.data.data);
        let filteredBeneficiaries = response.data.data.data.filter(
          beneficiary => beneficiary.customer.name === val[0].name
        )
        setBeneficiaries(filteredBeneficiaries);
      }
    )
  }, []);
  const navigateToConfirmpage = (values, id) => {
    const updatedValues = { ...values, customer_bank_accounts_id: id.id }
    navigate('/dmt/confirm-txns', {
      state: {
        beneficiary_name: id.beneficiary_name,
        beneficiary_bank: id.bank_name,
        beneficiary_account: id.account_no,
        ifsc_code: id.ifsc_code,
        formValue: updatedValues,
        name: val[0].name,
        mobile_number: val[0].mobile_number
      }
    })
  }

  return (
    <div className='content-wrapper'>
      <RetailerHeader />
      
      <div className='container-fluid my-4 pb-5'>
        <ToastContainer />
        <div className='row'>
          <div className='col-md-4'>
            <div className='card'>
              <div className='card-body'>
                <h5 className=' text-center p-3'>REMITTER DETAILS</h5>
                <table className='table mx-auto'>
                  {customer.map((val, index) => (
                    <>
                      <tr key={index}>
                        <th className='p-2'>Name : </th>
                        <td className='p-2'>{val.name}</td>
                      </tr>
                      <tr key={`mobile_${index}`}>
                        <th className='p-2'>Mobile : </th>
                        <td className='p-2'>{val.mobile_number}</td>
                      </tr>
                    </>
                  ))}
                  <tr>
                    <th className='p-2'>Month Limit : </th>
                    <td className='p-2 px-0'><i className="bi bi-currency-rupee"></i>25000.00</td>
                  </tr>

                </table>
              </div>
            </div>
          </div>
          <div className='col-md-8'>
            <div className='card'>
              <div className='card-body'>
                <h5 className='text-center p-3'>REGISTER BENIFICIARY</h5>
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={beneficiarySchema}
                  validateOnChange={false}
                  validateOnBlur={false}
                >
                  <Form>
                    <div className='row'>
                      <div className='col-md-6 col-sm-12'>
                        <FormikControl
                          type="text"
                          control="input"
                          id="name"
                          name="name"
                          label="Account name"
                          placeholder="Account name"
                        />
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <FormikControl
                          type="text"
                          control="input"
                          id="account_no"
                          name="account_no"
                          label="Account number"
                          placeholder="Account number"
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-6 col-sm-12'>
                        <FormikControl
                          type="text"
                          control="input"
                          id="ifsc_code"
                          name="ifsc_code"
                          label="IFSC code"
                          placeholder="IFSC code"
                        />
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <FormikControl
                          type="text"
                          control="input"
                          id="bank_name"
                          name="bank_name"
                          label="Bank name"
                          placeholder="Bank name"
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-6 col-sm-12'>
                        <FormikControl
                          type="text"
                          control="input"
                          id="address"
                          name="address"
                          label="Address"
                          placeholder="Address"
                        />
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <FormikControl
                          type="text"
                          control="input"
                          id="beneficiary_name"
                          name="beneficiary_name"
                          label="Beneficiary name"
                          placeholder="Beneficiary name"
                        />
                      </div>
                    </div>
                    <div className='row d-flex text-center'>
                      <div className='col-12'>
                        <button
                          type="submit"
                          id='register'
                          className="btn btn-outline-dark m-4"
                        >
                          Register Beneficiary
                        </button>
                        <button
                          type='button'
                          className="btn btn-outline-dark m-4"
                        >
                          Verify Account [Fee Rs. 4]
                        </button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <div className='card mt-5'>
          <div className='card-header'>
            <div className='card-title'>
              <h6 className='mt-2'>
                <i className="bi bi-bank2"></i>&nbsp;
                Beneficiaries
              </h6>
            </div>
            {beneficiaries.length === 0 && (
              <div className='card-tools'>
                <button className='btn' onClick={scrollToTop}>
                  Register Beneficiary
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="30" fill="currentColor" className="bi bi-cloud-plus-fill" viewBox="0 0 16 16">
                    <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm.5 4v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 1 0z" />
                  </svg>
                </button>
              </div>
            )}

          </div>
          <div className="card-body text-center table-responsive">
            {beneficiaries.length !== 0 && (
              <div id="example2_wrapper" className="dataTables_wrapper dt-bootstrap4">
                <table
                  id="example2"
                  className="table table-bordered table-hover dataTable dtr-inline"
                  aria-describedby="example2_info"
                >
                  <thead>
                    <th>Beneficiary Name</th>
                    <th>Bank</th>
                    <th>A/C Number</th>
                    <th>IFSC Code</th>
                    <th>Address</th>
                    <th>Amount</th>
                  </thead>
                  <tbody>
                    {beneficiaries.map((element) => {
                      return (
                        <tr key={element.id}>

                          <td style={{ verticalAlign: 'middle' }}>{element.beneficiary_name}</td>
                          <td style={{ verticalAlign: 'middle' }}>{element.bank_name}</td>
                          <td style={{ verticalAlign: 'middle' }}>{element.account_no}</td>
                          <td style={{ verticalAlign: 'middle' }}>{element.ifsc_code}</td>
                          <td style={{ verticalAlign: 'middle' }}>{element.address}</td>
                          <td>
                            <Formik
                              onSubmit={(val) => navigateToConfirmpage(val, element)}
                              initialValues={dmtInitialvalues}
                              validateOnChange={false}
                              validateOnBlur={false}
                              validationSchema={dmtSchema}
                            >
                              <Form>
                                <div className='d-flex justify-content-center'>
                                  <FormikControl
                                    control='input'
                                    placeholder='Enter Amount'
                                    className='rounded-pill'
                                    name='amount'
                                  />
                                  <button
                                    className='btn'
                                    type='submit'
                                    style={{
                                      height: '3rem',
                                      borderRadius: '50%',
                                      marginLeft: '2rem',
                                      backgroundColor: '#f6f9fc'
                                    }}
                                  >
                                    <i className="bi bi-send"></i>
                                  </button>
                                </div>
                              </Form>
                            </Formik>
                          </td>
                        </tr>
                      )
                    })
                    }
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Customer