import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import FormikControl from '../FormikControl';
import { ToastContainer, toast } from 'react-toastify';
import { gstValidationSchema } from '../../pages/distributors/Validation'




const Gst = (props) => {
  const [number, setNumber] = useState('');

  const initialValues = {
    gst: ''
  }

  const handleSubmit = (values, { resetForm }) => {
    console.log('submit button clicked');
    toast.success('successfull', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // Reset the form after submission
    resetForm();
  };

  const notHaveGstnumber = () => {
    console.log('you dont have gst number');

    toast.error('Do not have GST number', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    
  }

  return (
    <div className='container'>
      <ToastContainer/>
      <div className='card'>
        <div className='card-header'>
          <div className='card-title'>
            GST Details
          </div>
        </div>
        <div className='card-body'>
          <Formik 
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={gstValidationSchema}           
          >
            <Form>
              {number ? (
                <FormikControl
                  control='input'
                  type='text'
                  label='GST Number'
                  name='gst'
                  placeholder='GST Number'
                  value={number}
                  
                />
              ) : (
                <>
                  <FormikControl
                    control='input'
                    type='text'
                    label='Enter Your GST Number'
                    placeholder='GST Number'
                    name='gst'                    
                    
                  />
                  <div className='row my-2'>
                    <div className='col-md-6 text-md-right text-center mt-3'>
                        <button className='btn btn-secondary rounded-pill' type='submit'>Submit</button>
                    </div>
                    <div className='col-md-6 mt-3'>
                        <button className='btn btn-danger text-center text-md-left rounded-pill' type='button' onClick={notHaveGstnumber}>Do Not Have GST Number</button>
                    </div>
                  </div>
                </>
              )}

            </Form>
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default Gst