import React, { useEffect, useState } from 'react'
import { deleteRetailer, getRetailer, getRetailerPage, searchDistributor } from '../../../api/instance_method';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setRole } from '../../../redux/slices/authSlice';
import { BsThreeDots } from 'react-icons/bs';
import Pagination from 'react-js-pagination';
import DistributorHeader from '../DistributorHeader';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Edit from './Edit';
import { Form, Formik } from 'formik';
import FormikControl from '../../../components/FormikControl';
import Loader from '../../../components/Loader';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';

const List = (props) => {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem('token')
  const [search, setSearch] = useState('');
  const [totalItemsCount, setTotalItemsCount] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const [isOpen, setIsOpen] = useState(false);
  const [usedId, setUserId] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const openModal = (id) => {
    setUserId(id)
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };


  useEffect(() => {
    if (token) {
      getRetailer(1).then(
        (response) => {
          setUsers(
            response.data.data.data
          );
          setTotalItemsCount(response.data.data.total)
          dispatch(setRole('distributor'))
        },
      );
      setTimeout(() => {
        setIsLoading(false)
      }, 2000)
    }
  }, [isOpen]);

  const fetchUsers = () => {
    setSearch('')
    if (token) {
      getRetailer(1).then(
        (response) => {
          setUsers(
            response.data.data.data
          );
          setTotalItemsCount(response.data.data.total)
          dispatch(setRole('distributor'))
        },
      );
      setTimeout(() => {
        setIsLoading(false)
      }, 2000)
    }
  }
  const handleAddRetailer = (id) => {
    navigate('/distributor/add-retailer')
  }


  const handleNavigate = (id) => {
    navigate(`/retailer-details/${id}`)
  }
  const remove = (id) => {
    deleteRetailer(id).then(
      (response) => {
        const element = document.getElementById(`tr-${id}`)
        element.remove();
        toast.success('Retailer deleted successfully');
      }, (error) => { }
    )
  }
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber)
    getRetailerPage(pageNumber).then(
      (response) => {
        setUsers(response.data.data.data);
        setTotalItemsCount(response.data.data.total);
      },
      (error) => {
        if (error) {
          navigate('/')
          toast.error('Cannot go to next page.')
        }
      }
    )
  }
  const initialSearchValues = {
    mobile_number: ''
  }

  const handleSearch = (values) => {
    searchDistributor(values).then(
      (response) => {
        if (response.data.status === false) {
          toast.warning('No Retailer Found')
        } else {
          setSearch(response.data.data);
        }
      }
    )
  };
 
  return (
    <div className='content-wrapper'>
      <ToastContainer />
      <DistributorHeader />
      <>
        {isOpen && (
          <Edit closeModal={closeModal} usedId={usedId} />
        )}
      </>
      <Breadcrumbs title='Retailers' path='/distributor-dashboard' />
      <div className='container-fluid'>
        {isLoading ? (
          <Loader />
        ) : (
          <div className='card  mb-5'>
            <div className='card-header'>
              <div className='row'>
                <div className='col-9'>
                  <Formik
                    onSubmit={handleSearch}
                    initialValues={initialSearchValues}
                  >
                    <Form>
                      <div className='row'>
                        <div className='col-md-6 mt-1'>
                          <FormikControl
                            control='input'
                            type='search'
                            placeholder='Enter mobile number'
                            name='mobile_number'
                            style={{ margin: '0' }}
                            className="rounded-pill"
                          />
                        </div>
                        <div className='col-md-6 my-auto'>
                          <button
                            className="btn px-3 btn-dark search"
                            type='submit'
                          >
                             <i className="bi bi-search"></i> &nbsp; Search
                          </button>
                          <button
                            className="btn px-3 bg-white btn-outline-dark cancel_search"
                            type='button'
                            onClick={fetchUsers}
                          >x</button>
                        </div>
                      </div>
                    </Form>
                  </Formik>
                </div>
                <div className='col-3 mt-2 text-end'>
                  <button type='button' className='btn'
                    onClick={handleAddRetailer}>
                    <PersonAddIcon />
                  </button>
                </div>
              </div>
            </div>
            <div className='card-body table-responsive'>
              <div id="example2_wrapper" className="dataTables_wrapper dt-bootstrap4">
                <table
                  id="example2"
                  className="table table-bordered table-hover dataTable dtr-inline"
                  aria-describedby="example2_info"
                >
                  <thead>
                    <tr>
                      <th className="sorting sorting_asc" tabIndex="0" aria-controls="example2" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="Rendering engine: activate to sort column descending">
                        Name
                      </th>
                      <th className="sorting" tabIndex="0" aria-controls="example2" rowSpan="1" colSpan="1" aria-label="Browser: activate to sort column ascending">
                        Email
                      </th>
                      <th className="sorting" tabIndex="0" aria-controls="example2" rowSpan="1" colSpan="1" aria-label="Platform(s): activate to sort column ascending">
                        Mobile Number
                      </th>
                      <th className="sorting" tabIndex="0" aria-controls="example2" rowSpan="1" colSpan="1" aria-label="Platform(s): activate to sort column ascending">
                        Address
                      </th>
                      <th className="sorting" tabIndex="0" aria-controls="example2" rowSpan="1" colSpan="1" aria-label="Engine version: activate to sort column ascending">
                        PAN
                      </th>
                      <th className="sorting" tabIndex="0" aria-controls="example2" rowSpan="1" colSpan="1" aria-label="CSS grade: activate to sort column ascending">
                        Adhar
                      </th>
                      <th className="sorting" tabIndex="0" aria-controls="example2" rowSpan="1" colSpan="1" aria-label="CSS grade: activate to sort column ascending">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  {users.length ? (
                    <tbody>
                      {search ? (
                        <tr>
                          <td>
                            <a
                              onClick={(event) => { event.preventDefault(); handleNavigate(search.id) }}>
                              {search.first_name}</a>
                          </td>
                          <td>{search.email}</td>
                          <td>{search.mobile_number}</td>
                          <td>{search.address_one}</td>
                          <td>{search.pan}</td>
                          <td>{search.adhar}</td>
                          <td>
                            <div className='dropdown'>
                              <p
                                className="text-center"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown" aria-expanded="false"
                              >
                                <BsThreeDots />
                              </p>
                              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li className="dropdown-item">
                                  <button
                                    className='background-transparent text-dark mx-1 no-border'
                                    title="Remove"
                                    onClick={() => remove(search.id)}
                                  >
                                    Delete
                                  </button></li>
                                <li className="dropdown-item">
                                  <button
                                    className='background-transparent text-dark mx-1 no-border'
                                    title="Change Password"
                                    onClick={() => openModal(search.id)}
                                  >
                                    Edit
                                  </button>
                                </li>
                                <li className="dropdown-item">
                                  <button
                                    className='background-transparent text-dark mx-1 no-border'
                                    title="Bank"
                                  >
                                    Add Bank
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        users.map((element) => {
                          return (
                            <tr key={element.index}>
                              <td>
                                <a
                                  onClick={(event) => { event.preventDefault(); handleNavigate(element.id) }}>
                                  {element.first_name}</a>
                              </td>
                              <td>{element.email}</td>
                              <td>{element.mobile_number}</td>
                              <td>{element.address_one}</td>
                              <td>{element.pan}</td>
                              <td>{element.adhar}</td>
                              <td>
                                <div className='dropdown'>
                                  <p
                                    className="text-center"
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown" aria-expanded="false"
                                  >
                                    <BsThreeDots />
                                  </p>
                                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li className="dropdown-item">
                                      <button
                                        className='background-transparent text-dark mx-1 no-border'
                                        title="Remove"
                                        onClick={() => remove(element.id)}
                                      >
                                        Delete
                                      </button></li>
                                    <li className="dropdown-item">
                                      <button
                                        className='background-transparent text-dark mx-1 no-border'
                                        title="Change Password"
                                        onClick={() => openModal(element.id)}
                                      >
                                        Edit
                                      </button>
                                    </li>
                                    <li className="dropdown-item">
                                      <button
                                        className='background-transparent text-dark mx-1 no-border'
                                        title="Bank"
                                      >
                                        Add Bank
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      )}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan='7'>
                          <p className='font-mulish'>No user found</p>
                        </td>
                      </tr>
                    </tbody>
                  )}

                </table>
                {!search && (
                  <div className=''>
                    <div className='row'>
                      <div className="col-sm-12 col-md-6">
                        {users.length ? (<div className="dataTables_info text-primary" id="example2_info" role="status" aria-live="polite">
                          *Showing <span className='fw-bold'>{users.length} </span>
                          of <span className='fw-bold'>{totalItemsCount} </span>entries
                        </div>) : (
                          ''
                        )}

                      </div>
                      <div className="col-sm-12 col-md-6 text-right ">
                        <div className="float-end dataTables_paginate paging_simple_numbers" id="example2_paginate">
                          {users.length >
                            0 ? (
                            <Pagination
                              activePage={activePage}
                              itemsCountPerPage={10}
                              totalItemsCount={totalItemsCount}
                              pageRangeDisplayed={10}
                              onChange={handlePageChange}
                              itemClass="page-item"
                              linkClass="page-link"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

      </div>
    </div >
  )
}

export default List;