import React from "react";
import Card from '../../../components/sections/Card'
import CardComponent from '../../../components/sections/CardBody'
import { Form, Formik } from 'formik'
import FormikControl from '../../../components/FormikControl'
import Table from '../../../components/sections/Table'
import Button from '../../../components/elements/Button'
import {MdFileUpload} from 'react-icons/md'; 
import { refundAndPendingDatePicker } from '../../../pages/retailer/validation';
import { ToastContainer, toast } from 'react-toastify';


const RefundAndPending = (props) => {
  const initialValues = {
    start_date : "",
    end_date : ""
  }

  const handleSubmit = (value, { resetForm }) => {
    toast.success('Data read successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    console.log('Refund and  pending clicked');
    resetForm()
  }

  return (
    <div>
      <ToastContainer />
      <div className="card">

        <div className="card-header">
          <div className="card-title">{props.title}</div>
        </div>
        <div className="card-body">
          <Formik
            initialValues={initialValues}
            validationSchema={refundAndPendingDatePicker}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
          >
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <FormikControl
                    control="datepicker"
                    name="start_date"
                    label="Enter Start Date"
                  />
                </div>
                <div className="col-md-6">
                  <FormikControl
                    control="datepicker"
                    name="end_date"
                    label="Enter End Date"
                  />
                </div>
              </div>
              <div className="text-center">
                <button className="btn btn-dark rounded-pill"  type="submit">Submit</button>
              </div>
            </Form>
          </Formik>
          <div className="mt-5">
            <Table
              tableHeading={[
                "Date",
                "TXNID",
                "Remitter",
                "Bank Name",
                "Account No",
                "IFSC",
                "BeneName",
                "UTR",
                "Type",
                "Amount",
                "Charges",
                "Re-Initiate",
                "Status",
              ]}
              tableBody="No data right now"
              colSpan='13'
            />
          </div>
          <Button
            className="btn btn-dark my-3 rounded-pill"
            value={
              <>
                Export <MdFileUpload />
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default RefundAndPending;
