import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import CardComponent from '../../../components/sections/CardBody'
import Card from '../../../components/sections/Card';
import Table from '../../../components/sections/Table';
import Button from '../../../components/elements/Button';
import { Form, Formik } from 'formik'
import FormikControl from '../../../components/FormikControl';
import { FaDownload } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import { TransactionReportSchema } from '../validation'



const RechargeReport = () => {
  const initialValues = {
    start_date: "",
    end_date: "",
    keyword: "",
    search: "",
  }
  const handleSubmit = (value, { resetForm }) => {
    toast.success('Data read successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    console.log(' submit button clicked');
    resetForm()
  }
  return (
    <div className='content-wrapper'>
      <ToastContainer />
    <Breadcrumbs title='Transaction Report' path='/retailer-dashboard' />
    <div className='container'>
    <Card title='Transaction Report'>
      <Formik 
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={TransactionReportSchema}
            validateOnBlur={false}
            validateOnChange={false}
      >
        <Form>
          <div className='row'>
            <div className='col-md-6'>
              <FormikControl
                control='datepicker'
                label='Start Date'
                name='start_date'
              />
            </div>
            <div className='col-md-6'>
              <FormikControl
                control='datepicker'
                label='End Date'
                name='end_date'
              />
            </div>
            <div className='col-md-6'>
              <FormikControl
                control='select'
                label='Search By'
                name='search'
                options={[
                  { key: '1', value: '--Select--' },
                  { key: '2', value: 'TXNID' },
                  { key: '3', value: 'Current' }
                ]}
              />
            </div>
            <div className='col-md-6'>
              <FormikControl
                control='input'
                label='Keyword'
                name='keyword'
              />
            </div>
            <div className='col-md-6'>
              <FormikControl
                control='Select'
                label='Transaction Status'
                name='status'
                options={[
                  { key: '1', value: '--Select Status--' }
                ]}
              />
            </div>
          </div>
          <div className='text-center'>
            <button className='btn btn-dark px-4 rounded-pill' type='submit'>Submit</button>
          </div>
        </Form>
      </Formik>
      <CardComponent className='my-5'>
        <div className="px-md-5 pt-3 pb-2">
          <table className="table">
            <thead>
              <tr>
                <th className="bg-transparent">Amount</th>
                <th className="bg-transparent">Count</th>
                <th className="bg-transparent">Type</th>
                <th className="bg-transparent">Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="bg-transparent">Total Amount : 0</td>
                <td className="bg-transparent">Total Count : 0</td>
                <td className="bg-transparent">-</td>
                <td className="bg-transparent">-</td>
              </tr>
            </tbody>
          </table>
        </div>
      </CardComponent>
      <Table
        tableHeading={[
          'TXNID', 'Amount', 'Comm', 'Type', 'Date', 'UTR', 'Account Number', 'Remarks', 'ResponseMsg', 'Status', 'Dispute'
        ]}
        tableBody='No data right now'
        colSpan='12'
      />
      <div>
        <Button className='btn btn-dark rounded-pill' value={<>Download <FaDownload /></>} />
      </div>
    </Card>
  </div>
</div>
  )
}

export default RechargeReport;