import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    role: null,
    userId: null,
    mantraButton : null,
    token: null,
    feature: null
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
      login : (state,action) => {
      state.user = action.payload;
      },
      setRole: (state, action) => {
        state.role = action.payload;
      },
      userId: (state, action) => {
        state.userId = action.payload;
      },
      setMantraButton: (state, action) => {
        state.mantraButton = action.payload
      },
      setToken : (state,action) => {
        state.token = action.payload
      },
      setFeature: (state, action) => {
        state.feature = action.payload
      }
   
   }
})

export const { login, setRole, userId, setMantraButton, setToken, setFeature } = authSlice.actions;

export default authSlice.reducer;