import React, { Suspense } from 'react'
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';
import Loader from '../components/Loader';
import { Outlet } from 'react-router-dom';
import Footer from './footer/Footer';

function Layout() {
  return (
    <>
      <Header />
      <Sidebar />
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
      <Footer />
    </>
  )
}

export default Layout;