import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { getRetailerByToken } from '../../../api/instance_method';
import RetailerHeader from '../RetailerHeader';
import styles from './RetailerDashboard.module.scss';
import money from '../../../assets/images/mainBanner.png'
import { useDispatch } from 'react-redux';
import { setRole } from '../../../redux/slices/authSlice';
import Loader from '../../../components/Loader';
import Card from '../../../components/sections/ScrollingCardBody';
import { dashboardData } from '../../../data/retailerDashboard';


const RetailerDashboard = () => {
  const heading = ['Main Wallet', 'Cash-In Wallet']
  const [wallet, setWallet] = useState([]);
  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();


  useEffect(() => {
    getRetailerByToken().then(
      (response) => {
        setUser(response.data.user)
        setWallet(response.data.user.wallet)

        dispatch(setRole('retailer'));
      }
    )
    setTimeout(() => {
      setIsLoading(false)
    }, 2500);
  }, [])
  return (
    <div className='content-wrapper'>
      <RetailerHeader />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className='row my-3 m-auto text-center container-fluid'>
            <div className='col-md-8'>
              <img src={money} alt='image' className={`img-fluid ${styles.image}`} />
            </div>
            <div className='col-md-4'>
              <Card title='Latest Updates' content=' With instant payment services like AEPS and DMT you can also earn money through profitable commissions. Stay connected for more updates.' />
            </div>
          </div>
          <div className='row m-auto container-fluid'>
            <div className='col-lg-8 text-center'>
              <div className='mx-auto container-fluid'>
                <div className="row">
                  {dashboardData.map((data, id) => (
                    <div className="col-lg-4 col-md-6 col-sm-12" key={id}>
                      <div className={`small-box ${data.bgColor}`}>
                        <div className={`inner ${styles.dashboard_card}`}>
                          <h3 className={`${styles.dashboard_icon} text-white`}>
                            {data.icon}
                          </h3>
                          <h6 className='text-uppercase font-oswald text-white'>
                           {data.heading}
                          </h6>
                        </div>
                        <Link to={data.path} className="small-box-footer">
                          <span className='text-white'>More info </span><i className="fas text-white  fa-arrow-circle-right"></i></Link>
                      </div>
                    </div>
                  ))}
                  {/* <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="small-box bg-pink">
                      <div className="inner">
                        <h4 className='text-white'>
                          <GiTakeMyMoney />
                        </h4>
                        <p>
                          DMT
                        </p>
                      </div>
                      <Link to="/dmt/customer-login" className="small-box-footer text-white">
                        More info <i className="fas fa-arrow-circle-right"></i></Link>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="small-box bg-success">
                      <div className="inner">
                        <h4><TbMoneybag style={{ height: '3rem', width: '3.5rem' }} /></h4>
                        <p>AEPS</p>
                      </div>
                      <Link to="/aeps" className="small-box-footer text-white">
                        More info&nbsp;
                        <i className="fas fa-arrow-circle-right"></i>
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="small-box bg-danger">
                      <div className="inner">
                        <h4><BsPersonFillAdd style={{ height: '3rem', width: '3.5rem' }} /></h4>
                        <p>Add Customers</p>
                      </div>
                      <Link to="/dmt/add-customer" className="small-box-footer text-white">
                        More info&nbsp;
                        <i className="fas fa-arrow-circle-right"></i>
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="small-box bg-purple">
                      <div className="inner">
                        <h4> <FaUsers style={{ height: '3rem', width: '3.5rem' }} /></h4>
                        <p>View Customers</p>
                      </div>
                      <Link to="/dmt/all-customers" className="small-box-footer text-white">
                        More info&nbsp;
                        <i className="fas fa-arrow-circle-right"></i>
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="small-box bg-blue">
                      <div className="inner">
                        <h4 className='text-white'>
                          <TbDeviceMobileDollar style={{ height: '3rem', width: '3.5rem' }} />
                        </h4>
                        <p>
                          Recharge
                        </p>
                      </div>
                      <Link to="/recharge-prepaid" className="small-box-footer text-white">
                        More info <i className="fas fa-arrow-circle-right"></i></Link>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div>
                {heading.map((item, index) => (
                  <div className="card" key={index}>
                    <div className={`card-header ${styles.title_background}`}>
                      <h4 className="text-center text-light">{item}</h4>
                    </div>
                    <div className="card-body">
                      {wallet.map((amount, walletIndex) => {
                        if (walletIndex === index) {
                          return <h4 className="text-center" key={walletIndex}>{amount.amount}</h4>;
                        }
                        return null;
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )
      }


    </div >
  )
}

export default RetailerDashboard