import React from 'react';
import Breadcrumbs from '../../../../components/sections/Breadcrumbs';
import BulkOrderComponent from '../../../../components/sections/BulkOrderComponent';

const Axis = () => {
  return (
    <div className='content-wrapper'>
      <Breadcrumbs title='Axis Bank' path='/distributor-dashboard' />
      <div className='container'>
        <BulkOrderComponent
          title='Hello Partner, Axis Bank Are Available Now At below Slabs. Click Activate To Avail Services.'
          tableHeading={['SLAB', 'INR (Incl. Tax)']}
          tableData={
            [{ firstItem: '5 To 5', secondItem: '450 /- (inclusive of GST)' },
            { firstItem: '6 To 10', secondItem: '425 /- (inclusive of GST)' },]
          }
          button='Activate EMI ID'
        />
      </div>
    </div>
  )
}

export default Axis