import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import CardComponent from '../../../components/sections/CardBody'
import { Form, Formik } from 'formik'
import FormikControl from '../../../components/FormikControl';
import { TbCircleNumber1, TbCircleNumber2, TbCircleNumber3 } from 'react-icons/tb';
import { BiSolidChevronRightCircle } from 'react-icons/bi';

const EmiCollection = () => {
  return (
    <div className='content-wrapper'>
      <Breadcrumbs title='EMI Collection' path='/retailer-dashboard' />
      <div className='container'>
        <CardComponent className='container py-3'>
          <p className='border-bottom border-success pb-3 fw-bold'>Cash Drop</p>
          <div className='row container'>
            <div className='col-4'>
              <button className='btn text-dark fw-bold'>
                <span className='h5'><TbCircleNumber1 /></span> Enter Details
              </button>
            </div>
            <div className='col-4'>
              <button className='btn border-0' disabled>
                <span className='h5'><TbCircleNumber2 /></span> Confirm
              </button>
            </div>
            <div className='col-4'>
              <button className='btn border-0' disabled>
                <span className='h5'><TbCircleNumber3 /></span> Complete
              </button>
            </div>
          </div>
          <div className='py-5 mx-3'>
            <Formik>
              <Form>
                <div className='row'>
                  <div className='col-md-6'>
                    <FormikControl
                      name="company"
                      control="select"
                      options={[
                        { key: '1', value: '--Select Company--' }
                      ]}
                    />
                  </div>
                  <div className='col-md-6 text-md-start text-center'>
                    <button className='btn btn-secondary px-3 mt-2 rounded-pill'>Next <BiSolidChevronRightCircle /></button>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </CardComponent>
      </div>
    </div>
  )
}

export default EmiCollection