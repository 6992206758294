import React from 'react'
import Breadcrumbs from '../../components/sections/Breadcrumbs'
import Card from '../../components/sections/Card'
import Table from '../../components/sections/Table'
import { Form, Formik } from 'formik'
import FormikControl from '../../FormikControl'
import Button from '../../components/elements/Button'
import CardComponent from '../../components/sections/CardBody'
import {activateAxisCardSchema} from './Validation'
import { ToastContainer, toast } from 'react-toastify';


const AcivateAxisCard = () => {

  const initialValues = {
    card_number : '',
    cardHolder_name : ""
  }

  const handleSubmit = (values, { resetForm }) => {
    toast.success('Card activated successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // Reset the form after submission
    resetForm();
  };
 
  return (
    <div className='content-wrapper'>
      <ToastContainer />
      <Breadcrumbs title='Activate card' path='/distributor-dashboard' />
      <div className='container'>
        <Card title='Activate Axis Card'>
          <Table
            tableHeading={['Sno', 'Card Number', 'Assigned To', 'Status']}
            tableBody='No data right now'
            colSpan='4'
            className='my-4'
          />
        
          <div className='my-4'>
            <Formik 
              initialValues= {initialValues}
              onSubmit={handleSubmit}
              validationSchema={activateAxisCardSchema}
              validateOnBlur={false}
              validateOnChange={false}
            >
              <Form >
                <div className='row'>
                  <div className='col-md-6'>
                    <FormikControl
                      type="text"
                      control='input'
                      id = "card_number"
                      name='card_number'
                      placeholder='Enter card number'
                      label='Card Number'
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormikControl
                      type="text"
                      control='input'
                      id = "cardHolder_name"
                      name='cardHolder_name'
                      placeholder='Enter card holder name'
                      label='Card Holder Name'
                    />
                  </div>
                </div>
                <div className='text-center'>
                  <Button className='btn btn-dark rounded-pill' value='Activate Card' type="submit" />
                </div>
              </Form>
            </Formik>
          </div>
        </Card>
        <CardComponent className='mt-5'>
          <div className='text-center py-4'>
            <h4>CDM Charges</h4>
            <p className='text-capitalize'>Cash deposit charges in banking days is 0.1% + GST (i.e., Rs 118 per lakh ) or Rs 20 + GST whichever is higher.</p>
            <p className='text-capitalize'>Cash deposit on bank holidays is free on & above Rs 25000. If deposit amount is less than Rs 25000 flat Rs 25 will charged.</p>
          </div>
          <div className='p-3'>
            <table className='table table-bordered table-hover dataTable dtr-inline'>
              <thead>
                <tr>
                <th colspan='2' className='bg-dark-subtle text-center'>Sunday</th>
                </tr>
                <tr>
                  <td colspan='2' className='text-center font-mulish text-danger'>No CDM Deposit Charges</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>January</td>
                  <td>Date according to year</td>
                </tr>
                <tr>
                  <td>February</td>
                  <td>Date according to year</td>
                </tr>
                <tr>
                  <td>March</td>
                  <td>Date according to year</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                <th colspan='2' className='bg-dark-subtle text-center'>Saturday</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>January</td>
                  <td>Date according to year</td>
                </tr>
                <tr>
                  <td>February</td>
                  <td>Date according to year</td>
                </tr>
                <tr>
                  <td>March</td>
                  <td>Date according to year</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                <th colspan='2' className='bg-dark-subtle text-center'>National Holiday</th>
                </tr>
              </thead>
            </table>
          </div>
        </CardComponent>
      </div>
    </div>
  )
}

export default AcivateAxisCard;