import React from 'react';
import Card from '../../../components/sections/Card';
import { Form, Formik } from 'formik';
import FormikControl from '../../../components/FormikControl';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import Table from '../../../components/sections/Table';

const AllFund = () => {
  return (
    <div className='content-wrapper'>
      <Breadcrumbs title='All Top-Up Fund' path='/ditributor-dashboard'/>
      <div className='container'>
        <Card
          title='All Fund Request'
          body={<>
            <Formik>
              <Form>
                <div className='row'>
                  <div className='col-md-6'>
                    <FormikControl
                      name='start_date'
                      control='datepicker'
                      label='Start Date'
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormikControl
                      name='end_date'
                      control='datepicker'
                      label='End Date'
                    />
                  </div>
                </div>
                <div className='text-center'>
                  <button className='btn btn-dark'>Submit</button>
                </div>
              </Form>
            </Formik>
          </>}
        />
        <div>
          <Table tableHeading={['TXNID', 'Date Added', 'Reference Number', 'deposit Branch', 'Deposit Date', 'Approve/Reject Date', 'Amount', 'Reject Remark']} 
          tableBody='No data right now' colSpan='8'
          />
        </div>
      </div>
    </div>
  )
}

export default AllFund