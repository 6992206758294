import React from 'react'
import { Formik, Form } from 'formik';
import { BsFillEyeFill } from "react-icons/bs";
import { ToastContainer, toast } from 'react-toastify';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import { CardHeader } from '../../../components/sections/CardHeader';
import FormikControl from '../../../components/FormikControl';
import { broadbandSchema } from '../validation';
import RetailerHeader from '../RetailerHeader';

const Broadband = () => {
  const initialValues = {
    operator: '',
    landline_number: ''
  }

  const handleSubmit = (val) => {
    if(val) {
      toast.success('Successful')
  }
  }

  return (
    <div className='content-wrapper'>
      <RetailerHeader />
        <Breadcrumbs title='Broadband' path='/retailer-dashboard' />
        <ToastContainer />
        <div className='container'>
          <div className='card'>
            <CardHeader title="Broadband" />
            <div className='card-body'>
              <Formik
                initialValues={initialValues}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={broadbandSchema}
                onSubmit={handleSubmit}
              >
                <Form>
                <div className='row'>
                  <div className='col-md-6'>
                    <FormikControl 
                      control="input"
                      placeholder="Enter operator"
                      label="Operator"
                      name="operator"
                    />
                  </div>
                  <div className='col-md-6'>
                  <FormikControl 
                      control="input"
                      placeholder="Enter landline number with STD code"
                      label="Landline Number (with STD code)"
                      name="landline_number"
                    />
                  </div>
                </div>
                <div className='text-center'>
                  <button type='submit' className='btn btn-secondary my-3 px-3 rounded-pill'>
                    View Bill <BsFillEyeFill />
                  </button>
                </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Broadband