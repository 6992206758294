import React from 'react'

const Downloads = () => {
  return (
    <div className='content-wrapper p-5'>
        <div className='container'>
            <h4 className='font-mulish'>
                Posters to download
            </h4>
        </div>
        
    </div>
  )
}

export default Downloads