import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import FormikControl from '../../../components/FormikControl';
import { Formik, Form } from 'formik'
import { CardHeader } from '../../../components/sections/CardHeader';
import { PaymentGateWayvalidationSchema } from '../validation'
import { ToastContainer, toast } from 'react-toastify';

const PaymentG = () => {
  const initialValues = {
    account_type: "",
    amount: "",
    mobile_number: "",
    email: "",
    card_digit:"",
    name:"",
  }
  const handleSubmit = (value, { resetForm }) => {
    toast.success('Data read successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    console.log('submit button clicked');
    resetForm()
  }
  return (
    <div className='content-wrapper'>
     <ToastContainer/>
      <Breadcrumbs title='Payment Gateway' path='retailer-dashboard' />
      <div className="container">
        <div className='card'>
          <CardHeader title="Payment Gateway" />
          <div className="card-body">
            <Formik 
              initialValues={initialValues}
              validationSchema={PaymentGateWayvalidationSchema}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className='row'>
                  <div className='col-md-6'>
                      <FormikControl
                        control='radio'
                        name="account_type"
                        label="Card Type"
                        options={[
                          { key: 1, value: "Debit Card" },
                          { key: 2, value: "Credit Card" },
                        ]}
                      />
                  </div>
                  <div className='col-md-6'>
                      <FormikControl
                      label="Amount"
                      control='input'
                      name="amount"
                      placeholder='Enter Amount'
                      />
                  </div>
                  <div className='col-md-6'>
                      <FormikControl
                        control='input'
                        name="mobile_number"
                        placeholder='Enter mobile number'
                        label="Mobile Number"
                      />
                  </div>
                  <div className='col-md-6'>
                      <FormikControl
                        control='input'
                        name="email"
                        placeholder='Enter email id'
                        label="Email Address"
                      />
                  </div>
                  <div className='col-md-6'>
                      <FormikControl
                        control='input'
                        name="card_digit"
                        placeholder='Enter last four digits of card'
                        label="Card Number"
                      />
                  </div>
                  <div className='col-md-6'>
                      <FormikControl
                        control='input'
                        name="name"
                        placeholder='Enter card holder name'
                        label="Card Holder Name"
                      />
                  </div>
                </div>
                <div className='text-center'>
                  <button className='btn btn-secondary rounded-pill px-3' type='submit'>
                    Submit
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentG