import React from "react";

const Table = (props) => {
  return (
    <div className={`table-responsive ${props.className}`}>
      <div className=" dataTables_wrapper dt-bootstrap4">
        <table
          id="example2"
          className="table table-bordered table-hover dataTable dtr-inline"
          aria-describedby="example2_info"
        >
          <thead>
            <tr>
              {props.tableHeading.map((element, index) => {
                return <th className={props.className}>{element}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {props.children}
            {props.tableBody && (
            <tr>
              <td className={`text-danger ${props.className}`} colSpan={props.colSpan}>{props.tableBody}</td>
            </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
