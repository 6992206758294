import React, { useCallback, useState } from 'react';
import { Formik, Form } from "formik";
import FormikControl from "../../../components/FormikControl";
import { toast } from 'react-toastify';
import { changePasswordValidation } from './Validation'
import 'react-toastify/dist/ReactToastify.css';
import { changePassword } from '../../../api/instance_method';


const ChangePassword = (props) => {
  const [confirmKyc, setConfirmKyc] = useState(false);
  const [show, setShow] = useState(false);

  const initialValues = {
    password: "",
    password_confirmation: "",
  };

  const onSubmit = (values) => {
    localStorage.setItem('values', JSON.stringify(values))
    if (!confirmKyc) {
      setShow(true);
    }
  }
  const handleConfirm = async () => {
    const val = await JSON.parse(localStorage.getItem('values'));
    const updatedValues = { ...val, id: props.usedId }
    document.getElementById('confirm').disabled=true;
    changePassword(updatedValues).then(
      (response) => {
        toast.success('Password changed successfully', { autoClose: 2000 });
        setShow(false)
        setTimeout(() => {
          props.closeModal();
          localStorage.removeItem('values')
        }, 2000);

      },
      (error) => {
        toast.error('Password cannot be changed.')
        if (error.response.data.status === false) {
          toast.error('Please try again later')
        }
      }
    );

  }


  return (
    <>

      <div className={`modal fade show ${props.isOpen ? 'show' : 'hide'}`} style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content ">
            <div className="modal-header bg-light">
              <h5 className="modal-title ">Change Password</h5>
              <button type="button" className="close color-white" onClick={props.closeModal}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={changePasswordValidation}
              >
                <Form>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <FormikControl
                          type="password"
                          control="input"
                          id="password"
                          name="password"
                          label="Enter new password"
                          placeholder="Password"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <FormikControl
                          type="password"
                          control="input"
                          id="password_confirmation"
                          name="password_confirmation"
                          label="Confirm password"
                          placeholder="Confirm password"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 text-right">
                        <button type="submit"
                          className="btn primary_button rounded-pill"
                        >
                          Save
                        </button>
                      </div>
                      <div className="col-6">
                        <button type="button"
                          className="btn btn-danger rounded-pill"
                          onClick={props.closeModal}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
          <div className='overlay'> </div>
        </div>
        {
          show && (
            <div className='text-center bg-light kyc-modal'>
              <p>Are you sure you want to add new password?</p>
              <h1 className='text-secondary'>
                <i className="bi bi-check-circle"></i></h1><br />
              <button
                id="confirm"
                className='mx-3 btn btn-outline-success'
                onClick={handleConfirm}>Yes</button>
              <button
                className='mx-3 btn btn-outline-danger'
                onClick={() => { setConfirmKyc(false); setShow(false) }}>Cancel</button>
            </div>
          )
        }
      </div>
    </>
  );
}

export default ChangePassword;