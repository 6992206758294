import React, { useState } from 'react';
import { Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import { BsInfoCircle } from 'react-icons/bs';

function Input(props) {
  const { id, name, label, ...rest } = props

  return (
    <>
      <div className="form-group" style={{ position: 'relative' }}>
        <div className='d-flex'>
          <label htmlFor={name} className='font-mulish'>{label}</label>
          <ErrorMessage name={name} className='my-0 py-0'>
            {errorMsg =>
              <div
                className="invalid-feedback my-0 error d-block"
                style={{
                  position: 'absolute',
                  right: '0%',
                  top: '46%',
                  backgroundColor: '#fff0f1',
                  width: '70%',
                  opacity: 0.8,
                  transition: '2s ease-in-out',
                  padding: '8px',
                  borderTopRightRadius: '8px',
                  borderBottomRightRadius: '8px'
                }}>
                <BsInfoCircle /> &nbsp;{errorMsg}</div>}
          </ErrorMessage>
        </div>
        <Field name={name} id={name} {...rest} 
        className={`form-control ${props.className}`} style={{ outline: 'none' }} />
        {/* <ErrorMessage name={name}>
          {errorMsg => <div className="invalid-feedback error d-block">
            <WarningRoundedIcon /> &nbsp;{errorMsg}{console.log(errorMsg)}</div>}
        </ErrorMessage> */}

        {/* <ErrorMessage name={name} className='my-5 py-0'>
          {errorMsg =>
            <div
              className="invalid-feedback my-5 text-center error d-block px-0 py-2"
              style={{
                // position: 'absolute',
                // left: '55%',
                // top: '0%',
                // backgroundColor: '#ffe4e6',
                // width: '45%',
                // opacity: 1,
                // transition: '2s ease-in-out',
                // borderTopLeftRadius: '0px',
                // zIndex:2,
                // borderTopRightRadius: '12px',
                // borderBottomRightRadius: '12px',
                // borderBottomLeftRadius: '12px',
              }}>
              <WarningRoundedIcon /> &nbsp; {errorMsg}</div>}
        </ErrorMessage> */}
      </div>

    </>
  );
}

export default Input;