import React from 'react'

const VerticalCard = (props) => {
  return (
    <div className='card text-center'>
       <div className='card-header text-center bg-custom-dark'>
        <div className='fw-bold text-center text-white'>
            <h5 className='text-center'>{props.title}</h5>
        </div>
       </div>
       <div className='card-body'>
        {props.children}
       </div>
    </div>
  )
}

export default VerticalCard