import React from 'react'
import { TiDownload } from "react-icons/ti";
const IdCards = () => {
  return (
    <div className='content-wrapper'>
        <div className='container py-5'>
            <button className='btn my-5 btn-info rounded-pill'>
            <TiDownload /> Download ID Card
            </button>
        </div>
    </div>
  )
}

export default IdCards