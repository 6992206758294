import React from 'react';
import { useLocation } from 'react-router-dom';
import  Add  from './Add'
import Edit from './Edit'

function FormElements(props) {
  const {state} = useLocation();
  return (
      <>
        {
          state ? <Edit/> : <Add/>
        }
      </>
    );
}

export default FormElements;