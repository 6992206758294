import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import CardComponent from '../../../components/sections/CardBody'

const TdsCertificate = () => {
  return (
    <div className='content-wrapper'>
      <Breadcrumbs title='TDS Certificate' path='/distributor-dashboard' />
      <div className='container'>
        <h3 className='text-center font-mulish fw-bold'>TDS Certificate</h3>
        <div className='row'>
          <div className='col-md-4'>
            <CardComponent className='bg-primary-subtle my-3 p-4 text-center'>
              <h4>Quarter 3 2021-22</h4>
              <p>You are not eligible for Quarter 3 2021-22 TDS Certificate</p>
            </CardComponent>
          </div>
          <div className='col-md-4'>
            <CardComponent className='bg-primary-subtle p-4 my-3 text-center'>
              <h4>Quarter 4 2021-22</h4>
              <p>You are not eligible for Quarter 3 2021-22 TDS Certificate</p>
            </CardComponent>
          </div>
          <div className='col-md-4'>
            <CardComponent className='bg-primary-subtle my-3 p-4 text-center'>
              <h4>Quarter 1 2022-23</h4>
              <p>You are not eligible for Quarter 3 2021-22 TDS Certificate</p>
            </CardComponent>
          </div>
          <div className='col-md-4'>
            <CardComponent className='bg-primary-subtle p-4 text-center'>
              <h4>Quarter 2 2022-23</h4>
              <p>You are not eligible for Quarter 3 2021-22 TDS Certificate</p>
            </CardComponent>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TdsCertificate