import React from 'react';
import { FaPlusCircle } from 'react-icons/fa'

const ScannerSetting = () => {
  return (
    <div className='d-grid gap-2'>
      <button className='btn btn-secondary rounded-pill'>
        <FaPlusCircle /> Mantra Installation Guide
      </button>
      <button className='btn btn-secondary rounded-pill'>
        <FaPlusCircle /> Morpho Installation Guide
      </button>
      <button className='btn btn-secondary rounded-pill'>
        <FaPlusCircle /> Secugen Installation Guide
      </button>
      <button className='btn btn-secondary rounded-pill'>
        <FaPlusCircle /> Startek Installation Guide
      </button>
      <button className='btn btn-secondary rounded-pill'>
        <FaPlusCircle /> Precision Installation Guide
      </button>
      <button className='btn btn-secondary rounded-pill'>
        <FaPlusCircle /> NEXT Installation Guide
      </button>
    </div>
  )
}

export default ScannerSetting