import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { getByToken, getDistributorByToken, getRetailerByToken } from '../api/instance_method';
import { login } from '../redux/slices/authSlice';
import Layout from '../layouts/Layout';

const PrivateRoute = ({ allowedRoles }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem('token');
  const userRole = useSelector((state) => state.auth.role );

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;

        if (userRole === 'admin') {
          response = await getByToken();
        } else if (userRole === 'distributor') {
          response = await getDistributorByToken();
        } else if (userRole === 'retailer') {
          response = await getRetailerByToken();
        } else {
          throw new Error('Invalid role');
        }

        if (response.data) {
          dispatch(login(response.data.user));
        } else {
          throw new Error('Invalid response data');
        }
      } catch (error) {
        console.error(error);
        sessionStorage.removeItem('token');
        dispatch(login(''));
        navigate('/');
      }
    };

    if (token && allowedRoles.includes(userRole)) {
      fetchData();
    } else {
      navigate('/');
    }
  }, [token, userRole, allowedRoles, dispatch, navigate]);

  return token ? <Layout /> : <Navigate to="/" />;
};

export default PrivateRoute;
