import React from 'react'
import { ImVideoCamera } from 'react-icons/im'

const Help = () => {
  return (
    <div className='card mx-auto'>
    <div className='card-header'>
      <div className='card-title'>
        Help & Support
      </div>
      <div className='card-tools'>
        <button className='btn rounded-pill btn-secondary'>
          Video Tutorials <ImVideoCamera style={{ height: '1.5rem', width: '1.5rem' }} />
        </button>
      </div>
    </div>
    <div className='card-body table-responsive'>
      <div className="dataTables_wrapper dt-bootstrap4">
        <table
          able
          id="example2"
          className="table table-bordered  table-striped table-hover dataTable dtr-inline"
          aria-describedby="example2_info"
          style={{ minHeight: '11rem' }}
        >
          <tbody>
            <tr>
              <th>Distributor Name</th>
              <th>Distributor Phone</th>
              <th>Distributor Email</th>
            </tr>
            <tr>
              <td colspan='7'>
                <p className='font-mulish'>No data yet</p>
              </td>
            </tr>
            <tr>
              <th>TSM Name</th>
              <th>TSM Phone</th>
              <th>TSM Email</th>
            </tr>
            <tr>
              <td colspan='7'>
                <p className='font-mulish'>No data yet</p>
              </td>
            </tr>
            <tr>
              <th>ASM Name</th>
              <th>ASM Phone</th>
              <th>ASM Email</th>
            </tr>
            <tr>
              <td colspan='7'>
                <p className='font-mulish'>No data yet</p>
              </td>
            </tr>
            <tr>
              <th>State Head Name</th>
              <th>State Head Phone</th>
              <th>State Head Email</th>
            </tr>
            <tr>
              <td colspan='7'>
                <p className='font-mulish'>No data yet</p>
              </td>
            </tr>
            <tr>
              <th></th>
              <th>Customer Care Number</th>
              <th>Customer Care Email</th>
            </tr>
            <tr>
              <td></td>
              <td>xxxxxxxxxx</td>
              <td>abc@gmail.com</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  )
}

export default Help