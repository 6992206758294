import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { BsInfoCircle } from 'react-icons/bs';
function Select(props) {
    const { id, name, label, options, ...rest } = props
    return (
        <div className="form-group">
          <div className='d-flex'>
              <label htmlFor={name} className='font-mulish'>{label}</label>
              <ErrorMessage name={name} className='my-0 py-0'>
                  {errorMsg =>
                      <div
                          className="invalid-feedback my-0 error d-block"
                          style={{
                              position: 'absolute',
                              right: '2%',
                              top: '38%',
                              backgroundColor: '#fff0f1',
                              width: '70%',
                              opacity: 0.8,
                              transition: '2s ease-in-out',
                              padding: '8px',
                              borderTopRightRadius: '8px',
                              borderBottomRightRadius: '8px'
                          }}>
                          <BsInfoCircle /> &nbsp;{errorMsg}</div>}
              </ErrorMessage>
          </div>
          <Field as="select" name={name} {...rest} className="form-control select-field custom-select">
              {
                  options.map(option => {
                      return (
                          <option key={option.key} value={option.key}>{option.value}</option>
                      )
                  })

              }
          </Field>

          {/* <ErrorMessage name={name} className='my-0 py-0'>
              {errorMsg =>
                  <div
                      className="invalid-feedback my-0 text-center error d-block px-0 py-2"
                      style={{
                          position: 'absolute',
                          left: '55%',
                          top: '58%',
                          backgroundColor: '#ffe4e6',
                          width: '45%',
                          opacity: 0.8,
                          transition: '1s ease-in-out',
                          borderTopLeftRadius: '0px',
                          borderTopRightRadius: '12px',
                          borderBottomRightRadius: '12px',
                          borderBottomLeftRadius: '12px',
                      }}>
                      <WarningRoundedIcon /> &nbsp; {errorMsg}</div>}
          </ErrorMessage> */}
        </div>
    );
}

export default Select;