import React from 'react'
import FormComponent from '../../../components/sections/statement/FormComponent'
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import Table from '../../../components/sections/Table';
import Button from '../../../components/elements/Button';
import { MdCloudDownload } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import { datePckerValidaton } from '../Validation';

const MyCommission = () => {

  const initialValues = {
    start_date: '', 
    end_date: '' 
  }

  const handleSubmitWallet = (value , {resetForm}) => {
    
    toast.success('Commission Earne read successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,   
      progress: undefined,
      theme: "light",
    });
    resetForm();
  }

  const handleCancleSubmit = (value , {resetForm}) => {
      toast.success('Date picker clear', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,   
        progress: undefined,
        theme: "light",
    });
    resetForm();
  }
  return (
    <div className='content-wrapper'>
       <ToastContainer />
      <Breadcrumbs title='Commission Earned' path='/distributor-dashboard' />
      <div className='container'>
        <FormComponent
          title='Commission Earned'
          nameOne='start_date'
          labelOne='Start Date'
          controlOne='datepicker'
          nameTwo='end_date'
          labelTwo='End Date'
          controlTwo='datepicker'
          initialValues = {initialValues}
          validationSchema={datePckerValidaton} 
          onSubmit={handleSubmitWallet}
          onClick={handleCancleSubmit}
        >
          <Table
            tableHeading={['TXNID', 'Date', 'Opening', 'Commission', 'TDS', 'Closing', 'Narration', 'Type', 'Txn Type']}
            tableBody='No data right now'
            colSpan='9'
            className='mt-5 mb-3'
          />
          <Button className='btn-secondary rounded-pill' value={<>Download <MdCloudDownload className='mb-1' /></>} />
        </FormComponent>
      </div>
    </div>
  )
}

export default MyCommission