import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import CardComponent from '../../../components/sections/CardBody'

const DownloadQr = () => {
  return (
    <div className='content-wrapper'>
      <Breadcrumbs title='QR Code' path='/retailer-dashboard' />
      <div className='container d-flex align-items-center'  style={{ height: '60vh' }}>
        <div className='card bg-light w-100 rounded-pill '>
          <button className='btn w-50 btn-secondary rounded-pill px-4'>
            Download QR Code
          </button>
        </div>
      </div>
    </div>
  )
}

export default DownloadQr