import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import MoneyTransferReport from '../../../components/sections/retailer/MoneyTransferReport'

const PaymentTransfer = () => {
  return (
    <div className='content-wrapper'>
      <Breadcrumbs title='Payment Gateway Transaction Report' path='retailer-dashboard' />
      <div className='container'>
       <MoneyTransferReport title="Payment Gateway Report" />
      </div>
    </div>
  )
}

export default PaymentTransfer