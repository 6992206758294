import React, { useEffect, useState } from 'react'
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setFeature } from '../redux/slices/authSlice';

const MantraButton = (props) => {
  const value = 'tamanna';
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formValue, setFormValue] = useState(null);
  const updatedformvalues = JSON.parse(localStorage.getItem('updated'));
  const formvalues = JSON.parse(localStorage.getItem('values'));
  const mantraButtonState = useSelector(state => state.auth.mantraButton)
  const feature = useSelector((state) => state.auth.feature);
  useEffect(() => {
    setFormValue(props.formData)
  }, []);
  const handleSubmit = async () => {
    // if (props.formData !== null) {
      var finalUrl = "https://127.0.0.1:8005"
      fetch('https://127.0.0.1:8005/', {
        method: 'RDSERVICE',
        headers: {
          'Content-Type': 'text/xml; charset=utf-8'
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.text();
        })
        .then(data => {
          const xmlDoc = new DOMParser().parseFromString(data, 'text/xml');
          const interfaceElements = xmlDoc.querySelectorAll('Interface');
          if (interfaceElements.length >= 2) {
            const MethodInfo = interfaceElements[0].getAttribute('path');
            const MethodCapture = interfaceElements[1].getAttribute('path');
            var captureUrl = finalUrl + MethodCapture
            var deviceInfoUrl = finalUrl + MethodInfo
            fetch(deviceInfoUrl, {
              method: 'DEVICEINFO',
              headers: {
                'Content-Type': 'text/xml; charset=utf-8;'
              },
            })
              .then(deviceInfoResponse => {
                if (!deviceInfoResponse.ok) {
                  throw new Error('Device info response was not ok');
                }
                return deviceInfoResponse.text();
              })
              .then(deviceInfoData => {
                console.log('Device info data recieved successfully');
              })
              .catch(deviceInfoError => {
                console.error('Device info fetch error:', deviceInfoError);
              });
            var DemoFinalString = ''
            var XML =
              '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="0" iCount="0" pCount="0" format="0" pidVer="2.0" timeout="10000" posh="UNKNOWN" env="P" /> ' + DemoFinalString + ' </PidOptions>';

            fetch(captureUrl, {
              method: 'CAPTURE',
              headers: {
                'Content-Type': 'text/xml; charset=utf-8;'
              },
              body: XML
            })
              .then(captureResponse => {
                if (!captureResponse.ok) {
                  throw new Error('Capture response was not ok');

                }
                return captureResponse.text();

              })
              .then(captureData => {
                const parsedCaptureData = new DOMParser().parseFromString(captureData, 'text/xml');
                const resElement = parsedCaptureData.querySelector('Resp');

                if (resElement) {
                  const errCode = resElement.getAttribute('errCode');
                  const errInfo = resElement.getAttribute('errInfo');

                  if (errCode === '0') {
                    if(feature === 'aeps') {
                      navigate('/aeps/confirm')
                    } else if (feature === 'aadharPay') {
                      navigate('/aadharpay/confirm')
                    }
                  } else {
                    console.log('Capture failed', errInfo);
                    toast.error('Fingerprint capture failed. Try again');
                    localStorage.removeItem('updated');
                  }
                } else {
                  console.log('invalid or unexpected response')
                }

                localStorage.setItem('data', value);

              })
              .catch(captureError => {
                console.error('Capture fetch error:', captureError);
              });
          }
          console.log('RDSERVICE Request Successful');
        })
        .catch(error => {
          console.error('Fetch error:', error);
        })
    }
  // }

  return (
    <div>
      <button className='btn btn-dark mx-3 rounded-pill px-3' type='submit' onClick={handleSubmit}>
        Scan Fingerprint <FingerprintIcon /> and Submit</button>
    </div>
  )
}

export default MantraButton