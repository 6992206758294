import React from 'react';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import Table from '../../../components/sections/Table';
import Card from '../../../components/sections/Card';

const OrderStatus = () => {
  return (
    <div className='content-wrapper'>
      <Breadcrumbs title='Product List' path='/distributor-dashboard' />
      <div className='container'>
        <Card title='Order status'>
        <Table
          tableHeading={['TXN ID', 'Product', 'No of Order', 'Status', 'Date','Delivery Address', 'Invoice No', 'Docket No']}
          tableBody='No data right now'
        />
        </Card>
      </div>
    </div>
  )
}

export default OrderStatus