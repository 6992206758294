import React, { useState, useEffect } from 'react'
import { getMatm } from '../../../api/instance_method';
import { toast } from 'react-toastify';
import Pagination from 'react-js-pagination';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import Loader from '../../../components/Loader';

const MatmAllOrder = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState('');

  useEffect(() => {
    getMatm(1).then(
      (response) => {
        setOrders(response.data.data.data);
        console.log(response.data.data.data);
        setTotalItemsCount(response.data.data.total);
      },
      (error) => {
        toast.error('Error fetching')
      }
    ).catch(
      (error) => {
        console.log(error);
      }
    )
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
  }, []);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    getMatm(pageNumber).then(
      (response) => {
        setOrders(response.data.data.data);
        setTotalItemsCount(response.data.data.total)
      }
    ).catch(
      (error) => {
        console.log(error)
      }
    )
  }
  return (
    <div className='content-wrapper'>
      <Breadcrumbs title='All Orders' path='/distributor-dashboard' />
      
      <div className='container mx-auto'>
        {isLoading ? (
          <Loader />
        ) : (
          <div className='card'>
            <div className='card-header'>
              <div className='card-title'>
                All Orders
              </div>
              <div className='card-tools d-flex mr-1'>
                <div className="text-dark mt-2  mx-3 pointer dropdown">
                  <div data-bs-toggle='dropdown'>
                    <h5><i className="bi bi-filter"></i></h5>
                  </div>
                  <div className="dropdown-menu m-0 p-0 px-1 dropdown-menu-sm dropdown-menu-right">
                    <a className='nav-link p-2 border-bottom dropdown-item font-weight-bold'
                    //  onClick={() => getDataByRole('')}
                    >
                      <div className='d-flex justify-content-between align-items-center'>
                        Clear Filter
                        <i className="bi bi-x-circle-fill"></i>
                      </div>
                    </a>
                    <a className='nav-link dropdown-item p-2'
                    //  onClick={() => getDataByRole(1)}
                    >
                      Cancel Order
                    </a>
                    <a className='nav-link p-2 dropdown-item'
                    // onClick={() => getDataByRole(2)}
                    >
                      Dispatch
                    </a>
                    <a className='nav-link p-2 dropdown-item'
                    // onClick={() => getDataByRole(2)}
                    >
                      Pending for Dispatch
                    </a>
                    <a className='nav-link p-2 dropdown-item'
                    // onClick={() => getDataByRole(2)}
                    >
                      Special Orders
                    </a>
                  </div>
                </div>
                <h5 className='mt-2 pointer'><i className="bi bi-cloud-arrow-down-fill"></i></h5>
              </div>
            </div>
            <div className='card-body table-responsive'>
              <div id="example2_wrapper" className="dataTables_wrapper dt-bootstrap4">
                <table
                  id="example2"
                  className="table table-bordered table-hover dataTable dtr-inline"
                  aria-describedby="example2_info"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Mobile Number</th>
                      <th>Amount</th>
                      <th>Address</th>
                      <th>City</th>
                      <th>State</th>
                      <th>Pin Code</th>
                      <th>Ordered At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.length ? (
                      orders.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index+1}</td>
                            <td>{item.mobile_number}</td>
                            <td>{item.amount}</td>
                            <td>{item.address}</td>
                            <td>{item.city}</td>
                            <td>{item.state}</td>
                            <td>{item.pincode}</td>
                            <td>{item.created_at}</td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={10}>No Record Found</td>
                      </tr>
                    )}

                  </tbody>
                </table>
                <div className=''>
                  <div className='row'>
                    <div className="col-sm-12 col-md-6">
                      {orders.length ? (<div className="dataTables_info text-primary" id="example2_info" role="status" aria-live="polite">
                        *Showing <span className='fw-bold'>{orders.length} </span>
                        of <span className='fw-bold'>{totalItemsCount} </span>entries
                      </div>) : (
                        ''
                      )}

                    </div>
                    <div className="col-sm-12 col-md-6 text-right ">
                      <div className="float-end dataTables_paginate paging_simple_numbers" id="example2_paginate">
                        {orders.length >
                          0 ? (
                          <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={totalItemsCount}
                            pageRangeDisplayed={10}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default MatmAllOrder