import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import { CardHeader } from '../../../components/sections/CardHeader'
import { TiDownload } from "react-icons/ti";
import { ImUpload2 } from "react-icons/im";

const BalanceCertificate = () => {
    return (
        <div className='content-wrapper'>
            <Breadcrumbs title="Balance Certificate" path="/retailer-dashboard" />
            <div className="container">
                <div className="card">
                    <CardHeader title="Balance Certificate" />
                    <div className="card-body">
                        <div className="d-flex justify-content-between">
                            <button className='btn btn-info rounded-pill'>
                                <TiDownload /> Download Balance Certificate English
                            </button>
                            <button className='btn btn-info rounded-pill'>
                                <TiDownload /> Download Balance Certificate Hindi
                            </button>
                            <button className='btn btn-warning rounded-pill'>
                            <ImUpload2 /> Upload Balance Certificate
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BalanceCertificate