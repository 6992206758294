import React from 'react'
import CardComponent from '../../../components/sections/CardBody';
import Button from '../../../components/elements/Button';
import { Form, Formik } from 'formik'
import FormikControl from '../../../components/FormikControl'
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import {ChangePasswordValidation} from '../Validation'
import { ToastContainer, toast } from 'react-toastify';

const ChangePassword = () => {

  const initialValues = {
    old_password : '',
    new_password : '',
    confrim_password: ''
  }

  const haddleChangePasswordSubmit = (value , {resetForm }) => {

    toast.success('Password change successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    
    console.log('password change');
    resetForm();

  }
  return (
    <div className='content-wrapper'>
      <ToastContainer/>
      <Breadcrumbs title='Change Password' path='/distributor-dashboard' />
      <div className='container'>
        <CardComponent body={
          <Formik 
            initialValues= {initialValues}
            onSubmit={haddleChangePasswordSubmit}
            validationSchema={ChangePasswordValidation}
            validateOnBlur={false}
            validateOnChange={false}
          >
            <Form>
              <div className='row'>
                <div className='col-md-4'>
                  <FormikControl
                    control='input'
                    placeholder='Enter old password'
                    label='Old Password'
                    name='old_password'
                  />
                </div>
                <div className='col-md-4'>
                  <FormikControl
                    control='input'
                    placeholder='Enter New password'
                    label='New Password'
                    name='new_password'
                  />
                </div>
                <div className='col-md-4'>
                  <FormikControl
                    control='input'
                    placeholder='Confirm new password'
                    label='Confrim Password'
                    name='confrim_password'
                  />
                </div>
              </div>
              <div className='text-center mt-3'>
                <Button className='btn-dark' type='submit' value='Submit' />
              </div>
            </Form>
          </Formik>
        }
          className='p-5'
        />
      </div>
    </div>
  )
}

export default ChangePassword;