import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import style from './HomePage.module.css'
import Signup from './signup/Signup';
import { useSelector } from 'react-redux';
import Services from './Services';
import ContactUs from './ContactUs';

const HomePage = () => {
  const token = sessionStorage.getItem('token');
  const role = useSelector((state) => state.auth.role)
  useEffect(() => {
    if (token && role === 'admin') {
      window.location.replace('/users');
    } else if (token && role === 'distributor') {
      window.location.replace('/distributor-dashboard');
    } else if (token && role === 'retailer') {
      window.location.replace('/retailer-dashboard');
    } else {
      sessionStorage.removeItem('token');
    }
  }, [role, token])

  return (
    <div className='' style={{ height: '100%' }}>
      {
        !token ? (
          <div className=''>
            <div className='navbar-background container-fluid'>
              <nav className={`navbar navbar-expand-lg navbar-light ${style.navbar_background}`}>
                <div className="container-fluid">
                  <Link to='/'
                    className="navbar-brand h1 text-white font-nova fw-bold">
                    MT PORTAL
                  </Link>
                  <div className={`position-relative ${style.dropdown}`}>
                    <button className={`rounded-pill font-oswald ${style.dropdown_button}`}>
                      Login
                    </button>
                    <div className={`position-absolute ${style.dropdown_content}`}>
                      <ul>
                        <li>
                          <Link to='/dist-login' className={`pe-4 text-dark font-mulish ${style.dropdown_text}`}>
                            Distributor
                          </Link>
                        </li>
                        <li>
                          <Link to='/retailer-login' className={`pe-4 text-dark font-mulish ${style.dropdown_text}`}>
                            Retailer
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
              <Signup />
            </div>
            <div className='container-fluid mb-5'>
            <Services />
            </div>
            <div className='navbar-background'>
            <ContactUs />
            </div>
          </div>
        ) : (
          ''
        )
      }
    </div>
  )
}

export default HomePage;