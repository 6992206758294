import { FaMoneyBillTransfer,FaIndianRupeeSign  } from "react-icons/fa6";
import { IoPersonAddSharp } from "react-icons/io5";
import { FaUsers,FaCarSide, FaQrcode  } from "react-icons/fa";
import { BsPiggyBank } from "react-icons/bs";
import { TbDeviceMobileDollar } from "react-icons/tb";
import { MdAccountBalance } from "react-icons/md";
import { LuUtilityPole } from "react-icons/lu";
import { RiVisaFill } from "react-icons/ri";

export const dashboardData = [
    {
        path: '/dmt/customer-login',
        icon: <FaMoneyBillTransfer />,
        heading: 'DMT',
        bgColor: 'bg-pink'
    },
    {
        path: '/aeps',
        icon: <FaMoneyBillTransfer />,
        heading: 'aeps',
        bgColor: 'bg-red'
    },
    {
        path: '/dmt/add-customer',
        icon: <IoPersonAddSharp />,
        heading: 'add customer',
        bgColor: 'bg-purple'
    },
    {
        path: '/dmt/all-customers',
        icon: <FaUsers />,
        heading: 'view customer',
        bgColor: 'bg-yellow'
    },
    {
        path: '/recharge-prepaid',
        icon: <TbDeviceMobileDollar />,
        heading: 'recharge',
        bgColor: 'bg-teal'
    },
    {
        path: '/adharpay',
        icon: <FaIndianRupeeSign />,
        heading: 'aadhar pay',
        bgColor: 'bg-info'
    },
    {
        path: '/emi-collection',
        icon: <BsPiggyBank />,
        heading: 'emi collection',
        bgColor: 'bg-indigo'
    },
    {
        path: '/axis-bank-account-open',
        icon: <MdAccountBalance />,
        heading: 'axis bank',
        bgColor: 'bg-danger'
    },
    {
        path: '/utility/broadband',
        icon: <LuUtilityPole />,
        heading: 'utility',
        bgColor: 'bg-orange'
    },
    {
        path: '/fast-tag',
        icon: <FaCarSide />,
        heading: 'fast tag',
        bgColor: 'bg-secondary'
    },
    {
        path: '/credit-card/bill',
        icon: <RiVisaFill />,
        heading: 'credit card payment',
        bgColor: 'bg-success'
    },
    {
        path: '/qr-code',
        icon: <FaQrcode />,
        heading: 'qr code',
        bgColor: 'bg-primary'
    },
]