import React from 'react';
import Breadcrumbs from '../../../../components/sections/Breadcrumbs';
import BulkOrderComponent from '../../../../components/sections/BulkOrderComponent';

const Emi = () => {
  return (
    <div className='content-wrapper'>
      <Breadcrumbs
        title='EMI ID'
        path='/distributor-dashboard'
      />
      <div className='container'>
        <BulkOrderComponent
          title='Hello Partner, EMI ID Are Available Now At below Slabs. Click Activate To Avail Services.'
          tableHeading={['SLAB', 'INR (Incl. Tax)']}
          tableData={
            [{ firstItem: '1 To 1', secondItem: '100 /- (inclusive of GST)' },
            { firstItem: '2 To 100', secondItem: '80 /- (inclusive of GST)' },]
          }
          button='Activate EMI ID'
        />
      </div>
    </div>
  )
}

export default Emi