import React from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { toast } from "react-toastify";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

function CheckBox(props) {
  const { setFieldValue } = useFormikContext();
  const { id, name, label, options, formik,  ...rest } = props;
  return (
    <div className="form-group">
      <label htmlFor={name} className='font-mulish'>{label}</label><br />
      <Field name={name} {...rest}>
        {({ field }) => {
          return options.map((option) => {
            return (
              <>
               
                <input
                  type="checkbox"
                  id={option.key}
                 {...field}
                  value={option.value}
                  //checked={field.value.includes(option.value) ? true : false}// Set checked based on field value
               className="form-check-input"
                />&nbsp;
                 <label htmlFor={option.key} className="form-check-label">{option.value}</label>
              </>
            );
          });
        }}
      </Field>
      {/* <ErrorMessage name={name}>
        {(errorMsg) => (
          <div className="invalid-feedback d-block"><WarningRoundedIcon /> &nbsp;{errorMsg}</div>
        )}
      </ErrorMessage> */}
      <ErrorMessage name={name} className='my-0 py-0'>
          {errorMsg =>
            <div
              className="invalid-feedback my-0 text-center error d-block px-0 py-2"
              style={{
                position: 'absolute',
                left: '55%',
                top: '85%',
                backgroundColor: '#ffe4e6',
                width: '45%',
                opacity: 0.8,
                transition: '1s ease-in-out',
                borderRadius: '8px'
              }}>
              <WarningRoundedIcon /> &nbsp; {errorMsg}</div>}
        </ErrorMessage>
    </div>
  );
}

export default CheckBox;
