import React from 'react';
import contact from '../../assets/images/contact.svg';
import styles from './ContactUs.module.scss'

const ContactUs = () => {
  return (
    <div>
      <div className={`${styles.background_image} position-relative`}>
        <div className="py-5">
          <div className="container-fluid">
            <div className={`font-mulish ${styles.top}`}>
              Have any query? 
            </div>
            <div className={`row justify-content-center`}>
              <div className="col-md-10">
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <h3 className="heading font-oswald text-dark fw-bold mb-4">Connect with us...</h3>
                    <p className='font-merriweather text-secondary'>
                      If you have any types of quries, you can send message from here. It's our pleasure to help you.
                    </p>
                    <p>
                      <img src={contact} alt="contact" className="img-fluid" />
                    </p>
                  </div>
                  <div className="col-md-6">
                    <form className="mb-5" method="post" id="contactForm" name="contactForm" noValidate="novalidate">
                      <div className="row">
                        <div className="col-md-12 form-group">
                          <input type="text" className="form-control" name="name" id="name" placeholder="Your name" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 form-group">
                          <input type="text" className="form-control" name="email" id="email" placeholder="Email" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 form-group">
                          <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 form-group">
                          <textarea className="form-control" name="message" id="message" cols="30" rows="7" placeholder="Write your message"></textarea>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <input type="submit" value="Send Message" className={`btn rounded-pill font-mulish fw-bold py-2 px-4 ${styles.button_outline_green}`} />
                          <span className="submitting"></span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div>
  )
}

export default ContactUs