import { Formik, Form } from 'formik'
import React from 'react'
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import FormikControl from '../../../../components/FormikControl'
import { selectBanks } from '../../../../api/instance_method';
import { cashWithdrawlApSchema } from '../../validation';
import MantraButton from '../../../../components/MantraButton';
import { setFeature, setMantraButton } from '../../../../redux/slices/authSlice';

const Balance = () => {
  const dispatch = useDispatch();
  const [bank, setBank] = useState([]);
  const [formData, setformData] = useState('');
  const initialValues = {
    bank_id: '',
    amount: '',
    mobile_number: '',
    aadhar_number: '',
    myCheckbox: ''
  }

  dispatch(setFeature('aadharPay'))
  useEffect(() => {
    selectBanks().then(
      (response) => {
        const banks = response.data.data;
        const allBanks = [
          { key: '--Select Bank--', value: '--Select Bank--' },
          ...banks.map((bank) => ({
            key: bank.id,
            value: bank.name,
          })),

        ]
        setBank(allBanks)
      }
    )
  }, []);

  const handleSubmit = (values) => {
    if (values.myCheckbox) {
      setformData({ ...values, services: 'Balance Enquiry' });
      localStorage.setItem('Form Values', JSON.stringify({ ...values, services: 'Balance Enquiry' }));
      dispatch(setMantraButton(true));
    } else {
      toast.error('Please accept terms and conditions')
    }
  }

  return (
    <div>
      <ToastContainer />
      <Formik
        initialValues={initialValues}
        validationSchema={cashWithdrawlApSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className='row'>
            <div className='col-md-6'>
              <FormikControl
                control='input'
                label='Mobile Number'
                placeholder='Enter Mobile Number'
                name='mobile_number'
              />
            </div>
            <div className='col-md-6'>
              <FormikControl
                control='input'
                label='Aadhar Number'
                placeholder='Enter Aadhar Number'
                name='aadhar_number'
              />
            </div>
            <div className='col-md-6'>
              <FormikControl
                control='select'
                label='Select Bank'
                options={bank}
                name='bank_id'
              />
            </div>
            <div className='col-md-6'>
              <FormikControl
                control='input'
                label='Amount'
                placeholder='Enter Amount'
                name='amount'
              />
            </div>
          </div>
          <p className='text-primary'>Please ask customer to place his thumb for impression on biometric scanner.</p>
          <div style={{ marginRight: '-2rem' }} className='font-mulish ml-3'>
            <FormikControl
              control="checkbox"
              name="myCheckbox"
              checked
              options={[
                { key: '1', value: 'I accept all terms & conditions' },
              ]}
            /> &nbsp;
          </div>
          <div className='justify-content-center d-flex'>
            <MantraButton formData={formData} />
          </div>
          <div className='text-center my-5'>
            <button type='button' className='btn btn-danger rounded-pill  px-3 mx-3'>
              Click to lodge a complaint with the issuer bank of your customer
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default Balance