import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BsPersonAdd } from 'react-icons/bs';
import { getCustomers } from '../../api/instance_method';
import RetailerHeader from './RetailerHeader';
import Loader from '../../components/Loader';
import Pagination from 'react-js-pagination';
import Breadcrumbs from '../../components/sections/Breadcrumbs';

const AllCustomers = () => {
  const [tableData, setTableData] = useState([]);
  const [totalItemsCount, setTotalItemsCount] = useState('');
  const [activepage, setActivePage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const token = sessionStorage.getItem('token')

  useEffect(() => {
    if (token) {
      getCustomers(1).then(
        (response) => {
          setTableData(
            response.data.data.data
          );
          setTotalItemsCount(
            response.data.data.total
          )
        },
      );
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 2000)
  }, [])

  const handlePageChange = (pagenumber) => {
    setActivePage(pagenumber);
    getCustomers(pagenumber).then(
      (response) => {
        setTableData(
          response.data.data.data
        );
        setTotalItemsCount(
          response.data.data.total
        )
      },
    );
  }
  return (
    <div className='content-wrapper'>
      <RetailerHeader />
      <Breadcrumbs title='Customers' path='/retailer-dashboard' />
     
      <div className="container-fluid mx-auto">
        {isLoading ? (
          <Loader />
        ) : (
          <div className='card mx-auto'>
            <div className='card-header'>
              <div className='card-title'>
                Customers
              </div>
              <div className='card-tools'>
                <Link to='/dmt/add-customer' className='btn'>
                  <BsPersonAdd style={{ height: '1.5rem', width: '1.5rem' }} />
                </Link>
              </div>
            </div>
            <div className='card-body table-responsive'>
              <div className="dataTables_wrapper dt-bootstrap4">
                <table
                  id="example2"
                  className="table table-bordered  table-striped table-hover dataTable dtr-inline"
                  aria-describedby="example2_info"
                  style={{ minHeight: '11rem' }}
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>D.O.B</th>
                      <th>Mobile Number</th>
                      <th>Address</th>
                      <th>City</th>
                      <th>State</th>
                      <th>Pincode</th>
                    </tr>
                  </thead>
                  {tableData.length ? (
                    <tbody>
                      {tableData && tableData.map((item) => {
                        return (<tr key={item.index}>
                          <td>{item.name}</td>
                          <td>{item.dob}</td>
                          <td>{item.mobile_number}</td>
                          <td>{item.address}</td>
                          <td>{item.city}</td>
                          <td>{item.state}</td>
                          <td>{item.pincode}</td>
                        </tr>
                        )
                      })}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colspan='7'>
                          <p className='font-mulish'>No Customer Found</p>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
            <div className='mx-3'>
              <div className='row'>
                <>
                  <div className="col-sm-12 col-lg-6">
                    <div className="dataTables_info text-primary" id="example2_info" role="status" aria-live="polite">
                      *Showing<span className='fw-bold'> {tableData.length} </span>
                      of <span className='fw-bold'>{totalItemsCount} </span>entries
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6 text-right ">
                    <div className="float-lg-end pagination-sm pagination justify-content-start  dataTables_paginate paging_simple_numbers" id="example2_paginate">
                      <Pagination
                        activePage={activepage}
                        itemsCountPerPage={10}
                        totalItemsCount={totalItemsCount}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AllCustomers