import React from 'react';
import { Field, ErrorMessage } from 'formik';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

function RadioButton(props) {
  const { id, name, label, options, ...rest } = props
  return (
    <div className="form-group">
      <label htmlFor={name} className='font-mulish'>{label}</label>
      <Field name={name} {...rest} >
        {
          ({ field }) => {
            return options.map(option => {
              return (
                <>
                  <input type="radio"
                    checked={field.value === option.key} id={option.key} {...field} className='ml-3 mr-2' value={option.key} />
                  <label className="form-check-label" htmlFor={option.key}>{option.value}</label>
                    
                </>
              );
            })
          }
        }
      </Field>
      {/* <ErrorMessage name={name}>
            {errorMsg =><div className="invalid-feedback d-block">
            <WarningRoundedIcon /> &nbsp;{errorMsg}</div>}
        </ErrorMessage> */}
      <ErrorMessage name={name} className='my-0 py-0'>
        {errorMsg =>
          <div
            className="invalid-feedback my-0 text-center error d-block px-0 py-2"
            style={{
              position: 'absolute',
              left: '55%',
              top: '0%',
              backgroundColor: '#ffe4e6',
              width: '45%',
              opacity: 0.8,
              transition: '1s ease-in-out',
              borderTopLeftRadius: '0px',
              borderTopRightRadius: '12px',
              borderBottomRightRadius: '12px',
              borderBottomLeftRadius: '12px',
            }}>
            <WarningRoundedIcon /> &nbsp; {errorMsg}</div>}
      </ErrorMessage>
    </div>
  );
}

export default RadioButton;