import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom"
import Pagination from "react-js-pagination";
import { useNavigate } from 'react-router-dom';
import ChangePassword from './ChangePassword';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { deleteUser, getUserByRole, getUsers, searchUser } from '../../../api/instance_method';
import { useDispatch } from 'react-redux';
import { setRole } from '../../../redux/slices/authSlice';
import Edit from './Edit';
import { Form, Formik } from 'formik';
import FormikControl from '../../../components/FormikControl';
import { userSearchParams } from '../../../helpers/constant';
import Loader from '../../../components/Loader';
import style from './List.module.scss';
import UserRow from '../../../components/user/UserRow';

const List = (props) => {
  let navigate = useNavigate();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [usedId, setUserId] = useState('');
  const [users, setUsers] = useState([]);
  const [activepage, setActivePage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [status, setStatus] = useState('');
  const [search, setSearch] = useState([]);
  const [role, setNewRole] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const token = sessionStorage.getItem('token')
  const dispatch = useDispatch();

  const openModal = (id) => {
    setUserId(id)
    setIsOpen(true);
  };
  const openEditModal = (id) => {
    setUserId(id)
    setIsEditOpen(true);
  };
  const closeEditModal = () => {
    setIsEditOpen(false);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const getDataByRole = (newRole) => {
    getUserByRole(newRole, 1).then(
      (response) => {
        setTotalItemsCount(
          response.data.data.total
        );
        setUsers(
          response.data.data.data
        );
        setNewRole(newRole);
        dispatch(setRole('admin'))
      },
      (error) => {
        if (error) {
          navigate('/');
          toast.error('An error occurred. Please login again.')
        }
      }
    );
  }
  useEffect(() => {
    if (token) {
      getUsers(1).then(
        (response) => {
          setTotalItemsCount(response.data.data.total);
          setUsers(response.data.data.data);
          dispatch(setRole('admin'))

        },
        (error) => {
          if (error) {
            navigate('/')
            toast.error('Error fetching users. Please login again.')
          }
        }
      ).catch(
        (error) => {
          console.log(error)
        }
      );
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
  }, [isEditOpen, dispatch, navigate, token]);

  const handleClearSearch = () => {
    setSearch([])
  }
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    if (role) {
      getUserByRole(role, pageNumber).then(
        (response) => {
          setUsers(response.data.data.data);
          setTotalItemsCount(response.data.data.total);
        }
      )
    } else {
      getUsers(pageNumber).then(
        (response) => {
          setUsers(response.data.data.data);
          setTotalItemsCount(response.data.data.total);
        },
        (error) => {
          if (error) {
            navigate('/')
            toast.error(error)
          }
        }
      )
    }
  }
  let remove = (id) => {
    deleteUser(id).then(
      (response) => {
        toast.error('User removed successfully');
        const element = document.getElementById(`tr-${id}`);
        element.remove();
      },
      (error) => { }
    );
  }

  let addBank = (id) => {
    navigate(`/bank-detail`, { state: { id: id } });
  }
  const searchParamsValues = {
    search_key: ''
  }
  const handleSearch = (values) => {
    searchUser(values).then(
      (response) => {
        if (response.data.data.total === 0) {
          toast.warning('No user found');
        } else {
          setSearch(response.data.data.data);
        }
      }
    )
      .catch(
        (error) => {
          toast.error('User cannot be searched. Try again later.')
        }
      )
  };


  return (

    <div className="content-wrapper screen-height ">

      <ToastContainer position="top-right" />
      <>
        {isOpen && (
          <ChangePassword closeModal={closeModal} usedId={usedId} />
        )}
        {isEditOpen && (
          <Edit closeModal={closeEditModal} usedId={usedId} />
        )
        }
      </>
      <section className="mb-5">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">

              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to='/dashboard' className='breadcrumb-link'>Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Users</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <div>
          <Formik
            initialValues={searchParamsValues}
            onSubmit={handleSearch}
          >
            <Form>
              <div className='row mx-auto'>
                <div className='col-md-4'>
                  <FormikControl
                    type="search"
                    control="input"
                    name="search_value"
                    placeholder="Search"
                  />
                </div>
                <div className='col-md-4'>
                  <FormikControl
                    control="select"
                    options={userSearchParams}
                    name="search_key"
                  />
                </div>
                <div className='col-md-4 mt-2'>
                  <button
                    className={`btn px-3 btn-dark ${style.search}`}
                    type='submit'
                  >
                    <i className="bi bi-search"></i> &nbsp; Search
                  </button>
                  <button
                    className={`btn px-3 bg-white btn-outline-dark ${style.cancel_search}`}
                    type='button'
                    onClick={handleClearSearch}
                  >
                    x
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
        <div className="container-fluid">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="row mt-3">
              <div className="col-12 card-height">

                <div className="card">
                  <div className="card-header">

                    <div className='card-tools d-flex'>
                      <div className="text-dark mt-2 mr-1 pointer dropdown">
                        <div data-bs-toggle='dropdown'>
                          <h5><i className="bi bi-filter"></i></h5>
                        </div>
                        <div className="dropdown-menu m-0 p-0 px-1 dropdown-menu-sm dropdown-menu-right">
                          <div className='nav-link p-2 border-bottom dropdown-item font-weight-bold'
                            onClick={() => getDataByRole('')}
                          >
                            <div className='d-flex justify-content-between align-items-center'>
                              Clear Filter
                              <i className="bi bi-x-circle-fill"></i>
                            </div>
                          </div>
                          <div className='nav-link dropdown-item p-2'
                            onClick={() => getDataByRole(1)}
                          >
                            Distributor
                          </div>
                          <div className='nav-link p-2 dropdown-item'
                            onClick={() => getDataByRole(2)}
                          >
                            Retailer
                          </div>
                        </div>
                      </div>
                      {
                        !props.showForm && <button type='button' className='btn' onClick={() => props.toggleForm(true)}>
                          <PersonAddIcon />
                        </button>
                      }
                    </div>
                  </div>
                  <div className="card-body table-responsive">

                    <div id="example2_wrapper" className="dataTables_wrapper dt-bootstrap4">
                      <table
                        id="example2"
                        className="table table-bordered table-striped table-hover dataTable dtr-inline"
                        aria-describedby="example2_info"
                      >
                        <thead>
                          <tr className='font-mulish'>
                            <th className='text-center align-middle'>Name</th>
                            <th className='text-center align-middle'>Email</th>
                            <th className='text-center align-middle'>Mobile</th>
                            <th className='text-center align-middle'>Pan</th>
                            <th className='text-center align-middle'>Adhar</th>
                            <th className='text-center align-middle'>Role</th>
                            <th className='text-center align-middle'>Membership Plan</th>
                            <th className='text-center align-middle'>Main Balance</th>
                            <th className='text-center align-middle'>Cash-in Balance</th>
                            <th className='text-center align-middle'>Status</th>
                            <th className='text-center align-middle'>Added On</th>
                            <th className='text-center align-middle'>Action</th>
                          </tr>
                        </thead>
                        {users.length ? (
                          <tbody>
                            {search.length ? (
                              search.map((item, index) => (
                                <UserRow
                                  key={index}
                                  user={item}
                                  onRemove={remove}
                                  onEdit={openEditModal}
                                  onOpenModal={openModal}
                                  onAddBank={addBank}
                                  status={status}
                                />
                              ))
                            ) : (
                              users.map((element, index) => (
                                <UserRow
                                  key={index}
                                  user={element}
                                  onRemove={remove}
                                  onEdit={openEditModal}
                                  onOpenModal={openModal}
                                  onAddBank={addBank}
                                  status={status}
                                />
                              ))
                            )}
                          </tbody>
                        ) : (
                          ''
                        )}
                      </table>
                    </div>
                  </div>
                  <div className='mx-3 mt-3'>
                    <div className='row'>
                      <>
                        <div className="col-sm-12 col-lg-6">
                          <div className="dataTables_info text-primary" id="example2_info" role="status" aria-live="polite">
                            *Showing<span className='fw-bold'> {users.length} </span>
                            of <span className='fw-bold'>{totalItemsCount} </span>entries
                          </div>
                        </div>
                        <div className="col-sm-12 col-lg-6 text-right ">
                          <div className="float-lg-end pagination-sm pagination justify-content-start  dataTables_paginate paging_simple_numbers" id="example2_paginate">
                            <Pagination
                              activePage={activepage}
                              itemsCountPerPage={10}
                              totalItemsCount={totalItemsCount}
                              pageRangeDisplayed={10}
                              onChange={handlePageChange}
                              itemClass="page-item"
                              linkClass="page-link"
                            />
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        </div>
      </section>
    </div>
  );
}

export default List;