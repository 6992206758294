import {  FaCoins } from 'react-icons/fa';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import {BsFillCreditCard2FrontFill} from 'react-icons/bs';

export const adminData = [
    {
        heading: 'Aeps Slab',
        subHeading: 'Users',
        backgroundColor: 'bg-teal',
        path: '/aeps-slab',
        icon: <FaCoins style={{ height: '3.5rem', width: '3.5rem' }} />
    },
    {
        heading: 'Membership',
        subHeading: 'Plans',
        path: '/membership-plans',
        backgroundColor: 'bg-yellow',
        icon: <CardMembershipIcon style={{ height: '3.5rem', width: '3.5rem' }} />
    },
    {
        heading: 'M-ATM',
        subHeading: 'Orders',
        path: '/orders/m-atm',
        backgroundColor: 'bg-primary',
        icon: <BsFillCreditCard2FrontFill style={{ height: '3.5rem', width: '3.5rem' }} />
    },
    {
        heading: 'Transaction',
        subHeading: 'DMT',
        path: '/transaction-history/dmt',
        backgroundColor: 'bg-pink',
        icon:  <HistoryRoundedIcon style={{ height: '3.5rem', width: '3.5rem' }} />
    },
    {
        heading: 'Transaction',
        subHeading: 'AEPS',
        path: '/transaction-history/aeps',
        backgroundColor: 'bg-danger',
        icon:  <HistoryRoundedIcon style={{ height: '3.5rem', width: '3.5rem' }} />
    },
    {
        heading: 'Transaction',
        subHeading: 'Topup Transfer',
        path: '/transaction-history/topup',
        backgroundColor: 'bg-orange',
        icon: <HistoryRoundedIcon style={{ height: '3.5rem', width: '3.5rem' }} />
    },
]