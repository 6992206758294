import React from 'react'
import FormikControl from '../../../../components/FormikControl'
import { Formik, Form } from 'formik'
import { IoSend } from "react-icons/io5";

const SendForm = () => {
    return (
        <div>
            <Formik>
                <Form>
                    <div className="row  d-flex justify-content-center align-items-center">
                        <div className="col-md-6">
                            <FormikControl
                                control="input"
                                placeholder="Enter amount"
                                label="Amount"
                                name="amount"
                            />
                        </div>
                        <div className="col-md-6 mt-md-3">
                            <button className="btn btn-dark">
                                Send <IoSend />
                            </button>
                        </div>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

export default SendForm