import React from 'react';
import DateView from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';


function FileUpload(props) {
    const { id, name, label, ...rest } = props

    return (
        <div className="form-group">
            <label htmlFor={name} className='font-mulish'>{label}</label>
            <Field name="firstName">
                {
                    ({ form, field }) => {
                        const { setFieldValue } = form;
                        //const { value } = field;
                        return (
                            <>
                                <input
                                    id={name}
                                    name={name}
                                    type="file"
                                    onChange={(event) => {
                                        setFieldValue("avatar", event.target.files[0]);
                                    }}
                                />
                            </>
                        );
                    }}
            </Field>
            {/* <ErrorMessage name={name}>
                {errorMsg =><div className="invalid-feedback d-block">
                <WarningRoundedIcon /> &nbsp;{errorMsg}</div>}
            </ErrorMessage>                   */}
           <ErrorMessage name={name} className='my-0 py-0'>
          {errorMsg =>
            <div
              className="invalid-feedback my-0 text-center error d-block px-0 py-2"
              style={{
                position: 'absolute',
                left: '55%',
                top: '85%',
                backgroundColor: '#ffe4e6',
                width: '45%',
                opacity: 0.8,
                transition: '1s ease-in-out',
                borderTopLeftRadius: '0px',
                borderTopRightRadius: '12px',
                borderBottomRightRadius: '12px',
                borderBottomLeftRadius: '12px',
              }}>
              <WarningRoundedIcon /> &nbsp; {errorMsg}</div>}
        </ErrorMessage>
        </div>
    );
}

export default FileUpload;