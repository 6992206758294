import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import FormikControl from '../../../components/FormikControl';
import { approvedRetailers, getTopupReport } from '../../../api/instance_method';
import { useDispatch } from 'react-redux';
import { setRole } from '../../../redux/slices/authSlice';
import { reportSchema } from '../Validation';
import DistributorHeader from '../DistributorHeader';
import Report from './Report';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';

const TopUp = () => {
   const token = sessionStorage.getItem('token')
   const dispatch = useDispatch();
   const [users, setUsers] = useState([]);
   const [data, setData] = useState([]);
   const [nextPageData, setnextPageData] = useState([]);
   const [show, setShow] = useState(false);
   const [totalItems, setTotalItems] = useState('')

   const initialValues = {
      user_id: '',
      from_date: '',
      to_data: '',
      report: ''
   }

   useEffect(() => {
      if (token) {
         approvedRetailers().then(
            (response) => {
               const retailers = response.data.data;
               const allRetailers = [
                  { key: '', value: '--Select Retailer--' },
                  ...retailers.map((element, id) => ({
                     key: element.id,
                     value: element.first_name
                  }))
               ]
               setUsers(allRetailers);
               dispatch(setRole('distributor'));
            },
         );
      }
   }, []);


   const onSubmit = (val) => {
      setnextPageData(val)
      document.getElementById('viewReport').disabled = true
      if (token) {
         getTopupReport(val, 1).then(
            (response) => {
               setData(response.data.data.data)
               setTotalItems(response.data.data.total)
               setShow(true)
               document.getElementById('viewReport').disabled = false
            },
            (error) => {
               console.log(error)
            }
         ).catch(
            (error) => {
               console.log(error)
            }
         )
      }
   }

   
   return (
      <div className=' content-wrapper'>
         <DistributorHeader />
         <Breadcrumbs title='Retailers Report' path='/distributor-dashboard' />
         
         <div className='container'>
            <div className='card mx-auto'>
               <div className='card-header pb-0 pt-3'>
                  <div className='card-title'>
                     E-Value Retailer Report
                  </div>
               </div>
               <div className='card-body'>
                  <Formik
                     initialValues={initialValues}
                     onSubmit={onSubmit}
                     validateOnBlur={false}
                     validateOnChange={false}
                     validationSchema={reportSchema}
                  >
                     <Form>
                        <div className='row mx-4'>
                           <div className='col-md-6 col-sm-12'>
                              <FormikControl
                                 control="datepicker"
                                 name="from_date"
                                 label="Start Date :"
                              />
                           </div>
                           <div className='col-md-6 col-sm-12'>
                              <FormikControl
                                 control="datepicker"
                                 name="to_data"
                                 label="End Date :"
                              />
                           </div>
                        </div>
                        <div className='row mx-4'>
                           <div className='col-md-6 col-sm-12'>
                              <FormikControl
                                 control="select"
                                 name="user_id"
                                 label="Select Retailers :"
                                 options={users}
                              />
                           </div>
                           <div className='col-md-6 col-sm-12'>
                              <FormikControl
                                 control="select"
                                 id="report"
                                 name="report"
                                 label="Report :"
                                 options={[
                                    { key: 'Option1', value: 'Select...' },
                                    { key: 'Option2', value: 'Transaction Report' },
                                    // { key: 'Option3', value: 'Consolidate Report' },
                                 ]}
                              />
                           </div>
                        </div>
                        <div className='row text-center'>
                           <div className='col-12'>
                              <button
                              id='viewReport'
                                 className='btn btn-secondary rounded-pill'
                                 type='submit'>
                                 View
                              </button>
                           </div>
                        </div>
                     </Form>
                  </Formik>
               </div>
            </div>
            {show && (
               <Report
                  setShow={setShow}
                  data={data}
                  totalItems={totalItems}
                  nextPageData={nextPageData}
               />
            )}
         </div>
      </div>
   )
}

export default TopUp