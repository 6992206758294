import React from 'react';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import CardComponent from '../../../components/sections/CardBody';
import DistributorHeader from '../DistributorHeader';
import { Link } from 'react-router-dom';
import styles from './BulkOrder.module.scss';
import { bulkOrder } from '../../../data/distributor/bulkorder';

const BulkOrders = () => {
  return (
    <div className='content-wrapper'>
      <DistributorHeader />
      <Breadcrumbs title='Bulk Orders' path='/distributor-dashboard' />
      <div className='container'>
        <div className='row text-center'>
          {bulkOrder.map((data, id) => (
            <div className='col-md-4' key={id}>
              <CardComponent
                body={
                  <h4 className={`p-4 ${styles.border}`}>
                    <Link
                      to={data.path}
                      className={styles.text}
                      data-content='QR Code'
                    >
                      {data.heading}
                    </Link>
                  </h4>
                }
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default BulkOrders