import React from 'react'
import Card from '../../../components/sections/Card'
import Breadcrumbs from '../../../components/sections/Breadcrumbs';

const TopUpTiming = () => {
  return (
    <div className='content-wrapper'>
        <Breadcrumbs title='Fund/Limit Timing' path='/ditributor-dashboard'/>
      <div className='container'>
        <Card title='Fund / Limit Timing' body={<>
          <table className='table'>
            <tbody>
              <tr>
                <td>Monday to Saturday</td>
                <td className='fw-bold'>From 8 AM to 10 PM</td>
              </tr>
              <tr>
                <td>Sunday</td>
                <td className='fw-bold'>From 10 AM to 7 PM</td>
              </tr>
            </tbody>
          </table>
          <p className='text-monospace mt-3 text-info'>
            A Fund/Limit request gets approved in about 10 to 15 minutes after amount credited in your account.</p>
        </>}
        />
      </div>
    </div>
  )
}

export default TopUpTiming