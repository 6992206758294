import React, {useState, useEffect} from 'react'
import DistributorHeader from './DistributorHeader';
import Breadcrumbs from '../../components/sections/Breadcrumbs';
import Loader from '../../components/Loader';
import Help from '../../components/sections/Help';

const DistributorHelp = () => {
    const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000)
  }, [])
  return (
    <div className='content-wrapper'>
      <DistributorHeader />
      <Breadcrumbs title='Help & Support' path='/distributor-dashboard' />
     
      <div className="container-fluid mx-auto">
        {isLoading ? (
          <Loader />
        ) : (
         <Help />
        )}
      </div>
    </div>
  )
}

export default DistributorHelp;