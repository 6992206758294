export const bulkOrder = [
    {
        heading: 'QR Code',
        path: '/bulk-orders/qr-code'
    },
    {
        heading: 'Axis Bank',
        path: '/bulk-orders/axis-bank'
    },
    {
        heading: 'Finequs (Finolet Loan)',
        path: '/bulk-orders/finequs-loan'
    },
    {
        heading: 'EMI ID',
        path: '/bulk-orders/emi-id'
    },
    {
        heading: 'AEPS Plus',
        path: '/bulk-orders/aeps-plus'
    },
]