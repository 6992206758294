import React from 'react';
import Breadcrumbs from '../../../../components/sections/Breadcrumbs';
import BulkOrderComponent from '../../../../components/sections/BulkOrderComponent';
import CardComponent from '../../../../components/sections/CardBody';
import { Form, Formik } from 'formik';
import FormikControl from '../../../../components/FormikControl';
import { BiRightArrowAlt } from 'react-icons/bi';
import { convertDate } from '../../../../globalfunctions/functions';

const QrCode = () => {
  const currentDate = new Date();
  const date = convertDate(currentDate)

  return (
    <div className='content-wrapper'>
      <Breadcrumbs
        title='Axis Bank'
        path='/distributor-dashboard'
      />
      <div className='container'>
        <div>
          <table className='table'>
            <thead>
              <tr>
                <th>Per ID Activation Cost</th>
                <th>Number of ID Bokked</th>
                <th>Number of ID Activated</th>
                <th>Number of ID Left</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>450.00</td>
                <td>4</td>
                <td>3</td>
                <td>1</td>
              </tr>
            </tbody>
          </table>
        </div>
        <BulkOrderComponent
          title='Hello Partner, QR Code Are Available Now At below Slabs.'
          tableHeading={['SLAB', 'INR (Incl. Tax)']}
          tableData={
            [{ firstItem: '2 To 4', secondItem: '475 /- (inclusive of GST)' },
            { firstItem: '4 To 9', secondItem: '450 /- (inclusive of GST)' },
            { firstItem: '10 To 24', secondItem: '425 /- (inclusive of GST)' },
            { firstItem: '25 To 50', secondItem: '400 /- (inclusive of GST)' }]
          }
          button='Activate EMI ID'
        />
        <CardComponent
          body={<>
            <h6 className="my-4 bg-secondary text-center py-2 rounded">
              Book More ID'S For QR CODE IDs
            </h6>
            <Formik>
              <Form>
                <div className='d-flex'>
                  <FormikControl
                    control='input'
                    label='Number of IDs'
                    placeholder='Enter ID'
                    name='id'
                  />
                  <div className='mx-4' style={{ marginTop: '2rem' }}>
                    <button className='btn btn-dark rounded-pill'>
                      <BiRightArrowAlt className='font-1' />
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
          </>}
        />
        <CardComponent
          body={<>
            <h6 className="my-4 bg-secondary text-center py-2 rounded">
              Service Ledger
            </h6>
            <div className='container'>
              <Formik>
                <Form>
                  <div className='row'>
                    <div className='col-md-5'>
                      <FormikControl
                        control='datepicker'
                        label='Start Date'
                        name='id'
                      />
                    </div>
                    <div className='col-md-5'>
                      <FormikControl
                        control='datepicker'
                        label='End Date'
                        name='id'
                      />
                    </div>
                    <div className='col-md-2'>
                      <div className='mx-4' style={{ marginTop: '2rem' }}>
                        <button className='btn btn-dark rounded-pill'>
                          <BiRightArrowAlt className='font-1' />
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              </Formik>
            <p className='mt-4 font-mulish text-danger'>
              Filter For Service : QR CODE ID's From {date} To {date}
            </p>
            </div>
            <table className='table table-border'>
              <thead>
                <tr>
                  <th>Opening</th>
                  <th>Number of ID's</th>
                  <th>Closing</th>
                  <th>Date</th>
                  <th>Narration</th>
                </tr>
              </thead>
            </table>
          </>}
        />
      </div>
    </div>
  )
}

export default QrCode