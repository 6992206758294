import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import Table from '../../../components/sections/Table'

const RetailerProfile = () => {
    return (
        <div className='content-wrapper'>
            <Breadcrumbs title="Profile" path="/retailer-dashboard" />
            <div className='container'>
                <div className='card'>
                    <div className='card-header'>
                        <div className='card-title'>
                            My Profile
                        </div>
                    </div>
                    <div className='card-body'>
                        <Table tableHeading={['Full Name', 'Firm Name', 'UserName', 'Scode', 'Email Address', 'Pancard', 'Phone', 'Alt Phone']}>
                            <tr>
                                <td>Name</td>
                                <td>Name</td>
                                <td>UserName</td>
                                <td>111</td>
                                <td>name@gmail.com</td>
                                <td>DFGBH7890L</td>
                                <td>1234567890</td>
                                <td>9876543210</td>
                            </tr>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RetailerProfile