import React from 'react';
import Table from '../../../components/sections/Table';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import Card from '../../../components/sections/Card';
import CardComponent from '../../../components/sections/CardBody';

const Invoices = () => {
  return (
    <div className='content-wrapper'>
      <Breadcrumbs title='Invoices' path='/distributor-dashboard' />
      <div className='container'>
        <CardComponent className='py-4 mb-5'>
          <div className='card-body'>
            <h5 className='fw-bold text-primary text-center'>INVOICE LIST</h5>
            <ul className='font-mulish lh-lg text-center'>
              <li>GST under RCM(reverse charge mechanism) invoice will have commission earned under DMT, AEPS, MATM.</li>
              <li>GST under COMM(commission) invoice will have commission earned under Recharge, PAN, Token, IRCTC.</li>
              <li>GST under SALE invoice for Devices, Services sold </li>
            </ul>
          </div>
        </CardComponent>
        <Table
          tableHeading={['Invoice No.', 'Date of Invoice', 'Sale', 'CGST', 'SGST', 'IGST', 'Invoice Type', 'Print', 'Upload']}
          tbody='No data right now'
          colSpan='9'
        />
      </div>
    </div>
  )
}

export default Invoices;