import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
const CashDeposit = () => {
  return (
    <div className='content-wrapper'>
      <Breadcrumbs title='Cash Deposit' path='/retailer-dashboard' />
      <div className='container'>
         No UI
      </div>
    </div>
  )
}

export default CashDeposit