import { Formik, Form } from 'formik'
import React from 'react'
import FormikControl from '../../../components/FormikControl'
import { FaArrowRight } from "react-icons/fa";
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import Table from '../../../components/sections/Table';


const EarnedCommision = () => {
    return (
        <div className='content-wrapper'>
            <Breadcrumbs title='Commsion Earned' path='/retailer-dashboard' />
            <div className='container'>
                <div className='card'>
                    <div className='card-header'>
                        <div className="card-title">
                            Commision Earned
                        </div>
                    </div>
                    <div className='card-body'>
                        <Formik>
                            <Form>
                                <div className='row d-flex align-items-center'>
                                    <div className='col-6'>
                                        <FormikControl
                                            control="datepicker"
                                            placeholder="Select Date"
                                            name="date"
                                            label="Select Date"
                                        />
                                    </div>
                                    <div className='col-6 mt-3'>
                                        <button className='btn btn-dark rounded-pill'>
                                            <FaArrowRight />
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                        <div className='mt-5'>
                            <Table
                                tableHeading={['Amount', 'Discount On Commision', 'TDS', 'Net Commision']}
                            >
                                <tr>
                                    <td>1282</td>
                                    <td>0.00</td>
                                    <td>0.40</td>
                                    <td>7.60</td>
                                </tr>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EarnedCommision