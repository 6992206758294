import React from 'react'
import FormComponent from '../../../components/sections/statement/FormComponent';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import Table from '../../../components/sections/Table';
import { MdCloudDownload } from 'react-icons/md';
import Button from '../../../components/elements/Button';
import { datePckerValidaton } from '../Validation';
import { ToastContainer, toast } from 'react-toastify';


const TxnCommission = () => {

  const initialValues = {
    start_date: '', 
    end_date: '' 
  }

  const handleSubmitWallet = (value , {resetForm}) => { 
    toast.success('Commission Statement read successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,   
      progress: undefined,
      theme: "light",
    });
  resetForm();
  }

  const handleCancleSubmit = () => {
    toast.success('Date picker is clear', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,   
      progress: undefined,
      theme: "light",
    });
  } 

  return (
    <div className='content-wrapper'>
      <ToastContainer/>
      <Breadcrumbs title='TXN Wise Commission' path='/distributor-dashboard' />
      <div className='container'>
        <FormComponent
          title='TXN Wise Commission Details'
          nameOne='start_date'
          labelOne='Start Date'
          controlOne='datepicker'
          nameTwo='end_date'
          labelTwo='End Date'
          controlTwo='datepicker'
          initialValues = {initialValues}
          validationSchema={datePckerValidaton} 
          onSubmit={handleSubmitWallet}
          onClick={handleCancleSubmit}

        >
          <Table
            tableHeading={['TXNID', 'Credit DComm', 'Debit DComm', 'Date', 'Product', 'Narration', 'Status']}
            tableBody='No data right now'
            colSpan='7'
            className='mt-5 mb-3'
          />
          <Button className='btn-secondary rounded-pill' value={<>Download <MdCloudDownload className='mb-1'/></>} />
        </FormComponent>
      </div>
    </div>
  )
}

export default TxnCommission