import React, { useEffect } from "react";
import { Form, Formik } from 'formik';
import FormikControl from '../../../components/FormikControl';
import Table from '../../../components/sections/Table';
import CardComponent from '../../../components/sections/CardBody';
import {MdFileUpload} from 'react-icons/md';
import { moneyTransferDatePicker } from '../../../pages/retailer/validation';

const MoneyTransferReport = (props) => {
  
  const initialValues = {
    start_date: "",
    end_date: "",
    keyword: ""
  }

  const handleSubmit = () => {
    
    console.log('button clicked');
  }
  

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <div className="card-title">{props.title ? props.title : 'Money Transfer Report'}</div>
        </div>
        <div className="card-body">
          <Formik  
            initialValues={initialValues}
            validationSchema={moneyTransferDatePicker}            
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <FormikControl
                    control="datepicker"
                    name="start_date"
                    label="Start Date"
                  />
                </div>
                <div className="col-md-6">
                  <FormikControl
                    control="datepicker"
                    name="end_date"
                    label="End Date"
                  />
                </div>
                <div className="col-md-6">
                  <FormikControl
                    control="select"
                    name="search"
                    label="Search"
                    options={[
                      { key: "1", value: "Search by" },
                      { key: "2", value: "First Selector" },
                      { key: "3", value: "Second Selector" }
                    ]}
                  />
                </div>
                <div className="col-md-6">
                  <FormikControl
                    control="input"
                    name="keyword"
                    label="Keyword"
                    placeholder="Enter keyword"
                  />
                </div>
                <div className="col-md-6">
                  <FormikControl
                    control="select"
                    name="status"
                    label="Status"
                    options={[
                      { key: "1", value: "Transaction Status" },
                      { key: "2", value: "True" },
                      { key: "3", value: "False" }
                      
                    ]}
                  />
                </div>
              </div>
            </Form>
          </Formik>
          <div className="my-5">
            <CardComponent>
              <div className="px-md-5 pt-3 pb-2">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="bg-transparent">Total Amount</th>
                      <th className="bg-transparent">Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="bg-transparent">xx</td>
                      <td className="bg-transparent">xx</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CardComponent>
          </div>
          <Table
            tableHeading={[
              "Date",
              "TXNID",
              "Remitter",
              "Bank Name",
              "Account No",
              "IFSC",
              "BeneName",
              "UTR",
              "Type",
              "Amount",
              "Charges",
              "Wallet Type",
              "Status",
              "Action",
              "Remarks",
            ]}
            tableBody="No data right now"
            colSpan="15"
          />
          <button className="btn btn-dark my-3 rounded-pill">
            Export <MdFileUpload />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MoneyTransferReport;
