import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import FormikControl from "../../../components/FormikControl";
import { roles, userStatus } from '../../../helpers/constant'
import { ToastContainer, toast } from 'react-toastify';
import { validationSchema } from './Validation'
import 'react-toastify/dist/ReactToastify.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { addNewUser } from "../../../api/instance_method";
import List from "./List";

function Add(props) {
  const [show, setShow] = useState(false);
  const [confirmKyc, setConfirmKyc] = useState(false);

  const initialValues = {
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    address_one: "",
    address_second: "",
    mobile_number: "",
    pan: "",
    adhar: "",
    role: "",
    status: "",
  };

  const onSubmit = (values) => {
    localStorage.setItem('values', JSON.stringify(values))
    if (!confirmKyc) {
      setShow(true);
    }
  }
  const handleConfirm = async () => {
    const val = await JSON.parse(localStorage.getItem('values'));
    document.getElementById('confirm-user').disabled = true;
    addNewUser(val).then(
      (response) => {
        toast.success('Saved successfully');
        setShow(false)
        setTimeout(() => {
          props.toggleForm(false)
          localStorage.removeItem('values')
        }, 2000);

      },
      (error) => {
        toast.error('User cannot be added.')
        if (error.response.data.status === false) {
          toast.error('Please try again later')
        }
      }
    );

  }

  return (
    <>
      {
        props.showForm ? (
          <div className="content-wrapper">
            <ToastContainer />
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h4 className="pointer"
                      style={{ width: '10px' }}
                      onClick={() => props.toggleForm(false)}
                    >
                      <i className="bi bi-arrow-left-circle"></i>
                    </h4>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <Link to='/users'>Home</Link>
                      </li>
                      <li className="breadcrumb-item active">Add User</li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
            <section className="mb-5">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card ">
                      <div className="card-header">
                        <div className="card-title d-flex align-items-center">
                          <AddCircleIcon className="primary-color" />&nbsp;Add User Detail</div>
                      </div>
                      <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        validateOnBlur={false}
                        validateOnChange={false}
                      >
                        <Form>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-6">
                                <FormikControl
                                  type="text"
                                  control="input"
                                  name="first_name"
                                  label="First name"
                                  placeholder="First name"
                                />
                              </div>
                              <div className="col-md-6">
                                <FormikControl
                                  type="text"
                                  control="input"
                                  name="middle_name"
                                  label="Middle name"
                                  placeholder="Middle name"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <FormikControl
                                  type="text"
                                  control="input"
                                  id="last_name"
                                  name="last_name"
                                  label="Last name"
                                  placeholder="Last Name"
                                />
                              </div>

                              <div className="col-md-6">
                                <FormikControl
                                  type="email"
                                  control="input"
                                  id="email"
                                  name="email"
                                  label="Email"
                                  placeholder="Email"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <FormikControl
                                  type="text"
                                  control="input"
                                  name="mobile_number"
                                  label="Mobile number"
                                  placeholder="Mobile number"
                                />
                              </div>
                              <div className="col-md-6">

                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <FormikControl
                                  type="text"
                                  control="input"
                                  name="pan"
                                  label="Pan"
                                  placeholder="Pan"
                                />
                              </div>
                              <div className="col-md-6">
                                <FormikControl
                                  type="text"
                                  control="input"
                                  name="adhar"
                                  label="Adhar"
                                  placeholder="Adhar"
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <FormikControl
                                  type="text"
                                  control="input"
                                  name="address_one"
                                  label="Address one"
                                  placeholder="Address one"
                                />
                              </div>
                              <div className="col-md-6">
                                <FormikControl
                                  type="text"
                                  control="input"
                                  name="address_second"
                                  label="Address second"
                                  placeholder="Address second"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <FormikControl
                                  control="select"
                                  name="role"
                                  label="Role"
                                  options={roles}
                                />
                              </div>
                              <div className="col-md-6">
                                <FormikControl
                                  control="select"
                                  name="status"
                                  label="Status"
                                  options={userStatus}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 text-right">
                                <button
                                  id="add-user"
                                  className="btn primary_button px-3 rounded-pill"
                                >
                                  Save
                                </button>
                              </div>
                              <div className="col-6">

                                <button type="button"
                                  className="btn btn-danger px-3 rounded-pill"
                                  onClick={() => props.toggleForm(false)}
                                >
                                  Back
                                </button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {show && (
              <div className='text-center bg-light kyc-modal'>
                <p>Are you sure you want to add new user?</p>
                <h1 className='text-secondary'>
                  <i className="bi bi-check-circle"></i></h1><br />
                <button
                  id="confirm-user"
                  className='mx-3 btn btn-outline-success px-3 rounded-pill'
                  onClick={handleConfirm}>Yes</button>
                <button
                  className='mx-3 btn btn-outline-danger px-3 rounded-pill'
                  onClick={() => { setConfirmKyc(false); setShow(false) }}>Cancel</button>
              </div>
            )}
          </div>

        ) : (
          <List />
        )
      }

    </>
  );
}

export default Add;
