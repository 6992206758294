import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL

const instance  = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': "multipart/form-data",
        'Access-Control-Allow-Credentials': "true",
    }
})
instance.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem('token')
      if (token) {
        config.headers['Authorization'] = token ? `Bearer ${token}` : '';
      }
      return config;
    },
    (error)=> {
        Promise.reject(error)
    }

  );
export default instance;