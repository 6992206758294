import React from "react";
import CardComponent from "./CardBody";

const BulkOrderComponent = (props) => {
  return (
    <div>
      <CardComponent
        body={
          <>
            <h6 className="my-4 bg-secondary text-center py-2 rounded">
              {props.title}
            </h6>
            <table className="table text-center my-4 mx-auto table-bordered table-hover">
              <thead>
                <tr>
                  {props.tableHeading.map((item) => {
                    return <th key={item.index}>{item}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {props.tableData.map((item, id) => {
                  return (
                    <tr key={id}>
                      <td>{item.firstItem}</td>
                      <td>&#x20B9; {item.secondItem}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className="text-center my-4">
              <button className="btn mb-4 btn-secondary rounded-pill">
                {props.button}
              </button>
            </div>
          </>
        }
      />
    </div>
  );
};

export default BulkOrderComponent;