import React from 'react';
import { Link } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';

const Breadcrumbs = (props) => {
    return (
        <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item">
                                <Link to={props.path}>Home</Link>
                            </li>
                            <li className="breadcrumb-item active">{props.title}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Breadcrumbs