import './App.scss';
import React from 'react';
import { RouterProvider } from "react-router-dom"
import { router } from './routes/index.js';
const App = (props) => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
