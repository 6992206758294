import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Breadcrumbs from '../../../../components/sections/Breadcrumbs';
import { useNavigate } from 'react-router-dom';

const Confirm = () => {
  const navigate = useNavigate();
  const values = JSON.parse(localStorage.getItem('Form Values'));

  const handleNavigate = () => {
    navigate('/adharpay')
    localStorage.removeItem('Form values');
  }
  return (
    <div className='margin-header-table content-wrapper screen-height'>
      <Breadcrumbs title='Confirm' path='/retailer-dashboard' />
      <ToastContainer />
      <div className='container'>
        <div className='mx-5 px-5'>
          <table className='table table-hovered'>
            <tr>
              <th>Service</th>
              <td>{values && values.services}</td>
            </tr>
            <tr>
              <th>Mobile Number</th>
              <td>{values && values.mobile_number}</td>
            </tr>
            <tr>
              <th>Aadhar Number/ Virtual ID</th>
              <td>{values && values.aadhar_number}</td>
            </tr>
            <tr>
              <th>Amount</th>
              <td>{values && values.amount}</td>
            </tr>
            <tr>
              <th>Bank</th>
              <td>{values && values.bank_id}</td>
            </tr>
          </table>
        </div>
        <div className='text-center'>
          <button type="button" className="btn btn-outline-dark" onClick={handleNavigate}>Confirm</button>
        </div>
      </div>
    </div>
  )
}

export default Confirm