import React, { useState } from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import Nav from 'react-bootstrap/Nav';
import SendForm from './element/SendForm';
import { Formik, Form } from 'formik'
import { IoSend } from "react-icons/io5";
import FormikControl from '../../../components/FormikControl';

const SettleMainWallet = () => {
    const [element, setElement] = useState('cash_in_main_wallet');

    const setCashIn = () => {
        setElement('cash_in_main_wallet')
    }
    const setPg = () => {
        setElement('cash_in_pg')
    }
    return (
        <div className='content-wrapper'>
            <Breadcrumbs path="/retailer-dashboard" title="" />
            <div className="container">
                <div className='card'>
                    <Nav justify variant="tabs" defaultActiveKey="link-0">
                        <Nav.Item>
                            <Nav.Link
                                eventKey="link-0"
                                onClick={setCashIn}
                            >
                                Move CashIn To Main Wallet
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="link-1" onClick={setPg}>
                                Move PG Wallet To Main Wallet
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <div className='p-4'>
                        {element === 'cash_in_main_wallet' ? (
                            <SendForm />
                        ) : element === 'cash_in_pg' ? (
                            <Formik>
                                <Form>
                                    <div className="row  d-flex align-items-center">
                                        <div className="col-md-6">
                                            <FormikControl
                                                control="input"
                                                placeholder="Enter amount"
                                                label="Amount"
                                                name="amount"
                                            />
                                        </div>
                                        <div className="col-md-6 mt-md-3">
                                            <button className="btn btn-dark">
                                                Send <IoSend />
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </Formik>
                        ) :
                            ('')
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettleMainWallet