import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { editMatm, getMatmById } from '../../../api/instance_method';
import { toast } from 'react-toastify';
import FormikControl from '../../../components/FormikControl';
import { Formik, Form } from 'formik';

const Editmatm = (props) => {
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({
    amount: '',
    mobile_number: '',
    address: '',
    city: '',
    state: '',
    pincode: '',
  });
  useEffect(() => {
    getMatmById(props.userId).then(
      (response) => {
        setInitialValues({
          id: response.data.data.id,
          amount: response.data.data.amount,
          address: response.data.data.address,
          city: response.data.data.city,
          state: response.data.data.state,
          pincode: response.data.data.pincode,
          mobile_number: response.data.data.mobile_number,
        })
      },
      (error) => {
        toast.error('User data cannot be fetched')
      }
    )

  }, [props.userId, id]);

  const updateMatm = (val) => {
    editMatm( val, props.userId).then(
      (response) => {
        document.getElementById('update-matm').disabled = true;
        toast.success('Saved successfully');
        setTimeout(() => {
          props.closeModal()
        }, 2000);
  
      },
      (error) => {
        toast.error('Error. User cannot be updated')
      }
    );
  }
  return (
    <div className={`modal fade show ${props.isOpen ? 'show' : 'hide'}`}
      style={{ display: 'block' }}
    >
      <div className='modal-dialog  modal-dialog-scrollable modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header bg-light'>
            <h5 className='modal-title'>Edit Details</h5>
            <button type="button" className="close color-white" onClick={props.closeModal}>x</button>
          </div>
          <div className='modal-body'>
            <Formik
              initialValues={initialValues}
              onSubmit={updateMatm}
              // validationSchema={validationSchema}
              enableReinitialize
            >
              <Form>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <FormikControl
                        type="text"
                        control="input"
                        id="amount"
                        name="amount"
                        label="Amount"
                        placeholder="Amount"
                      />
                    </div>
                    <div className="col-md-6">
                      <FormikControl
                        type="text"
                        control="input"
                        id="mobile_number"
                        name="mobile_number"
                        label="Mobile Number"
                        placeholder="Mobile Number"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <FormikControl
                        type="text"
                        control="input"
                        id="address"
                        name="address"
                        label="Address"
                        placeholder="Address"
                      />
                    </div>

                    <div className="col-md-6">
                      <FormikControl
                        type="text"
                        control="input"
                        id="city"
                        name="city"
                        label="City"
                        placeholder="City"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <FormikControl
                        type="text"
                        control="input"
                        id="state"
                        name="state"
                        label="State"
                        placeholder="State"
                      />
                    </div>
                    <div className="col-md-6">
                      <FormikControl
                        type="text"
                        control="input"
                        id="pincode"
                        name="pincode"
                        label="Pincode"
                        placeholder="Pincode"
                      />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-md-6 text-center my-2">
                      <button
                        type="submit"
                        className="btn btn-outline-dark"
                        id='update-matm'
                      >Update</button>
                    </div>
                    <div className="col-md-6 text-center my-2">
                      <button
                        className="btn btn-dark"
                        onClick={props.closeModal} >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
        <div className='overlay'> </div>
      </div>
      <style jsx='true'>
        {`
        .modal {
          overflow-y: hidden;
        }
        `}
      </style>
    </div>
  )
}

export default Editmatm