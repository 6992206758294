import React from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { makeAeps } from '../../../api/instance_method';
import { useDispatch } from 'react-redux';
import { setMantraButton } from '../../../redux/slices/authSlice';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';

const Confirm = () => {
  const navigate = useNavigate();
  const values = JSON.parse(localStorage.getItem('values'));
  const updatedValues = JSON.parse(localStorage.getItem('updated'));
  const dispatch = useDispatch();

  const handleNavigate = () => {
    console.log('aeps')
    if (values) {
      console.log(values)
      makeAeps(values).then(
        (response) => {
          console.log(response)
          if (response.data.status === true) {
            toast.success('Amount transfered successfully');
            localStorage.removeItem('values');
            setTimeout(() => {
              navigate('/aeps');
            }, 2000);
            dispatch(setMantraButton(false))
          } else {
            toast.error('Transaction failed. Try again later');
            localStorage.removeItem('values');
            setTimeout(() => {
              navigate('/aeps');
            }, 2000);
            dispatch(setMantraButton(false))
          }
        }
      ).catch(
        (error) => {
          toast.error('Transaction cannot be made.');
          console.log(error);
          localStorage.removeItem('values');
          setTimeout(() => {
            navigate('/aeps');
          }, 2000);
          dispatch(setMantraButton(false))
        }
      )
    } else {
      makeAeps(updatedValues).then(
        (response) => {
          if (response.status === true) {
            toast.success('Amount transfered successfully');
            localStorage.removeItem('updated');
            setTimeout(() => {
              navigate('/aeps');
            }, 2000);
            dispatch(setMantraButton(false))
          } else {
            toast.error('Transaction failed. Try again later');
            localStorage.removeItem('updated');
            setTimeout(() => {
              navigate('/aeps');
            }, 2000);
            dispatch(setMantraButton(false))
          }
        }
      ).catch(
        (error) => {
          toast.error('Transaction cannot be made.');
          console.log(error);
          localStorage.removeItem('updated');
          setTimeout(() => {
            navigate('/aeps');
          }, 2000);
          dispatch(setMantraButton(false))
        }
      )
    }
  }

  return (
    <div className='margin-header-table content-wrapper screen-height'>
        <Breadcrumbs title='Confirm' path='/retailer-dashboard' />
        <ToastContainer />
      <div className='container'>
        <div className='mx-5 px-5'>
          <table className='table table-hovered'>
            <tr>
              <th>Service</th>
              <td>Cash Withdrawl</td>
            </tr>
            <tr>
              <th>Mobile Number</th>
              <td>{values ? values.customer_mobile_number : updatedValues.customer_mobile_number}</td>
            </tr>
            <tr>
              <th>Aadhar Number/ Virtual ID</th>
              <td>{values ? values.customer_adhar_number : updatedValues.customer_adhar_number}</td>
            </tr>
            <tr>
              <th>Amount</th>
              <td>{values ? values.amount : updatedValues.amount}</td>
            </tr>
            <tr>
              <th>Bank</th>
              <td>{values ? values.bank_id : updatedValues.bank_id}</td>
            </tr>
          </table>
        </div>
        <div className='text-center'>
          <button type="button" className="btn btn-outline-dark" onClick={handleNavigate}>Confirm</button>
        </div>
      </div>
    </div>
  )
}

export default Confirm