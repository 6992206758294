import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { changeUserStatus, getUserCall } from '../../../api/instance_method';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditUser from '../../../components/user/EditUser';
import FormikControl from '../../../FormikControl';
import { Form, Formik } from 'formik';
import { userStatus } from '../../../helpers/constant';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setRole } from '../../../redux/slices/authSlice';

const UserDetails = (props) => {
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [error, setError] = useState('')
  const [isOpen, setIsOpen] = useState(false);
  const [usersStatus, setUserStatus] = useState('');

  const dispatch = useDispatch();
  const initialValues = {
    id: id,
    status: ''
  }
  const handleStatus = (val) => {
    changeUserStatus(val, id).then(
      (response) => {
        toast.success('Status Changed Successfully')
        setUserStatus(val)
      }
    )
  }
  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await getUserCall(id);
        const userData = response.data;
        console.log(response)
        setUser(userData.data);
        dispatch(setRole('admin'));
      } catch (error) {
        setError('user not found')
      }
    };

    fetchData();

  }, [isOpen, usersStatus, dispatch, id]);

  const openModal = (id) => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };



  return (
    <div className='content-wrapper screen-height'>
      <ToastContainer />
      {isOpen && (
        <>
          <div className='overlay'></div>
          <EditUser closeModal={closeModal} user={user} />
        </>
      )}
      {
        user ? (
          <div className='py-4 container-fluid'>
            <div>
              <div className='text-center'>
                <div className=''>
                  <div className=''>
                    <table className="table table-hover table-bordered mb-5 caption-top">
                      <caption className='caption-top'>
                        <div className='d-flex justify-content-between'>
                          <h4><AccountCircleIcon />&nbsp;{user.first_name}&nbsp; </h4>
                          <div className='d-flex'>
                            <span className='btn btn-success'>{user.status}</span>&nbsp;&nbsp;
                            <button className='btn-outline-dark btn' onClick={() => openModal(user)}>
                              Edit
                            </button>&nbsp;&nbsp;
                            <div>
                            </div>
                          </div>
                        </div>
                      </caption>

                      <tbody>
                        <tr>
                          <th scope="row">First Name</th>
                          <td>{user.first_name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Middle Name</th>
                          <td>{user.middle_name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Last Name</th>
                          <td>{user.last_name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Email</th>
                          <td>{user.email}</td>
                        </tr>
                        <tr>
                          <th scope="row">Mobile Number</th>
                          <td>{user.mobile_number}</td>
                        </tr>
                        <tr>
                          <th scope="row">Address One</th>
                          <td>{user.address_one}</td>
                        </tr>
                        <tr>
                          <th scope="row">Address two</th>
                          <td>{user.address_second}</td>
                        </tr>
                        <tr>
                          <th scope="row">Pan Number</th>
                          <td>{user.pan}</td>
                        </tr>
                        <tr>
                          <th scope="row">Adhar Number</th>
                          <td>{user.adhar}</td>
                        </tr>
                        <tr>
                          <th scope="row">Added By</th>
                          <td>{user.added_by}</td>
                        </tr>

                      </tbody>
                    </table>

                  </div>
                  <div className=''>
                    <Formik initialValues={initialValues} onSubmit={handleStatus}>
                      <Form>
                        <div className='row d-flex'>
                          <div className='col-md-3'>
                            <FormikControl
                              control='select'
                              options={userStatus}
                              name='status'
                            />
                          </div>
                          <div className='col-md-1 my-3 py-2'>
                            <button type='submit' className='btn btn-dark'>save</button>
                          </div>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>

            </div>
          </div>
        ) : (
          <p>{error}</p>
        )
      }
      <style jsx='true'>
        {`
  .overlay {
    opacity: 0.5;
    background-color: #000;
    height: 100vh;
    z-index: 1050;
    position: relative;
  }
  .margin-header-table{
    position: absolute;
    top: 0;
    width: 81%;
  }
  `
        }
      </style>
    </div>
  )
}

export default UserDetails