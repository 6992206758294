
import React from 'react';
import RetailerHeader from './RetailerHeader';
import Breadcrumbs from '../../components/sections/Breadcrumbs';
import Gst from '../../components/sections/Gst';

const GstNumber = () => {
 
  return (
    <div className='content-wrapper'>
      <RetailerHeader />
      <Breadcrumbs path='/retailer-dashboard' title='GST Number' />
      <Gst />

    </div>
  )
}

export default GstNumber