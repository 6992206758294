import React, { useState } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import { RiDeleteBin4Line } from 'react-icons/ri';
import { AiOutlineEdit } from 'react-icons/ai';
import { TbLockCog } from 'react-icons/tb';
import { BsPiggyBank } from 'react-icons/bs';
import EditStatus from './EditStatus';

const UserRow = ({ user, onRemove, onEdit, onOpenModal, onAddBank }) => {
  const [status, setStatus] = useState('');
  const walletType1 = user.wallet.find((item) => item.type === 1);
  const walletType2 = user.wallet.find((item) => item.type === 2);
  return (
    <tr className="trodd" key={user.id} id={`tr-${user.id}`}>
      <td className="align-middle text-center dtr-control sorting_1" tabIndex="0">
        {user.first_name}
      </td>
      <td className="align-middle text-center">{user.email}</td>
      <td className="align-middle text-center">{user.mobile_number}</td>
      <td className="align-middle text-center">{user.pan}</td>
      <td className="align-middle text-center">{user.adhar}</td>
      <td className="align-middle text-center">{(user.role === 1) ? 'Distributor' : (user.role === 2) ? 'Retailer' : 'Customer'}</td>
      <td className="align-middle text-center">{user.member_ship_plan.name}</td>

      <td className="align-middle text-center">{walletType1 ? walletType1.amount : '-'}</td>
      <td className="align-middle text-center">{walletType2 ? walletType2.amount : '-'}</td>
      <td className="align-middle text-center">
        <EditStatus
          userId={user.id}
          status={user.status}
          onStatusChange={setStatus}
        />
      </td>
      <td className="align-middle text-center">{new Date(user.created_at).toLocaleDateString()} </td>
      <td className="align-middle text-center">
        <div className='dropdown m-0'>
          <p
            className="text-center"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown" aria-expanded="false"
          >
            <BsThreeDots />
          </p>
          <ul
            className="dropdown-menu"
            aria-labelledby="dropdownMenuButton1"
          >
            <li className="dropdown-item">
              <button
                className='background-transparent text-black mx-1 no-border'
                title="Remove"
                onClick={() => onRemove(user.id)}
              >
                <RiDeleteBin4Line /> &nbsp; Delete
              </button></li>
            <li className="dropdown-item">
              <button
                className='background-transparent text-black mx-1 no-border'
                title="Change Password"
                onClick={() => onEdit(user.id)}
              >
                <AiOutlineEdit /> &nbsp; Edit
              </button>
            </li>
            <li className="dropdown-item">
              <button
                className='background-transparent text-black mx-1 no-border'
                title="Change Password"
                onClick={() => onOpenModal(user.id)}
              >
                <TbLockCog /> &nbsp; Change Password
              </button>
            </li>
            <li className="dropdown-item">
              <button
                className='background-transparent text-black mx-1 no-border'
                title="Bank"
                onClick={() => onAddBank(user.id)}
              >
                <BsPiggyBank /> &nbsp;
                {user.bank_detail === null ? 'Add Bank' : 'Edit Bank'}
              </button>
            </li>
          </ul>
        </div>

      </td>
    </tr>
  )
}

export default UserRow