import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import { CardHeader } from '../../../components/sections/CardHeader'
import { Formik, Form } from 'formik'
import FormikControl from '../../../components/FormikControl'
import Table from '../../../components/sections/Table'
import { ToastContainer, toast } from 'react-toastify';
import { AddBankvalidationSchema } from '../validation'

const SettleBank = () => {
  const initialValues = {
    bank_name : "" ,
    account_name : "" ,
    account_number : "" ,
    confirm_account_number : "" ,
    ifsc_code : "" ,
  }

  const handleSubmit = (value, { resetForm }) => {
    toast.success('Data read successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    console.log('Add bank clicked');
    resetForm()
  }
    return (
        <div className='content-wrapper'>
        <ToastContainer/>
            <Breadcrumbs path="/retailer-dashboard" title="Bank" />
            <div className="container">
                <div className='card'>
                    <CardHeader title="Bank" />
                    <div className='card-body'>
                        <div className="bg-light p-4">
                            <Formik 
                              initialValues={initialValues}
                              validationSchema={AddBankvalidationSchema}
                              validateOnBlur={false}
                              validateOnChange={false}
                              onSubmit={handleSubmit}
                            >
                                <Form>
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <FormikControl
                                                control="input"
                                                placeholder="Enter Bank Name"
                                                label="Bank Name"
                                                name="bank_name"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <FormikControl
                                                control="input"
                                                placeholder="Enter name on account"
                                                label="Name"
                                                name="account_name"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <FormikControl
                                                control="input"
                                                placeholder="Enter account number"
                                                label="Account Number"
                                                name="account_number"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <FormikControl
                                                control="input"
                                                placeholder="Re-Enter account number"
                                                label="Confirm Account Number"
                                                name="confirm_account_number"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <FormikControl
                                                control="input"
                                                placeholder="Enter IFSC code"
                                                label="IFSC Code"
                                                name="ifsc_code"
                                            />
                                        </div>
                                    </div>
                                    <button className='btn btn-secondary rounded-pill' type='submit'>
                                        Add Account
                                    </button>
                                </Form>
                            </Formik>
                        </div>
                        <div className='my-4'>
                        <Table
                            tableHeading={['Bank Name', 'Name on Account', 'Account No', 'IFSC Code', 'IMPS Status']}
                        >
                            <tr>
                                <td>Axis Bank</td>
                                <td>Name</td>
                                <td>123456789025</td>
                                <td>POIU1234567</td>
                                <td>UP</td>
                            </tr>
                        </Table>
                        </div>
                        <Formik>
                            <Form>
                                <div className='w-50'>
                                    <FormikControl
                                        control="input"
                                        placeholder="Enter amount"
                                        label="Amount"
                                        name="amount"
                                    />
                                    </div>
                                    <FormikControl
                                        control='radio'
                                        name="account_type"
                                        label="Card Type"
                                        options={[
                                            { key: 1, value: "IMPS" },
                                            { key: 2, value: "NEFT" },
                                        ]}
                                    />
                                    <button className='btn btn-secondary rounded-pill'>
                                        Confirm Settlement
                                    </button>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettleBank