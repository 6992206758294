import React from 'react';
import { Form, Formik } from 'formik';
import {BsFillSendFill} from 'react-icons/bs';
import { toast, ToastContainer } from 'react-toastify';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import Card from '../../../components/sections/Card';
import FormikControl from '../../../components/FormikControl';
import { axisAccountSchema } from '../validation';

const AxisbankAccount = () => {
  const initialValues = {
    account_type: '',
    name: '',
    mobile_number: ''
  }

  const handleSubmit = (val) => {
    if(val) {
      toast.success('Axis bank account opening request made successfuly')
    } else {
      toast.error('Error making request')
    }
  }

  return (
    <div className='content-wrapper'>
      <ToastContainer />
      <Breadcrumbs title='Axis bank Account' path='/retailer-dashboard' />
      <div className='container'>
        <Card title='Axis Bank A/C Opening Referral Link'>
          <Formik 
            onSubmit={handleSubmit}
            validationSchema={axisAccountSchema}
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={initialValues}
          >
            <Form>
              <div className='row'>
                <div className='col-md-6'>
                    <FormikControl
                      control='select'
                      name="account_type"
                      label="Account Type"
                      options={[
                        { key: '--Select Service--', value: "--Select Service--" },
                        { key: 1, value: "Saving Account" },
                        { key: 2, value: "Current Account" },
                      ]}
                    />
                </div>
                <div className='col-md-6'>
                    <FormikControl
                      control='input'
                      name="name"
                      placeholder='Enter Name'
                      label="Name"
                    />
                </div>
                <div className='col-md-6'>
                    <FormikControl
                      control='input'
                      name="mobile_number"
                      placeholder='Enter mobile number'
                      label="Mobile Number"
                    />
                </div>
              </div>
              <div className='text-center mt-3'>
                <button className='btn btn-secondary rounded-pill px-3' type="submit">
                  Submit <BsFillSendFill />
                </button>
              </div>
            </Form>
          </Formik>
        </Card>
      </div>
    </div>
  )
}

export default AxisbankAccount