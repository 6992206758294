import React from 'react';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import Card from '../../../components/sections/Card';
import Table from '../../../components/sections/Table';
import Button from '../../../components/elements/Button';

const BalanceCert = () => {
  return (
    <div className='content-wrapper'>
      <Breadcrumbs title='Balance Certificate' path='/ditributor-dashboard' />
      <div className='container'>
        <Card title='Balance Certificate'>
          <div className='d-flex justify-content-between mb-5 mt-3'>
            <Button className='btn-dark rounded-pill' value='Download Balance Certificate English' type='button' />
            <Button className='btn-dark rounded-pill' value='Download Balance Certificate Hindi' type='button' />
            <Button className='btn-dark rounded-pill' value='Upload Balance Certificate' type='button' />
          </div>
          <Table
            tableHeading={['Certificate', 'Status', 'Date Uploaded', 'Date Modified', 'Remarks']}
            tableBody='No data right now'
          />
        </Card>
      </div>
    </div>
  )
}

export default BalanceCert