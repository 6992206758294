export const reportData = [
    {
        heading: 'DMT',
        subHeading: '1280',
        bgColor: 'bg-primary'
    },
    {
        heading: 'AEPS',
        subHeading: '500.00',
        bgColor: 'bg-danger'
    },
    {
        heading: 'Mini Statement',
        subHeading: '0.00',
        bgColor: 'bg-teal'
    },
    {
        heading: 'QR Settlement Main Wallet',
        subHeading: '5950.00',
        bgColor: 'bg-secondary'
    },
    {
        heading: 'Platform Charges On last TXN',
        subHeading: '0.00',
        bgColor: 'bg-pink'
    },
]