import React from 'react';
import Breadcrumbs from '../../../../components/sections/Breadcrumbs';
import BulkOrderComponent from '../../../../components/sections/BulkOrderComponent';

const AepsPlus = () => {
  return (
    <div className='content-wrapper'>
      <Breadcrumbs title='Axis Bank' path='/distributor-dashboard' />
      <div className='container'>
        <BulkOrderComponent
          title='Hello Partner, AEPS Plus Are Available Now At below Slabs. Click Activate To Avail Services.'
          tableHeading={['SLAB', 'INR (Incl. Tax)']}
          tableData={
            [{ firstItem: '1 To 1', secondItem: '300 /- (inclusive of GST)' },
            { firstItem: '2 To 10', secondItem: '200 /- (inclusive of GST)' },
            { firstItem: '11 To 25', secondItem: '175 /- (inclusive of GST)' },
            { firstItem: '26 To 50', secondItem: '150 /- (inclusive of GST)' }]
          }
          button='Activate AEPS Plus'
        />
      </div>
    </div>
  )
}

export default AepsPlus