import React, { useEffect, useState } from 'react';
import DistributorHeader from '../DistributorHeader'
import { setRole } from '../../../redux/slices/authSlice';
import { getRetailer } from '../../../api/instance_method';
import { useDispatch } from 'react-redux';
import Loader from '../../../components/Loader';
import Card from '../../../components/sections/ScrollingCardBody';
import money from '../../../assets/images/mainBanner.png';
import styles from './DistributorDashboard.module.scss'

const DistributorDashboard = () => {
  const token = sessionStorage.getItem('token');
  const dispatch = useDispatch();
  const [totalItemsCount, setTotalItemsCount] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (token) {
      getRetailer(1).then(
        (response) => {
          setTotalItemsCount(response.data.data.total)
          dispatch(setRole('distributor'))
        },
      );
      setTimeout(() => {
        setIsLoading(false);
      }, 1500)
    }
  }, []);
  return (
    <div className='content-wrapper' style={{ position: 'relative' }}>
      <DistributorHeader />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className='row my-3 text-center container-fluid'>
            <div className='col-md-8'>
              <img src={money} alt='image' className={`${styles.image} img-fluid`} />
            </div>
            <div className='col-md-4'>
              <Card title='Latest Updates' content=' With instant payment services like AEPS and DMT you can also earn money through profitable commissions. Stay connected for more updates.' />
            </div>
          </div>
         
        </>
      )}



    </div>
  )
}

export default DistributorDashboard