import React from 'react';
import Card from '../../../components/sections/Card';
import Table from '../../../components/sections/Table';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import CardComponent from '../../../components/sections/CardBody';

const Profile = () => {
  return (
    <div className='content-wrapper' >
    <Breadcrumbs title='Profile' path='/ditributor-dashboard' />
    <div className='container-fluid'>
      <Card
        title='Company Bank Accounts'
        body={<Table
          tableHeading={['Full Name', 'Firm Name', 'UserName', 'SCode', 'Email Address', 'Pancard', 'Phone', 'Alt Phone']}
          tableBody='No data right now'
        />}
      />
      <div className='row'>
        <div className='col-md-4'>
          <CardComponent
            title='All Company Accounts'
            className='bg-light lh-lg px-3 py-3 px-md-4 py-md-4'
            body={
              <table>
                <tr >
                  <th>Name  </th>
                  <td className='px-md-5 px-4'>Money Transfer Portal</td>
                </tr>
                <tr>
                  <th>PAN Card  </th>
                  <td className='px-md-5 px-4'>ABCDE1234F</td>
                </tr>
                <tr>
                  <th>GST No.  </th>
                  <td className='px-md-5 px-4'>12ABCDE345F6G7</td>
                </tr>
              </table>
            }
          />
        </div>
        <div className='col-md-8'>
          <Table 
          tableHeading={['Bank Name', 'Branch', 'IFSC', 'Account Number', 'Type', 'Cash/CDM Deposit Charges', 'Remark']}
           tableBody='No data right now'
           colSpan='7'
            />
        </div>
      </div>
    </div>
  </div >
  )
}

export default Profile