import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Form, Formik } from 'formik'
import FormikControl from '../../../components/FormikControl';
import { addTopFund, approvedRetailers } from '../../../api/instance_method'
import { fundSchema } from '../Validation';
import { setRole } from '../../../redux/slices/authSlice';
import DistributorHeader from '../DistributorHeader'
import Breadcrumbs from '../../../components/sections/Breadcrumbs';


const AddFund = () => {
  const initialValues = {
    amount: '',
    to_user_id: '...',
  };

  const [users, setUsers] = useState([]);
  const [amount, setAmount] = useState('');
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem('token')

  useEffect(() => {
    if (token) {
      approvedRetailers().then(
        (response) => {
          const retailers = response.data.data;
          const allUsers = [
            { key: '', value: '--Select Retailer--' },
            ...retailers.map((element, id) => ({
              key: element.id,
              value: element.first_name
            }))
          ]
          setUsers(allUsers);
          dispatch(setRole('distributor'))
        },
      );
    }
  }, []);


  const handleSubmit = (value) => {
    addTopFund(value).then(
      (response) => {
        document.getElementById('add-fund').disabled = true;
        toast.success('Amount added successfully');
        setTimeout(() => {
          navigate('/distributor-dashboard')
        }, 4000)
      },
      (error) => {
        console.log('error', error)
        toast.error('Fund cannot be transfered')
      }

    )
  }
  return (
    <div className='content-wrapper screen-height'>
      <ToastContainer />
      <DistributorHeader />
      <Breadcrumbs title='Add Fund' path='/distributor-dashboard' />


      <div className='container d-flex justify-content-center'>
        <div className='card'>
          <div className='card-header pb-0'>
            <p className='font-weight-bold'>
              Add Fund Retailor
            </p>
          </div>
          <div className='card-body'>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={fundSchema}
              validateOnBlur={false}
              validateOnChange={false}
            >
              <Form>
                <div className=''>
                  <FormikControl
                    label="Select Retailer"
                    name="to_user_id"
                    control="select"
                    options={users}
                  />
                </div>
                <div className=''>
                  <FormikControl
                    label="Amount"
                    type="text"
                    control="input"
                    id="amount"
                    name='amount'
                    placeholder='Amount'
                  />
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className='btn btn-secondary rounded-pill'
                    id='add-fund'
                  >
                    Submit&nbsp;
                    <KeyboardDoubleArrowRightIcon className='my-auto' />
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddFund;