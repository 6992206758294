import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik';
import { BsFillEyeFill } from "react-icons/bs";
import { toast, ToastContainer } from 'react-toastify';
import { getRetailerByToken } from '../../../api/instance_method';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import { CardHeader } from '../../../components/sections/CardHeader';
import FormikControl from '../../../components/FormikControl';
import { dcPostpaidSchema } from '../validation';
import RetailerHeader from '../RetailerHeader';

const DatacardPostpaid = () => {
  const [wallet, setWallet] = useState('')
  const initialValues = {
    operator: '',
    mobile_number: '',
    net_amount: '',
    wallet: ''
  }
  useEffect(() => {
    getRetailerByToken().then(
      (response) => {
        setWallet(response.data.user.wallet)
      }
    )
  }, []);
  const handleSubmit = (val) => {
    if(val) {
      toast.success('Bill payed successfuly')
    }
  }
  return (
    <div className='content-wrapper'>
      <RetailerHeader />
      <ToastContainer />
      <Breadcrumbs title='Datacard Postpaid' path='/retailer-dashboard' />
      <div className='container'>
        <div className='card'>
          <CardHeader title="Datacard Postpaid" />
          <div className='card-body'>
            <Formik
              initialValues={initialValues}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={dcPostpaidSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className='row'>
                  <div className='col-md-6'>
                    <FormikControl
                      control="input"
                      placeholder="Enter operator"
                      label="Operator"
                      name="operator"
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormikControl
                      control="input"
                      placeholder="Enter mobile number"
                      label="Mobile Number"
                      name="mobile_number"
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormikControl
                      control="input"
                      placeholder="Net bill amount"
                      label="Net Bill Amount"
                      name="net_amount"
                    />
                  </div>
                  <div className='col-md-6'>
                    <label className='font-mulish'>Select Wallet</label><br />
                    <FormikControl
                      control="radio"
                      name="wallet"
                      options={[
                        {key: '1', value: `Main Wallet`},
                        {key: '2', value: `Cash-In Wallet`}
                      ]}
                    />
                  </div>
                </div>
                <div className='text-center'>
                  <button type='submit' className='btn btn-secondary my-3 px-3 rounded-pill'>
                    Pay Bill <BsFillEyeFill />
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DatacardPostpaid;