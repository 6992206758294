import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import Card from '../../../components/sections/Card'
import { Formik, Form } from 'formik'
import { MdCloudDownload } from 'react-icons/md'
import FormikControl from '../../../components/FormikControl'
import Table from '../../../components/sections/Table'
import Button from '../../../components/elements/Button'
import { datePckerValidaton } from '../Validation';
import { ToastContainer, toast } from 'react-toastify';

const Commission = () => {
  const initialValues = {
    start_date: '', 
    end_date: '' 
  }

  const handleSubmitWallet = (value , {resetForm}) => {    
      toast.success('Commission Statement read successfully', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,   
        progress: undefined,
        theme: "light",
      });
    resetForm();
  } 

  const handleCancleSubmit = () => {
      toast.success('Date picker clear', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,   
        progress: undefined,
        theme: "light",
    });
  }

  return (
    <div className='content-wrapper'>
      <ToastContainer/>
      <Breadcrumbs title='Advance Limit' path='/distributor-dashboard' />
      <div className='container'>
        <Card title='Commission Earned' body={
          <>
            <div className='container'>
              <Formik
                initialValues = {initialValues}
                validationSchema={datePckerValidaton} 
                onSubmit={handleSubmitWallet}
                validateOnBlur={false}
                validateOnChange={false}
                onClick={handleCancleSubmit}
              >
                <Form>
                  <div className='row'>
                    <div className='col-md-6'>
                      <FormikControl
                        name='start_date'
                        label='Start Date'
                        control='datepicker'
                      />
                    </div>
                    <div className='col-md-6'>
                      <FormikControl
                        name='end_date'
                        label='End Date'
                        control='datepicker'
                      />
                    </div>
                  </div>
                  <div className='d-flex justify-content-center'>
                    <Button className='btn-secondary rounded-pill px-3 m-3' type='submit' value='Submit' />
                    <Button className='btn-danger rounded-pill px-3 m-3' type="reset" value='Cancel' />
                  </div>
                </Form>
              </Formik>
            </div>
            <Table
              tableHeading={['TXNID', 'Date', 'Opening', 'Commission', 'TDS', 'Closing', 'Narration', 'Type', 'TXN Type']}
              tableBody='No data right now'
              colSpan='9'
              className='mt-5 mb-3'
            />
            <Button className='btn-secondary rounded-pill' value={<div>Download <MdCloudDownload className='mb-1' /></div>} />
          </>
        }
        />
      </div>
    </div>
  )
}

export default Commission