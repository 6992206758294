import React from 'react'

const Button = (props) => {
  return (
    <div>
        <button className={`btn ${props.className}`} type={props.type} onClick={props.onClick}>{props.value}</button>
    </div>
  )
}

export default Button