import React from 'react';
import contact from '../assets/images/contact.svg';

const Contact = () => {
  return (
    <>
    <div className='content-wrapper'>
      <div className="content pt-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <h3 className="heading mb-4">Send us a message</h3>
                  <p>
                    If you have any types of quries, you can send message from here. It's our pleasure to help you.
                  </p>
                  <p>
                    <img src={contact} alt="contact" className="img-fluid" />
                  </p>
                </div>
                <div className="col-md-6">
                  <form className="mb-5" method="post" id="contactForm" name="contactForm" noValidate="novalidate">
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <input type="text" className="form-control" name="name" id="name" placeholder="Your name" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <input type="text" className="form-control" name="email" id="email" placeholder="Email" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <textarea className="form-control" name="message" id="message" cols="30" rows="7" placeholder="Write your message"></textarea>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <input type="submit" value="Send Message" className="btn btn-dark  py-2 px-4" />
                        <span className="submitting"></span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.leftSide}>
            <div className={`${classes.address} ${classes.details}`}>
              <i className="fas fa-map-marker-alt"></i>
              <div className={classes.topic}>Address</div>
              <div className={classes.textOne}>D 229, Sector 74</div>
              <div className={classes.textTwo}>Sahibzada Ajit Singh Nagar, Punjab</div>
            </div>
            <div className={`${classes.phone} ${classes.details}`}>
              <i className="fas fa-phone-alt"></i>
              <div className={classes.topic}>Phone</div>
              <div className={classes.textOne}>+91 9465532412</div>
                 </div>
            <div className={`${classes.email} ${classes.details}`}>
              <i className="fas fa-envelope"></i>
              <div className={classes.topic}>Email</div>
              <div className={classes.textOne}>hr@webinfinitesolutions.com</div>
             </div>
          </div>
          <div className={classes.rightSide}>
            <div className={classes.topicText}>Send us a message</div>
            <p>If you have any types of quries, you can send message from here. It's our pleasure to help you.</p>
            <form action="#">
              <div className={classes.inputBox}>
                <input type="text" placeholder="Enter your name" />
              </div>
              <div className={classes.inputBox}>
                <input type="text" placeholder="Enter your email" />
              </div>
              <div className={`${classes.inputBox} ${classes.messageBox}`}>
              <textarea id="subject" name="subject" 
              placeholder="Enter your message"></textarea>
          </div>
          <div className={classes.button}>
            <input type="button" value="Send Now" />
          </div>
        </form>
      </div>
    </div>
      </div > */}
    </div >
    </>
  )
}

export default Contact