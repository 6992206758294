import React from 'react'
import { CardHeader } from '../../../components/sections/CardHeader';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import { Form, Formik } from 'formik';
import FormikControl from '../../../components/FormikControl';
import { reportData } from '../../../data/retailer/buisnessReport';

const Buisnessreport = () => {
  return (
    <div className='content-wrapper'>
    <Breadcrumbs title='Buisness Report' path='retailer-dashboard' />
    <div className='container'>
        <div className='card'>
            <CardHeader title="Buisness Report" />
            <div className='card-body'>
            <Formik>
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <FormikControl
                    control="datepicker"
                    name="start_date"
                    label="Start Date"
                  />
                </div>
                <div className="col-md-6">
                  <FormikControl
                    control="datepicker"
                    name="end_date"
                    label="End Date"
                  />
                </div>
                <div className='col-12 text-center'>
                    <button className='btn btn-dark rounded-pill'>Submit</button>
                </div>
              </div>
            </Form>
          </Formik>
            </div>
        </div>
        <div className='row'>
            {reportData.map((data,id) => (
                <div className='col-md-4'>
                    <div className={`card text-center ${data.bgColor}`}>
                        <div className="card-body">
                            <h4 className='font-oswald'>{data.heading}</h4>
                            <h5 className='font-mulish'>{data.subHeading}</h5>
                        </div>
                        </div>
                </div>
            ))}
        </div>
    </div>
  </div>
  )
}

export default Buisnessreport