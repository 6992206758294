import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Form, Formik, } from 'formik';
import FormikControl from '../../../components/FormikControl';
import { matmSchema } from '../../distributors/Validation';

const RetailerMatm = () => {
  const initialValues = {
    amount: '',
    mobile_number: '',
    address: '',
    city: '',
    state: '',
    pincode: '',
  }
  const handleSubmit = (value, { resetForm }) => {
    toast.success('Data read successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    resetForm()
  }
  return (
    <div className=' content-wrapper'>
      <section>
        <ToastContainer/>
        <section className="content-header">
          <div className="">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to='/retailer-dashboard'>Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Order MATM</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
      </section>
      <div className='container'>
        <div className='card'>
          <div className='card-header pb-0 pt-3'>
            <p className='font-weight-bold'>PURCHASE M-ATM ORDER</p>
          </div>
          <div className='card-body'>
            <Formik
              initialValues={initialValues}
              validationSchema={matmSchema}
              onSubmit={handleSubmit}
            >
              <Form>

                <div className='row'>
                  <div className='col-md-6'>
                    <FormikControl
                      control="input"
                      name="amount"
                      label="Amount :"
                      placeholder="Enter Amount"
                    />
                    <p className='text-primary font-weight-bold' style={{ marginTop: '-10px' }}>
                      Total price &#x20B9; 10000 only</p>
                  </div>
                  <div className='col-md-6'>
                    <FormikControl
                      control="input"
                      name="mobile_number"
                      label="Mobile Number :"
                      placeholder="Enter Mobile Number"
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <FormikControl
                      control="input"
                      name="address"
                      label="Address :"
                      placeholder="Enter Address"
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormikControl
                      control="input"
                      name="city"
                      label="City :"
                      placeholder="Enter City"
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <FormikControl
                      control="input"
                      name="state"
                      label="State :"
                      placeholder="Enter State"
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormikControl
                      control="input"
                      name="pincode"
                      label="Pincode :"
                      placeholder="Enter Pincode"
                    />
                  </div>
                </div>
                <div className='row text-center'>
                  <div className='col'>
                    <button type='submit' className='btn btn-outline-dark' typeof='submit'>Submit Order</button>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
        <div className=''>
          <div className=''>
            <table className="table  table-bordered">
              <thead>
                <tr>
                  <th scope="col">Per Machine Cost</th>
                  <th scope="col">Minimum Order</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2000</td>
                  <td>5</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RetailerMatm;