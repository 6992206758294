import { useSelector } from 'react-redux';

const Footer = (props) => {
  const token = useSelector(state => state.auth.user);
  const role = useSelector(state => state.auth.role);

  if (!token || !(role === 'admin' || role === 'distributor' || role === 'retailer')) {
    return null;
  }

  return (
    <footer className="main-footer fixed-bottom">

      <strong>
        Copyright &copy; 2023&nbsp;
        <a href="https://www.webinfinitesolutions.com/" rel="noreferrer" target='_blank' style={{ textDecoration: 'none' }}>
          Web Infinite Solutions Pvt. Ltd.
        </a>
      </strong> All rights reserved.
    </footer>
  );
};

export default Footer;
