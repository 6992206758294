import React, { useState, useEffect } from 'react';
import Pagination from "react-js-pagination";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getBanksByAdmin } from '../../../api/instance_method';
import Loader from '../../../components/Loader';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';


function List(props) {
  const [users, setUsers] = useState([]);
  const [activepage, setActivePage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const token = sessionStorage.getItem('token')
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (token) {
      getBanksByAdmin(1).then(
        (response) => {
          setUsers(
            response.data.data.data
          );
          setTotalItemsCount(response.data.data.total)
        },
      )
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 2000);
  }, []);

  let handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    getBanksByAdmin(pageNumber).then(
      (response) => {
        setTotalItemsCount(
          response.data.data.total
        );
        setUsers(
          response.data.data.data
        );
      },
      (error) => {
        console.log(error)
      }
    )
      .catch(
        (error) => {
          console.log(error)
        }
      );
  };
  return (
    <>
      <div className="content-wrapper">

        <ToastContainer />
        <Breadcrumbs title='Customer Banks' path='/dashboard' />

        <div className="container-fluid">
          {isLoading ? (
           <Loader />
          ) : (
            <section className="">
              <div className="">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className='card-title'>
                          Customer Beneficiaries Details
                        </div>
                        <div className='card-tools'>
                          <h3 className="card-title float-right" ></h3>
                        </div>
                      </div>
                      <div className="card-body table-responsive">
                        <div id="example2_wrapper" className="dataTables_wrapper dt-bootstrap4">
                          <table id="example1"
                            className="
                                    table 
                                    table-bordered
                                    table-striped 
                                    dataTable dtr-inline
                                  "
                            aria-describedby="example1_info"
                            style={{ minHeight: '13rem' }}
                          >
                            <thead>
                              <tr>
                                <th
                                  className="sorting sorting_asc"
                                  tabIndex={0}
                                  aria-controls="example1"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-sort="ascending"
                                  aria-label="Rendering engine: activate to sort column descending">
                                  Customer Name
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example1"
                                  rowSpan={1} colSpan={1}
                                  aria-label="Browser: activate to sort column ascending">
                                  Customer Mobile Number
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example1"
                                  rowSpan={1} colSpan={1}
                                  aria-label="Platform(s): activate to sort column ascending">
                                  Beneficiary Name
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example1"
                                  rowSpan={1} colSpan={1}
                                  aria-label="Engine version: activate to sort column ascending">
                                  Account Number
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example1"
                                  rowSpan={1} colSpan={1}
                                  aria-label="CSS grade: activate to sort column ascending">
                                  Bank Name
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example1"
                                  rowSpan={1} colSpan={1}
                                  aria-label="CSS grade: activate to sort column ascending">
                                  IFSC Code
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example1"
                                  rowSpan={1} colSpan={1}
                                  aria-label="CSS grade: activate to sort column ascending">
                                  Added By
                                </th>
                              </tr>
                            </thead>
                            {users.length ? (
                              <tbody>
                                {users
                                  ? users.map((element, index) => {
                                    return (
                                      <tr className="trodd text-center" key={index} id={`tr-${element.id}`}>
                                        <td>{element.customer.name}</td>
                                        <td>{element.customer.mobile_number}</td>
                                        <td>{element.beneficiary_name}</td>
                                        <td>{element.account_no}</td>
                                        <td>{element.bank_name}</td>
                                        <td>{element.ifsc_code}</td>
                                        <td>{element.user && element.user.first_name ? element.user.first_name : '-'} 
                                        {/* {element.user.middle_name} 
                                        {element.user.last_name} */}
                                        </td>

                                      </tr>
                                    );
                                  }
                                  )
                                  : ""}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td colspan='7'>
                                    <p className='font-mulish'>
                                      No Records Found
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            )}

                          </table>
                        </div>

                      </div>
                      <div className='mx-3'>
                        <div className='row'>
                          {users.length > 0 ? (
                            <>
                              <div className="col-sm-12 col-lg-6">
                                <div className="dataTables_info text-primary" id="example2_info" role="status" aria-live="polite">
                                  *Showing<span className='fw-bold'> {users.length} </span>
                                  of <span className='fw-bold'>{totalItemsCount} </span>entries
                                </div>
                              </div>
                              <div className="col-sm-12 col-lg-6 text-right ">
                                <div className="float-lg-end pagination-sm pagination justify-content-start  dataTables_paginate paging_simple_numbers" id="example2_paginate">
                                  <Pagination
                                    activePage={activepage}
                                    itemsCountPerPage={10}
                                    totalItemsCount={totalItemsCount}
                                    pageRangeDisplayed={10}
                                    onChange={handlePageChange}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                  />

                                </div>
                              </div>
                            </>
                          ) : (
                            " "
                          )}
                        </div>
                      </div>
                    </div >
                  </div >
                </div >
              </div >
            </section >
          )
          }
        </div >
      </div >
    </>
  );
}

export default List;