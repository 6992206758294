import React from 'react'
import Table from '../../../components/sections/Table'
import Card from '../../../components/sections/Card';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';

const CommissionList = () => {
  return (
    <div className='content-wrapper'>
      <Breadcrumbs title='Retailer List' path='/distributor-dashboard' />
      <div className='container'>
        <Card title='Retailer List' body={
          <>
            <Table
              tableHeading={['Name', 'Firm Name', 'Username', 'Email', 'Phone', 'Alt Phone', 'Commission', 'Status', 'Created']}
              tableBody='No data right now'
            />
          </>
        }
        />
      </div>
    </div>
  )
}

export default CommissionList