import React from 'react';
import banner from '../../../assets/images/moneytransferbanner.png';
import styles from './Signup.module.scss';
import map from '../../../assets/icons/combined.svg';
import Typewriter from 'typewriter-effect';

const Signup = () => {
  return (
    <div className={styles.background}>
      <div className={`pb-4`}>
        <div className='container-fluid'>
          <div>
            <div className='row py-4'>
              <div className='col-md-5 text-center m-auto'>
                <img src={banner}
                  className="img-fluid banner-image"
                  alt="Sample" />
              </div>
              <div className='col-md-7 my-auto'>

                <div className='text-white text-md-start pl-md-5 text-center'>
                  <span className='fw-bold h1'>
                    <span className='typewriter_text font-mulish'>
                      Welcome to<br />
                      <span className={`font-nova ${styles.primaryText}`}>
                        <Typewriter
                          options={{
                            strings: ['<span>Money Transfer Portal</span>'],
                            autoStart: true,
                            loop: true,
                            delay: 200
                          }}
                        />
                      </span>
                    </span>
                  </span>
                  <span className='font-12 font-merriweather'>
                  Fintech services leverage technology to enhance financial processes, including payments, lending, investments, and personal financial management, revolutionizing the industry.
                  </span>
                </div>
              </div>
            </div>
            <div className='row text-black'>
              <div className='col-lg-6'>
                <div className={`p-5 mx-3 ${styles.secondary_background}`}>
                  <h2 className='text-center fw-bold font-oswald'> Why Choose <span className={`${styles.primaryText} font-nova`}>MT Portal</span> ?</h2>
                  <dl>
                    <dt className='h4 font-mulish text-info fw-bold'>Security :</dt>
                    <dd className='font-merriweather'>
                      Your financial security is our top priority. We use state-of-the-art encryption and security measures to ensure that your money and personal information are always protected.
                    </dd>
                    <dt className='h4 font-mulish text-info fw-bold'>Speed :</dt>
                    <dd className='font-merriweather'>
                      Need to send money urgently? We offer lightning-fast transfers so your funds reach their destination as quickly as possible.
                    </dd>
                    <dt className='h4 font-mulish text-info fw-bold'>Global :</dt>
                    <dd className='font-merriweather'>
                      Whether you're sending money locally or across borders, we've got you covered. Our extensive network ensures that you can transfer money to virtually any corner of the world.
                    </dd>
                    <dt className='h4 font-mulish text-info fw-bold'>Competitive :</dt>
                    <dd className='font-merriweather'>
                      We believe in providing our customers with the best value. Enjoy competitive exchange rates and low fees when you choose [Your Company Name].
                    </dd>
                    <dt className='h4 font-mulish text-info fw-bold'>24/7 Support :</dt>
                    <dd className='font-merriweather'>
                      Have a question or need assistance? Our dedicated customer support team is available around the clock to assist you.
                    </dd>
                  </dl>
                </div>
              </div>
              <div className='col-lg-6 m-auto text-center'>
                <img className={styles.home_image} src={map} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signup