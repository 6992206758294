import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import MoneyTransferReport from '../../../components/sections/retailer/MoneyTransferReport'
const SettleTransferReport = () => {
    return (
        <div className='content-wrapper'>
        <Breadcrumbs title='Transaction Report' path='retailer-dashboard' />
        <div className='container'>
         <MoneyTransferReport title="Transaction Report" />
        </div>
      </div>
    )
}

export default SettleTransferReport