import * as Yup from 'yup';

export const validationSchema = Yup.object({
    first_name: Yup.string().matches(/^[A-Za-z ]+$/, 'Name should only contain alphabets and spaces')
        .required('First Name is required'),
    middle_name: Yup.string().matches(/^[A-Za-z ]+$/, 'Name should only contain alphabets and spaces').nullable(),
    last_name: Yup.string().matches(/^[A-Za-z ]+$/, 'Name should only contain alphabets and spaces').nullable(),
    email: Yup.string().email('Invalid email').required('Email id is required'),
    address_one: Yup.string().required('Address is required'),
    mobile_number: Yup.string().matches(/^\d{10}$/, 'Mobile number must be 10 digits')
        .required('Mobile number is required'),
    adhar: Yup.string().matches(/^\d{12}$/, 'Adhar number must be 12 digits')
        .required('Adhar number is required'),
    pan: Yup.string().matches(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/, 'Invalid PAN card number')
        .required('PAN card number is required'),
    status: Yup.string().required('Select one status'),

})

export const fundSchema = Yup.object({
    amount: Yup.string().matches(/^\d+$/, 'Amount must be numeric')
        .required('Amount is required'),
})

export const reportSchema = Yup.object({
    user_id : Yup.string().required('Select atleast one retailer'),
    from_date : Yup.string().required('Start date is required'),
    to_data : Yup.string().required('End date is required'),
    report : Yup.string().required('Select report format')
})
export const matmSchema = Yup.object({
    amount: Yup.string()
        .required('Amount is required'),
    mobile_number: Yup.string().matches(/^\d{10}$/, 'Mobile number must be 10 digits')
        .required('Mobile number is required'),
    address: Yup.string().required('Address is required'),
    state: Yup.string().matches(/^[A-Za-z ]+$/,
        'State should only contain alphabets and spaces')
        .required('State is required'),
    city: Yup.string().matches(/^[A-Za-z ]+$/,
        'City should only contain alphabets and spaces')
        .required('City is required'),
    pincode: Yup.string().matches(/^\d{6}$/,
        'Pincode must be 6 digits')
        .required('Pincode is required'),
});

export const activateSchema = Yup.object({
    retailer_id: Yup.string().required('Retailer id is required')
})


export const activateAxisCardSchema  = Yup.object({
  card_number: Yup.string()
    .required("Card Number is required"),
  // cvv: Yup.string()
  //   .required("CVV is required")
  //   .matches(/^\d{3,4}$/, "CVV must be of length 3 or 4"),
  cardHolder_name : Yup.string()
    .matches(/^[A-Za-z ]+$/, 'Name should only contain alphabets and spaces')
    .required('Holder Name is required'),
})

export const datePckerValidaton = Yup.object({
  start_date: Yup.string()
    .required(),
  end_date: Yup.string()
    .required(),
})

export const ChangePasswordValidation = Yup.object({
  old_password: Yup.string()
  .required('Current Password is required')
  .min(8, 'Current Password must be at least 8 characters long'),
  new_password: Yup.string()
    .required('New Password is required')
    .min(8, 'New Password must be at least 8 characters long'),
  confrim_password: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
});

export const gstValidationSchema = Yup.object({
  gst: Yup.string().required('Gst number is required')
    .matches(/^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9]){1}?$/
  ,'Please enter a valid GSTIN number')
})

