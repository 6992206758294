import React from "react";
import styles from './Card.module.scss'

const ScrollingCardBody = ({ title, content }) => {
  return (
    <div className={`card ${styles.alerts}`}>
      <div className="card-body">
        <h3 className="text-center">{title}</h3>
        <div className={`${styles.scroll_container} my-3`}>
          <p
            className={`text-center pt-2 font-mulish ${styles.scrolling_text}`}
          >
            {content}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ScrollingCardBody;
