import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import { Form, Formik } from 'formik'
import FormikControl from '../../../components/FormikControl'
import { gender, wallet } from '../../../helpers/constant';
import {HiOutlineDownload} from 'react-icons/hi'

const ApplyNsdl = () => {
  const initialvalues = {
    pan_type: '',
    title: '',
    last_name: '',
    first_name: '',
    middle_name: '',
    gender: '',
    wallet: ''
  }
  return (
    <div className='content-wrapper'>
      <Breadcrumbs title='Create Pan' path='/retailer-dashboard' />
      <div className='container'>
        <div className='row'>
          <div className='col-md-8'>
            <div className='card' style={{ backgroundColor: "#f1feff" }}>
              <div className='card-body'>
                <h3 className="text-center mb-3 border-bottom border-success border-5 pb-2">
                  Create Pan
                </h3>
                <div className=''>
                  <Formik initialValues={initialvalues}>
                    <Form>
                      <div className='row'>
                        <div className='col-md-6'>
                          <FormikControl
                            control='select'
                            name='pan_type'
                            label='Pan Type'
                            options={[
                              { key: 1, value: '--Select Pan type--' }
                            ]}
                          />
                        </div>
                        <div className='col-md-6'>
                          <FormikControl
                            name='title'
                            control='select'
                            label='Title'
                            options={[
                              { key: 1, value: '--Title--' }
                            ]}
                          />
                        </div>
                        <div className='col-md-6'>
                          <FormikControl
                            control='input'
                            name='last_name'
                            label='Last Name / Surname'
                            placeholder='Enter last name / surname'
                          />
                        </div>
                        <div className='col-md-6'>
                          <FormikControl
                            name='first_name'
                            control='input'
                            label='First Name'
                            placeholder='Enter first name'
                          />
                        </div>
                        <div className='col-md-6'>
                          <FormikControl
                            name='middle_name'
                            control='input'
                            label='Middle Name'
                            placeholder='Enter middle name'
                          />
                        </div>
                        <div className='col-md-6'>
                          <FormikControl
                            control='select'
                            label='Gender'
                            name='gender'
                            options={gender}
                          />
                        </div>
                        <div className='col-md-12'>
                          <FormikControl
                            control='radio'
                            label='Select Wallet'
                            name='wallet'
                            options={wallet}
                          />
                        </div>
                      </div>
                      <div className='text-center'>
                        <button className='btn btn-secondary rounded-pill px-3'>
                          Submit
                        </button>
                      </div>
                    </Form>
                  </Formik>

                </div>
                <div className='text-center mt-5'>
                  <p className='text-danger fw-bold'>
                    Please ensure you have given permission to allow pop-up to appear while processing PAN card application.
                  </p>
                  <p className='text-success fw-bold'>
                    Now you can download e-PAN using the acknowledgement no. within 30days of registration
                  </p>
                  <button className='btn btn-secondary px-5 rounded-pill'>
                    Click here to Download ePAN
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='card' style={{ backgroundColor: "#f1feff" }}>
              <div className='card-body'>
                <h5 className='font-mulish text-center fw-bold mb-4'>PAN application fees for New/Change request Pan application</h5>
                <div className='table-responsive'>
                  <div className='table table-bordered table-info'>
                    <tr>
                      <td>Physical PAN card within India</td>
                      <td className='fw-bold'>Rs 107 /-</td>
                    </tr>
                    <tr>
                      <td>Physical PAN card outside India</td>
                      <td className='fw-bold'>Rs 1,017 /-</td>
                    </tr>
                    <tr>
                      <td>e-PAN card (within or outside India)</td>
                      <td className='fw-bold'>Rs 72 /-</td>
                    </tr>
                  </div>
                </div>
              </div>
            </div>
            <div className='card' style={{ backgroundColor: "#f1feff" }}>
              <div className='card-body'>
                <h5 className='font-mulish border-bottom border-success border-5 text-center fw-bold mb-4 pb-2'>
                  Please download Do's and Don'ts for NSDL PAN Card and display it in your shop
                  </h5>
                  <div className='text-center'>
                  <button className='btn btn-secondary rounded-pill'>
                    Download <HiOutlineDownload />
                  </button>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplyNsdl