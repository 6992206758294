import * as Yup from "yup";

export const validationSchema = Yup.object({
  name: Yup.string()
    .matches(/^[A-Za-z ]+$/, "Name should only contain alphabets and spaces")
    .required("First Name is required"),
  dob: Yup.string()
    .matches(
      /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/,
      "Dob should only contain numbers and hyphen in format dd-mm-yyyy"
    )
    .required("Date of birth is required"),
  address: Yup.string().required("Address is required"),
  pincode: Yup.string()
    .matches(/^\d{6}$/, "Pincode must be 6 digits")
    .required("Pincode is required"),
  mobile_number: Yup.string()
    .matches(/^\d{10}$/, "Mobile number must be 10 digits")
    .required("Mobile number is required"),
  state: Yup.string()
    .matches(/^[A-Za-z ]+$/, "State should only contain alphabets and spaces")
    .required("State is required"),
  city: Yup.string()
    .matches(/^[A-Za-z ]+$/, "City should only contain alphabets and spaces")
    .required("City is required")
});

export const bankSchema = Yup.object({
  name: Yup.string()
    .matches(/^[A-Za-z ]+$/, "Name should only contain alphabets and spaces")
    .required("Name is required"),
  bank_name: Yup.string()
    .matches(
      /^[A-Za-z ]+$/,
      "Bank name should only contain alphabets and spaces"
    )
    .required("Bank Name is required"),
  account_no: Yup.number()
    .required("Account number is required")
    .integer("Account number must be numeric"),
  ifsc_code: Yup.string()
    .matches(/^([A-Z]){4}0([A-Z0-9]){6}?$/, "Invalid IFSC code")
    .required("IFSC code is required"),
  address: Yup.string().required("address is required"),
  beneficiary_name: Yup.string()
    .matches(/^[A-Za-z ]+$/, "Name should only contaim alphabets and spaces")
    .required("Beneficiary name is required")
});

export const aepsSchema = Yup.object({
  customer_mobile_number: Yup.string()
    .matches(/^\d{10}$/, "Mobile number must be 10 digits.")
    .required("Mobile number is required."),
  customer_adhar_number: Yup.string()
    .matches(/^\d{12}$/, "Aadhar number must be 12 digits.")
    .required("Aadhar number is required."),
  amount: Yup.string().matches(/^\d/, "Amount should be in digits."),
  // .required("Amount is required."),
  bank_id: Yup.string().required("You need to select the bank.")
});

export const dmtSchema = Yup.object({
  amount: Yup.string()
    .matches(/^\d/, "Amount should be in digits")
    .required("Amount is required.")
});

export const confirmDmtSchema = Yup.object({
  transfer_type: Yup.string().required("Select transfer method"),
  wallet: Yup.string().required("Select wallet")
});

export const cashWithdrawlApSchema = Yup.object({
  mobile_number: Yup.string()
    .matches(/^\d{10}$/, "Mobile number must be 10 digits.")
    .required("Mobile number is required."),
  aadhar_number: Yup.string()
    .matches(/^\d{12}$/, "Aadhar number must be 12 digits.")
    .required("Aadhar number is required."),
  amount: Yup.string()
    .matches(/^\d/, "Amount should be in digits.")
    .required("Amount is required."),
  bank_id: Yup.string().required("You need to select the bank.")
});
export const axisAccountSchema = Yup.object({
  mobile_number: Yup.string()
    .matches(/^\d{10}$/, "Mobile number must be 10 digits.")
    .required("Mobile number is required."),
  account_type: Yup.string().required("Account type is required."),
  name: Yup.string()
    .matches(/^[A-Za-z ]+$/, "Name should only contain alphabets and spaces")
    .required("Name is required")
});
export const prepaidRechargeSchema = Yup.object({
  mobile_number: Yup.string()
    .matches(/^\d{10}$/, "Mobile number must be 10 digits.")
    .required("Mobile number is required."),
  operator: Yup.string().required("Account type is required."),
  amount: Yup.string()
    .matches(/^\d/, "Amount should be in digits.")
    .required("Amount is required.")
});
export const dthRechargeSchema = Yup.object({
  ca_number: Yup.string()
    .matches(/^\d{10}$/, "Mobile number must be 10 digits.")
    .required("Mobile number is required."),
  operator: Yup.string().required("Account type is required."),
  amount: Yup.string()
    .matches(/^\d/, "Amount should be in digits.")
    .required("Amount is required.")
});
export const broadbandSchema = Yup.object({
  landline_number: Yup.string().required("Landline number is required."),
  operator: Yup.string().required("Operator is required.")
});
export const cableSchema = Yup.object({
  subscriber_code: Yup.string().required("Subscriber code is required."),
  operator: Yup.string().required("Operator is required.")
});
export const electricitySchema = Yup.object({
  k_number: Yup.string().required("K number is required."),
  operator: Yup.string().required("Operator is required.")
});
export const emiSchema = Yup.object({
  loan_number: Yup.string().required("Loan number is required."),
  operator: Yup.string().required("Operator is required.")
});
export const gasSchema = Yup.object({
  customer_id: Yup.string().required("Customer id is required."),
  operator: Yup.string().required("Operator is required.")
});
export const telephoneSchema = Yup.object({
  telephone_number: Yup.string().required("Telephone number is required."),
  operator: Yup.string().required("Operator is required.")
});
export const lpgSchema = Yup.object({
  consumer_number: Yup.string().required("Consumer number is required."),
  operator: Yup.string().required("Operator is required.")
});
export const waterSchema = Yup.object({
  rr_number: Yup.string().required("RR number is required."),
  operator: Yup.string().required("Operator is required.")
});
export const fasttagSchema = Yup.object({
  vehicle_registeration_number: Yup.string().required(
    "Vehicle registeration number is required."
  ),
  operator: Yup.string().required("Operator is required.")
});
export const insuranceSchema = Yup.object({
    policy_number: Yup.string()
        .required('Customer id is required.'),
    operator: Yup.string().required('Operator is required.'),
    mobile_number: Yup.string().matches(/^\d{10}$/, 'Mobile number must be 10 digits.')
        .required('Mobile number is required.'),
    dob: Yup.string().matches(/^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/,
        'Dob should only contain numbers and hyphen in format dd-mm-yyyy')
        .required('Date of birth is required'),
  email: Yup.string()
        .matches(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/, 'Invalid email')
        .required('Email is required'),
});
export const dcPostpaidSchema = Yup.object({
    operator: Yup.string()
      .required('Operator is required.'), 
    mobile_number: Yup.string().matches(/^\d{10}$/, 'Mobile number must be 10 digits.')
        .required('Mobile number is required.'),
    net_amount: Yup.string()
        .matches(/^\d/, 'Amount should be in digits.')
        .required("Amount is required."),
    wallet: Yup.string().required('Select one wallet')
});
export const dcPrepaidSchema = Yup.object({
  operator: Yup.string().required("Operator is required."),
  datacard_number: Yup.string().required("Data card number is required."),
  net_amount: Yup.string()
    .matches(/^\d/, "Amount should be in digits.")
    .required("Amount is required."),
  wallet: Yup.string().required("Select one wallet")
});
export const municipalitySchema = Yup.object({
    operator: Yup.string()
        .required('Operator is required.'),
        ulb_code: Yup.string()
        .required('ULB code number is required.'),
        property_id: Yup.string()
        .required("Propert id is required."),
});


export const moneyTransferDatePicker = Yup.object({
  start_date: Yup.string()
    .required('Start date is required'),
  end_date: Yup.string()
    .required('End date is required'),
  // keyWord: Yup.string()
  //   .required('Keyword is required')
});

export const refundAndPendingDatePicker = Yup.object({
  start_date: Yup.string()
    .required('Start date is required'),
  end_date: Yup.string()
    .required('End date is required'),
  // keyWord: Yup.string()
  //   .required('Keyword is required')
});

export const TransactionReportSchema = Yup.object({
  start_date: Yup.string()
    .required('Start date is required'),
  end_date: Yup.string()
    .required('End date is required'),
  keyword: Yup.string()
    .required('Keyword is required'),
  search: Yup.string()
    .required('select the search item'),
  // status: Yup.string()
  //   .required('select the status item')
});

export const creditCardBillSchema = Yup.object({
  card_number: Yup.string()
    .required('Card number is required'),
});

export const applyLoanValidationSchema = Yup.object({
  mobile_number: Yup.string()
    .matches(/^\d{10}(?:[-\s]\d{10})*$/, 'Mobile number must be 10 digits with spaces or dashes.')
    .required('Mobile number is required.'),
  salutation: Yup.string()
    .required('choose something'),
  first_name: Yup.string()
    .matches(/^[A-Za-z ]+$/, 'Name should only contain alphabets and spaces')
    .required('First Name is required'),
  last_name: Yup.string()
    .matches(/^[A-Za-z ]+$/, 'Name should only contain alphabets and spaces')
    .required('First Name is required'),
  dob: Yup.string()
    .matches(/^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/, 'Dob should only contain numbers and hyphen in format dd-mm-yyyy')
    .required('Date of birth is required'),
  gender: Yup.string()
    .required('choose something'),
  email: Yup.string()
    .matches(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/, 'Invalid email')
    .required('Email is required'),
  pincode: Yup.string()
    .trim().length(6, 'Pincode must be exactly 6 characters')
    .matches(/^[0-9]{6}$/, 'Pincode can only contain numbers')
    .required('Pincode is required'),
  state: Yup.string()
    .matches(/^[A-Za-z ]+$/, 'State should only contain alphabets and spaces')
    .required('State is required'),
  city: Yup.string()
    .matches(/^[A-Za-z ]+$/, 'City should only contain alphabets and spaces')
    .required('City is required'),
  pancard: Yup.string()
    .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, 'Please enter a valid PanCard number')
    .required('PanCard number is required'),
  occupation: Yup.string()
    .matches(/^[a-zA-Z]+$/, 'Please enter a valid Occupation')
    .required('Occupation is required'),
  income: Yup.string()
    .required('Income is required'),
  loan_amount: Yup.string()
    .required("Amount is required"),
  loan: Yup.string()
    .matches(/^\d+$/, 'Amount should be in digits.')
    .required("Amount is required."),
  salary: Yup.string()
    .required('choose something'),
  salarySlip: Yup.string()
    .required('choose something'),
  salaryNetBanking: Yup.string()
    .required('choose something'),
  address: Yup.string()
    .required('address is required')
});

export const ActivateMatmvalidationSchema = Yup.object({
  serial_number: Yup.string()
    .required('Serial number is required'),
});

export const PaymentGateWayvalidationSchema = Yup.object({
  account_type: Yup.string()
    .required('choose something'), 
  amount: Yup.number()
    .min(0.01, 'Amount must be at least $0.01')
    .max(1000000, 'Amount cannot exceed $1,000,000')
    .required('Amount is required'),
  mobile_number: Yup.string()
    .matches(/^\d{10}$/, 'Mobile number must be 10 digits.')
    .required('Mobile number is required.'),
  email: Yup.string()
    .matches(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/, 'Invalid email')
    .required('Email is required'),
  card_digit: Yup.string()
    .label('Card number')
    .max(16)
    .required('Card number is required'),
  name: Yup.string().matches(/^[A-Za-z ]+$/,
    'Name should only contain alphabets and spaces')
    .required('Card holder Name is required'),
});

export const AddBankvalidationSchema = Yup.object({
  bank_name: Yup.string().matches(/^[A-Za-z ]+$/,
    'Bank name should only contain alphabets and spaces')
    .required('Bank Name is required'),
  account_name: Yup.string().matches(/^[A-Za-z ]+$/,
    'Name should only contain alphabets and spaces')
    .required('Name is required'),
  account_number: Yup.number()
    .required('Account number is required')
    .integer('Account number must be numeric'),
  confirm_account_number: Yup.string()
    .required('Confirm account number is required')
    .oneOf([Yup.ref('account_number')], 'Account numbers must match'),
  ifsc_code: Yup.string().matches(/^([A-Z]){4}0([A-Z0-9]){6}?$/, 'Invalid IFSC code')
    .required('IFSC code is required'),
});

export const beneficiarySchema = Yup.object({
  id: Yup.string(),
  customer_id: Yup.string(),
  account_no: Yup.number()
    .required("Account number is required")
    .integer("Account number must be numeric"),
  ifsc_code: Yup.string()
    .matches(/^([A-Z]){4}0([A-Z0-9]){6}?$/, "Invalid IFSC code")
    .required("IFSC code is required"),
  address: Yup.string().required("address is required"),
  beneficiary_name: Yup.string()
    .matches(/^[A-Za-z ]+$/, "Name should only contaim alphabets and spaces")
    .required("Beneficiary name is required"),
  bank_name: Yup.string()
    .matches(
      /^[A-Za-z ]+$/,
      "Bank name should only contain alphabets and spaces"
    )
    .required("Bank Name is required"),
  name: Yup.string()
    .matches(/^[A-Za-z ]+$/, "Name should only contain alphabets and spaces")
    .required("Name is required")
});