import React, { useEffect, useState } from 'react'
import MantraButton from '../../../components/MantraButton'
import RetailerHeader from '../RetailerHeader'
import { selectBanks } from '../../../api/instance_method'
import { ToastContainer, toast } from 'react-toastify'
import { aepsSchema } from '../validation';
import { aepsService } from '../../../helpers/constant'
import { useDispatch } from 'react-redux'
import { setFeature, setMantraButton } from '../../../redux/slices/authSlice';
import Breadcrumbs from '../../../components/sections/Breadcrumbs'

const Aeps = () => {

  const [bank, setBank] = useState([]);
  const [mantraButtondata, setMantrabuttondata] = useState(null);
  const fingerPrintData = localStorage.getItem('data');
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    customer_mobile_number: '',
    customer_adhar_number: '',
    amount: '',
    aepsService: '',
    bank_id: '',
    myCheckbox: true,
    fingerprint: fingerPrintData,
  });

  dispatch(setFeature('aeps'))
  useEffect(() => {
    selectBanks().then((response) => {
      const banks = response.data.data;
      const allBanks = [
        { key: '--Select Bank--', value: '--Select Bank--' },
        ...banks.map((bank) => ({
          key: bank.id,
          value: bank.name,
        })),
      ];
      setBank(allBanks);
    });
  }, []);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFastCashClick = (value) => {
    setFormData({ ...formData, amount: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await aepsSchema.validate(formData, { abortEarly: false });
      // If validation passes, continue with form submission logic
      if (formData.myCheckbox) {
        if (formData.amount) {
          await setMantrabuttondata(formData);
          localStorage.setItem('values', JSON.stringify(formData));
          dispatch(setMantraButton(true));
        } else {
          toast.warning('Please enter the amount.');
        }
      } else {
        toast.warning('Please accept terms and conditions.');
      }
    } catch (error) {
      // Handle validation errors
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
        toast.error(err.message)
      });
    }
  };
  return (
    <div className='content-wrapper'>
      <RetailerHeader />
      <ToastContainer />
      <Breadcrumbs title='AEPS' path='/retailer-dashboard' />
      <div className='container-fluid'>
        <div className='pb-5'>
          <div className='card mx-1'>
            <div className='card-header'>
              <div className='card-title'>
                aadhar Enabled Payment (AEPS)
              </div>
            </div>
            <div className='card-body p-4'>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div>
                  <div className='row pb-4'>
                    <div className='col-lg-6 col-sm-12'>
                      <label htmlFor="customer_mobile_number">Enter Customer Mobile Number:</label>
                      <input
                        className='form-control'
                        type="text"
                        id="customer_mobile_number"
                        name="customer_mobile_number"
                        placeholder="Enter Mobile Number"
                        value={formData.customer_mobile_number}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className='col-lg-6 col-sm-12'>
                      <label for="customer_adhar_number">Enter Aadhar Number/VIRTUAL-ID:</label>
                      <input
                        className='form-control'
                        type="text"
                        id="customer_adhar_number"
                        name="customer_adhar_number"
                        placeholder="Enter AADHAR Number/VIRTUAL-ID"
                        value={formData.customer_adhar_number}
                        onChange={handleInputChange} />
                    </div>
                  </div>
                  <div className='row pb-4'>
                    <div className='col-lg-6 col-sm-12'>
                      <label for="amount">Enter Amount to Withdraw or Select from Fast Cash:</label>
                      <input
                        className='form-control' type="text" onChange={handleInputChange} id="amount" value={formData.amount} name="amount" placeholder="Enter Amount" />
                    </div>
                    <div className='col-lg-6 d-flex justify-content-center'>
                      <div className='row'>
                        <div className='col-lg-1 d-flex justify-content-center align-items-center'>
                          <h5 className='text-primary'>or</h5>
                        </div>
                        <div className='col-lg mx-auto text-center'>
                          <input type='button' className='fast-cash-button mx-1 mt-4' value='1000' onClick={() => handleFastCashClick('1000')} />
                          <input type='button' className='fast-cash-button mx-1 mt-4' value='1500' onClick={() => handleFastCashClick('1500')} />
                          <input type='button' className='fast-cash-button mx-1 mt-4' value='3000' onClick={() => handleFastCashClick('3000')} />
                          <input type='button' className='fast-cash-button mx-1 mt-4' value='5000' onClick={() => handleFastCashClick('5000')} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-6 col-sm-12'>
                      <label for="aepsService">Select Service:</label>
                      <select
                        id='aepsService'
                        name='aepsService'
                        className='form-control'
                        value={formData.aepsService}
                        onChange={(e) => setFormData({ ...formData, aepsService: e.target.value })}
                      >
                        {aepsService.map((serviceOption) => (
                          <option key={serviceOption.key} value={serviceOption.key}>
                            {serviceOption.value}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='col-lg-6 col-sm-12'>
                      <label for="bank_id">Select Bank:</label>
                      <select
                        id='bank_id'
                        name='bank_id'
                        className='form-control'
                        value={formData.bank_id}
                        onChange={(e) => setFormData({ ...formData, bank_id: e.target.value })}
                      >
                        <option value=''>--Select Bank--</option>
                        {bank.map((bankOption) => (
                          <option key={bankOption.key} value={bankOption.key}>
                            {bankOption.value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='my-3 ml-3 pl-1' style={{ marginRight: '-2rem;' }}>
                    <input
                      type='checkbox'
                      id='myCheckbox'
                      name='myCheckbox'
                      checked={formData.myCheckbox}
                      className='form-check-input'
                      onChange={() => setFormData({ ...formData, myCheckbox: !formData.myCheckbox })}
                    />
                    <label htmlFor='myCheckbox'>I accept all terms & conditions</label>

                  </div>
                  <div className='row'>
                    <div className='col-lg-6 text-lg-end text-center'>
                      <MantraButton formData={mantraButtondata} />
                    </div>
                    <div className='col-lg-6 mt-2 mt-lg-0 text-lg-start text-center'>
                      <button
                        type='button'
                        className='btn btn-danger mx-3 px-3 rounded-pill'
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Aeps
