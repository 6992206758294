import * as Yup from 'yup';


export const adharPayReportSchema = Yup.object({
  start_date: Yup.string()
    .required('Start date is required'),
  end_date: Yup.string()
    .required('End date is required'),
  keyword: Yup.string()
    .required('Keyword is required'),
  search : Yup.string()
    .required('select the search item'),
  status : Yup.string()
    .required('select the status item')
})