import React from 'react'

const BookNow = () => {
  return (
    <div className='content-wrapper'>
        website like makemytrip
    </div>
  )
}

export default BookNow