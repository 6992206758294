import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import { CardHeader } from '../../../components/sections/CardHeader'
import { Form, Formik } from 'formik'
import FormikControl from '../../../components/FormikControl';
import { BiRightArrowAlt } from "react-icons/bi";
import { activateSchema } from '../Validation';
import { ToastContainer, toast } from 'react-toastify';

const ActivateDmt = () => {
    const initialValues = {
        retailer_id: ''
      }
    
  const handleSubmit = (values, { resetForm }) => {
    toast.success('ActivateDmt successfull', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    resetForm()
  }
  return (
    <div className='content-wrapper'>
      <ToastContainer/>
            <Breadcrumbs
                title='DMT'
                path='/distributor-dashboard'
            />
            <div className='container'>
            <div className='card'>
          <CardHeader title="Activate DMT" />
          <div className='card-body'>
            <Formik
              initialValues={initialValues}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={activateSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className="row">
                  <div className="col-md-6">
                    <FormikControl
                      control="input"
                      placeholder="Enter Retailer ID"
                      name='retailer_id'
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="mx-4" style={{ marginTop: "0.5rem" }}>
                      <button className="btn btn-dark rounded-pill">
                        <BiRightArrowAlt className="font-1"  type='submit'/>
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
            </div>
        </div>
  )
}

export default ActivateDmt