import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import { Formik, Form } from 'formik'
import FormikControl from '../../../components/FormikControl'
import { gender, salutation } from '../../../helpers/constant'
import Card from '../../../components/sections/Card'
import { applyLoanValidationSchema } from '../validation'
import { ToastContainer, toast } from 'react-toastify';

const ApplyLoan = () => {

  const initialValues = {
    mobile_number: "",
    salutation: "",
    first_name: "",
    last_name: "",
    dob: "",
    gender: "",
    email: "",
    pincode: "",
    state: "",
    city: "",
    pancard: "",
    occupation: "",
    income: "",
    loan: "",
    loan_amount: "",
    salary: "",
    salarySlip: "",
    salaryNetBanking: "",
    address: "",
  }

  const handleSubmit = (value, { resetForm }) => {
    console.log('submit button clicked');
    toast.success('Data read successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    console.log('submit button clicked');
    resetForm()
  }

    return (
        <div className='content-wrapper'>
            <ToastContainer/>
            <Breadcrumbs title='Finequs (Finolet) Loan' path='/retailer-dashboard' />
            <div className='container'>
                <Card title='Apply Loan'>
                    <Formik
                      initialValues={initialValues}              
                      validationSchema={applyLoanValidationSchema}
                      validateOnBlur={false}
                      validateOnChange={false}
                      onSubmit={handleSubmit}
                    >
                        <Form>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <FormikControl
                                        name='mobile_number'
                                        control='input'
                                        label='Mobile Number'
                                        placeholder='Enter mobile number'
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <FormikControl
                                        name='salutation'
                                        control='radio'
                                        label='Salutation'
                                        options={salutation}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <FormikControl
                                        name='first_name'
                                        control='input'
                                        label='First Name'
                                        placeholder='Enter first name'
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <FormikControl
                                        name='last_name'
                                        control='input'
                                        label='Last Name'
                                        placeholder='Enter last name'
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <FormikControl
                                        name='dob'
                                        control='datepicker'
                                        label='Date Of Birth'
                                        placeholder='Enter date of birth'
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <FormikControl
                                        name='gender'
                                        control='select'
                                        label='Gender'
                                        options={gender}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <FormikControl
                                        name='email'
                                        control='input'
                                        label='Email Address'
                                        placeholder='Enter email address'
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <FormikControl
                                        name='pincode'
                                        control='input'
                                        label='Residence Pincode'
                                        placeholder='Enter pincode'
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <FormikControl
                                        name='state'
                                        control='input'
                                        label='State'
                                        placeholder='Enter state'
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <FormikControl
                                        name='city'
                                        control='input'
                                        label='City'
                                        placeholder='Enter city'
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <FormikControl
                                        name='pancard'
                                        control='input'
                                        label='PAN Card'
                                        placeholder='Enter pan number'
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <FormikControl
                                        name='occupation'
                                        control='input'
                                        label='Occupation'
                                        placeholder='Enter occupation'
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <FormikControl
                                        name='income'
                                        control='input'
                                        label='Net Monthly Income'
                                        placeholder='Enter income'
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <FormikControl
                                        name='loan'
                                        control='input'
                                        label='Loan Applied For'
                                        placeholder='Enter income'
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <FormikControl
                                        name='loan_amount'
                                        control='input'
                                        label='Loan Amount'
                                        placeholder='Enter amount'
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <h6 className='font-mulish fw-bold'>Is Your Salary Credited In Basic Account?</h6>
                                    <FormikControl
                                        name='salary'
                                        control='radio'
                                        options={[
                                            {key: '1', value: 'Yes'},
                                            {key: '2', value: 'No'}
                                        ]}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <h6 className='font-mulish fw-bold'>
                                        Do You Have Salary Slip or Salary Certificate?</h6>
                                    <FormikControl
                                        name='salarySlip'
                                        control='radio'
                                        options={[
                                            {key: '1', value: 'Yes'},
                                            {key: '2', value: 'No'}
                                        ]}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <h6 className='font-mulish fw-bold'>
                                        Do You Have Net Banking?</h6>
                                    <FormikControl
                                        name='salaryNetBanking'
                                        control='radio'
                                        options={[
                                            {key: '1', value: 'Yes'},
                                            {key: '2', value: 'No'}
                                        ]}
                                    />
                                </div>
                                <div className='col-md-6'>
                                  <FormikControl
                                        name='address'
                                        control='select'
                                        label='Residence Type'
                                        options={[
                                            {key: '1', value: 'Owned'},
                                            {key: '2', value: 'Rental'}
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className='text-center'>
                                <button className='btn btn-secondary rounded-pill px-3' type="submit" >
                                Apply
                              </button>
                            </div>
                        </Form>
                    </Formik>
                </Card>
            </div>
        </div>
    )
}

export default ApplyLoan