import { Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import FormikControl from '../../../FormikControl'
import { getAepsSlabById, updateAepsSlab, getMemberShips } from '../../../api/instance_method'
import { toast } from 'react-toastify';
import style from './AepsSlab.module.scss';

const EditAeps = (props) => {
  const { id } = useParams();
  const [plans, setPlans] = useState([]);
  const [initialValues, setInitialValues] = useState([{
    id: props.aepsId,
    from: '',
    to: '',
    commission: '',
    commision_type: '',
    member_ship_plan_id: ''
  }]);
  const navigate = useNavigate()

  useEffect(() => {
    getAepsSlabById(props.aepsId).then(
      (response) => {
        setInitialValues(response.data.data)
      }
    )
  }, [id])

  useEffect(() => {
    getMemberShips().then(
      (response) => {
        console.log(response.data.data.data);
        setPlans(response.data.data.data);
      }
    )
  }, [])
  
  const membershipPlan = plans.map((plan) => {
    return {
      key: plan.id,
      value: plan.name
    }
  });

  const handleSubmit = (values) => {
    const commision_type = values.commision_type == 'Percentage' ? 'Percentage' : 'Flat';
    const valuesToSend = { ...values, commision_type };
    document.getElementById('edit_slab').disabled = true;
    updateAepsSlab(valuesToSend, props.aepsId).then(
      (response) => {
        toast.success('AEPS Slab updated successfuly');
        setTimeout(() => {
          props.closeModal();
        }, 2000)
      }
    )
  }
  return (
    <div className='container'>
      <div className={`card text-center position-absolute ${style.add_form}`}>
        <div className='card-header background-transparent'>
          <h5 className='card-title'>
            <a>Edit Aeps Slab</a>
          </h5>
          <div className='card-tools'>
            <button className='close-btn btn' onClick={props.closeModal}>x</button>
          </div>
        </div>
        <div className='card-body'>
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            enableReinitialize
          >
            <Form>
              <div className='row mx-auto'>
                <div className='col-md-6'>
                  <FormikControl
                    control='input'
                    type='text'
                    name='from'
                    label='From'
                  />
                </div>
                <div className='col-md-6'>
                  <FormikControl
                    control='input'
                    type='text'
                    name='to'
                    label='to'
                  />
                </div>
              </div>
              <div className='row mx-auto'>
                <div className='col-md-12'>
                  <FormikControl
                    control='input'
                    type='text'
                    name='commission'
                    label='Commission'
                  />
                </div>
              </div>
              <div className='row mx-auto'>
                <div className='col-md-6'>
                  <FormikControl
                    control='select'
                    name='member_ship_plan_id'
                    label='Plan Type'
                    options={membershipPlan}
                  />
                </div>
                <div className='col-md-6 text-center'>
                  <h6 className='font-weight-bold font-mulish'>Commission Type</h6>
                  <div style={{ marginTop: '-.8rem', paddingLeft: '1rem' }}>
                    <FormikControl
                      control="checkbox"
                      name="commision_type"
                      checked
                      options={[
                        { key: '1', value: 'Percentage' },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className='text-center my-4'>
                <button type='submit' id="edit_slab" className='btn rounded-pill px-3 btn-dark mx-3'>
                  Update Slab
                </button>
                <button
                  type='button'
                  onClick={props.closeModal}
                  className='btn btn-danger rounded-pill px-3 mx-3'
                >
                  Cancel
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default EditAeps