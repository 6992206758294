import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import { CardHeader } from '../../../components/sections/CardHeader'
import FormikControl from '../../../components/FormikControl'
import { IoSend } from "react-icons/io5";
import { Formik, Form } from 'formik'

const SettleDistributor = () => {
  return (
    <div className='content-wrapper'>
        <Breadcrumbs path="/retailer-dashboard" title="Distributor" />
        <div className="container">
            <div className='card'>
                <CardHeader title="Distributor" />
                <div className='card-body'>
                    <Formik>
                        <Form>
                            <div className="row d-flex align-items-center">
                                <div className="col-md-6">
                                    <FormikControl
                                        control="input"
                                        placeholder="Enter amount"
                                        label="Amount"
                                        name="amount"
                                    />
                                </div>
                                <div className="col-md-6 mt-md-3">
                                    <button className="btn btn-dark">
                                        Send <IoSend />
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SettleDistributor