import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import VerticalCard from '../../../components/sections/VerticalCard'
import {BsCart4} from 'react-icons/bs'

const ProductList = () => {
  return (
    <div className='content-wrapper'>
      <Breadcrumbs title='Product List' path='/distributor-dashboard' />
      <div className='container'>
        <div className='row'>
          <div className='col-md-4'>
            <VerticalCard
              title='Mentation Thermal Printer'
            >
              <div className='border-bottom py-3'>
                <h6>1 To 1</h6>
                <h4 className='text-info'>&#x20B9; 2950 /-</h4>
              </div>
              <div className='border-bottom py-3'>
                <h6>2 To 9</h6>
                <h4 className='text-info'>&#x20B9; 2750 /-</h4>
              </div>
              <div className='border-bottom py-3'>
                <h6>10 To 19</h6>
                <h4 className='text-info'>&#x20B9; 2650 /-</h4>
              </div>
              <div className='border-bottom py-3'>
                <h6>20 To 39</h6>
                <h4 className='text-info'>&#x20B9; 2600 /-</h4>
              </div>
              <div className='border-bottom py-3'>
                <h6>40 To 59</h6>
                <h4 className='text-info'>&#x20B9; 2550 /-</h4>
              </div>
              <div className='border-bottom py-3'>
                <h6>60 To 79</h6>
                <h4 className='text-info'>&#x20B9; 2525 /-</h4>
              </div>
              <div className='border-bottom py-3'>
                <h6>80 To 100</h6>
                <h4 className='text-info'>&#x20B9; 2500 /-</h4>
              </div>
              <div className='text-center'>
                <h6>All prices are inclusive of GST</h6>
                <button className='btn btn-dark'>
                  <BsCart4 className='mb-1'/> Order Now
                  </button>
              </div>
            </VerticalCard>
          </div>
          <div className='col-md-4'>
            <VerticalCard
              title='NEOFAM Card'
            >
              <div className='border-bottom py-3'>
                <h6>1 To 9</h6>
                <h4 className='text-info'>&#x20B9; 299 /-</h4>
              </div>
              <div className='border-bottom py-3'>
                <h6>1 To 9</h6>
                <h4 className='text-info'>&#x20B9; 299 /-</h4>
              </div>
              <div className='border-bottom py-3'>
                <h6>1 To 9</h6>
                <h4 className='text-info'>&#x20B9; 299 /-</h4>
              </div>
              <div className='border-bottom py-3'>
                <h6>1 To 9</h6>
                <h4 className='text-info'>&#x20B9; 299 /-</h4>
              </div>
              <div className='border-bottom py-3'>
                <h6>1 To 9</h6>
                <h4 className='text-info'>&#x20B9; 299 /-</h4>
              </div>
              <div className='text-center'>
                <h6>All prices are inclusive of GST</h6>
                <button className='btn btn-dark'>
                  <BsCart4 className='mb-1'/> Order Now
                  </button>
              </div>
            </VerticalCard>
          </div>
          <div className='col-md-4'>
            <VerticalCard
              title='Mantra Iris Scanner'
            >
              <div className='border-bottom py-3'>
                <h6>1 To 1</h6>
                <h4 className='text-info'>&#x20B9; 4000 /-</h4>
              </div>
              <div className='text-center'>
                <h6>All prices are inclusive of GST</h6>
                <button className='btn btn-dark'>
                  <BsCart4 className='mb-1'/> Order Now
                  </button>
              </div>
            </VerticalCard>
          </div>
          <div className='col-md-4'>
            <VerticalCard
              title='Retailer Register'
            >
              <div className='border-bottom py-3'>
                <h6>1 To 5</h6>
                <h4 className='text-info'>&#x20B9; 300 /-</h4>
              </div>
              <div className='border-bottom py-3'>
                <h6>6 To 500</h6>
                <h4 className='text-info'>&#x20B9; 250 /-</h4>
              </div>
              <div className='text-center'>
                <h6>All prices are inclusive of GST</h6>
                <button className='btn btn-dark'>
                  <BsCart4 className='mb-1'/> Order Now
                  </button>
              </div>
            </VerticalCard>
          </div>
          <div className='col-md-4'>
            <VerticalCard
              title='Axis CDM Card'
            >
              <div className='border-bottom py-3'>
                <h6>1 To 10</h6>
                <h4 className='text-info'>&#x20B9; 118 /-</h4>
              </div>
              <div className='text-center'>
                <h6>All prices are inclusive of GST</h6>
                <button className='btn btn-dark'>
                  <BsCart4 className='mb-1'/> Order Now
                  </button>
              </div>
            </VerticalCard>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductList