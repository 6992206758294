import React, { useState } from 'react'
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { validationSchema } from '../validation';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FormikControl from '../../../components/FormikControl';
import { addCustomer } from '../../../api/instance_method';
import RetailerHeader from '../RetailerHeader';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';

const AddCustomer = () => {

  const [data, setData] = useState('');
  const navigate = useNavigate();
  const initialValues = {
    name: "",
    mobile_number: '',
    dob: "",
    pincode: '',
    address: "",
    state: "",
    city: ""
  };

  const onSubmit = (values) => {
    addCustomer(values).then(
      (response) => {
        document.getElementById('add-customer').disabled = true;
        toast.success('Saved successfully');
        setTimeout(() => {
          navigate("/retailer-dashboard");
        }, 4000);

      },
      (error) => {
        console.log('error')
        if (error.response.data.status === false) {
          let serverError = error.response.data.errors;
          console.log(serverError)
        }
      }
    );
  }
  const handlenavigate = () => {
    navigate('/retailer-dashboard')
  }


  return (

    <div className='content-wrapper pb-5'>
      <RetailerHeader />
      <div className='container-fluid'>
        <ToastContainer />
        <Breadcrumbs title='Add Customer' path='/retailer-dashboard' />

        <div className="">
          <div className="card mx-1">
            <div className='card-header pt-3 pb-0 d-flex '>
              <p className="font-weight-bold">Add Customer Details</p>
              <span className='mx-2'><AddCircleIcon /></span>
            </div>
            <div className='card-body'>
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validateOnChange={false}
              >
                <Form>
                  <div className='row'>
                    <div className='col-md-6 col-sm-12'>
                      <FormikControl
                        type="text"
                        control="input"
                        name="name"
                        label="Name"
                        placeholder="Name"
                      />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <FormikControl
                        type="text"
                        control="input"
                        name="dob"
                        label="DOB"
                        placeholder="dd-mm-yyyy"
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6 col-sm-12'>
                      <FormikControl
                        type="text"
                        control="input"
                        // id="mobile_number"
                        name="mobile_number"
                        label="Mobile number"
                        placeholder="Mobile number"
                      />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <FormikControl
                        type="text"
                        control="input"
                        // id="pincode"
                        name="pincode"
                        label="Pin Code"
                        placeholder="Pin Code"
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <FormikControl
                      type="text"
                      control="input"
                      // id="address"
                      name="address"
                      label="Address"
                      placeholder="Address"
                    />
                  </div>
                  <div className='row'>
                    <div className='col-md-6 col-sm-12'>
                      <FormikControl
                        type="text"
                        control="input"
                        // id="state"
                        name="state"
                        label="State"
                        placeholder="State"
                      />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <FormikControl
                        type="text"
                        control="input"
                        // id="city"
                        name="city"
                        label="City"
                        placeholder="City"
                      />
                    </div>
                  </div>
                  <div className='row text-center'>
                    <div className='col-12'>
                      <button
                        type='submit'
                        className='btn main-button mx-4 my-1'
                        id='add-customer'
                      >Submit</button>
                      <button
                        className="btn main-button my-1 mx-4"
                        onClick={handlenavigate}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddCustomer