import { Form, Formik } from 'formik';
import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import FormikControl from '../../../components/FormikControl';
import RetailerHeader from '../RetailerHeader';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';
import { dthRechargeSchema } from '../validation';

const Dth = () => {
  const initialValues = {
    ca_number : '',
    operator: '',
    amount: ''
  }

  const handleSubmit = (val) => {
    if(val) {
      toast.success('Recharge successful');
    } else {
      toast.error('Recharge unsuccessful');
    }
  }
  return (
    <div className='content-wrapper'>
        <RetailerHeader />
        <ToastContainer />
        <Breadcrumbs title='DTH' path='/retailer-dashboard' />
     
      <div className='container'>
        <div className='card'>
          <div className='card-header'>
            <div className='card-title'>
              DTH Recharge
            </div>
          </div>
          <div className='card-body'>
            <Formik 
            initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={dthRechargeSchema}
            onSubmit={handleSubmit}
            >
              <Form>
                <div className='row'>
                  <div className='col-md-4'>
                    <FormikControl
                      type='text'
                      name='ca_number'
                      control='input'
                      placeholder='Enter CA Number'
                      label='CA Number'
                    />
                  </div>
                  <div className='col-md-4'>
                    <FormikControl
                      type='text'
                      name='operator'
                      control='input'
                      placeholder='Enter Your Operator'
                      label='Operator'
                    />
                  </div>
                  <div className='col-md-4'>
                    <FormikControl
                      type='text'
                      name='amount'
                      control='input'
                      placeholder='Enter Amount'
                      label='Amount'
                    />
                  </div>
                </div>
                <div className='d-flex justify-content-center'>
                  <button className='btn btn-secondary rounded-pill m-3 px-3'>Submit</button>
                  <button className='btn btn-danger rounded-pill m-3 px-3'>Cancel</button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dth;