import React from 'react'
import Card from '../../../components/sections/Card';
import { Form, Formik } from 'formik'
import FormikControl from '../../../components/FormikControl';
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import Table from '../../../components/sections/Table'
import { fundSchema } from '../Validation'
import { BiRightArrowAlt } from "react-icons/bi";

const AdvanceLimit = () => {
  const initialValues = {
    amount: ''
  }

  const handleSubmit = () => {

  }
  return (
    <div className='content-wrapper'>
      <Breadcrumbs title='Advance Limit' path='/distributor-dashboard' />
      <div className='container'>
        <Card title='Advance Funding' body={
          <div className='row'>
            <div className='col-md-4 lh-lg my-2'>
              <div className="p-3">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>Total Adavance Limit - </td>
                      <td className='fw-bold px-3'>300000</td>
                    </tr>
                    <tr>
                      <td>Left Adavance Limit - </td>
                      <td className='fw-bold px-3'>300000</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className='col-md-8'>
              <div className="card my-4 p-3 bg-light">
                <Formik
                  initialValues={initialValues}
                  validateOnBlur={false}
                  validateOnChange={false}
                  validationSchema={fundSchema}
                  onSubmit={handleSubmit}
                >
                  <Form>
                    <div className="row d-flex align-items-center">
                      <div className="col-md-6">
                        <FormikControl
                          label="Enter Retailer ID"
                          control="input"
                          placeholder="Enter Retailer ID"
                          name='amount'
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="mx-4">
                          <button className="btn btn-dark rounded-pill">
                            <BiRightArrowAlt className="font-1" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        }
        />
        <Table
          tableHeading={[
            'TXNID', 'Advance Amount', 'Advance Date Time', 'Expiry Date Time', 'Advance Age', 'Advance Clear TXNID', 'Action']}
          tableBody='No data right now'
          colSpan='7'
        />
      </div>
    </div>
  )
}

export default AdvanceLimit