import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import FormikControl from '../FormikControl'
import { Formik } from 'formik';
import { Form } from 'formik';
import { validationSchema } from '../../pages/admin/users/Validation';
import {roles,userStatus} from '../../helpers/constant'
import {  updateUserCall, getUserCall } from '../../api/instance_method';
import { ToastContainer, toast } from 'react-toastify';

const EditUser = (props) => {
  let navigate = useNavigate();
  const { id } = useParams();

  const [initialValues, setInitialValues] = useState({
    id:  '',
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
    address_one: '',
    address_second: '',
    mobile_number: '',
    pan: '',
    adhar: '',
    role: '',
    status: '',
  });
  useEffect(() => {

    setInitialValues({
      id: props.user.id ,
    first_name: props.user.first_name,
    middle_name: props.user.middle_name,
    last_name: props.user.last_name,
    email: props.user.email,
    address_one: props.user.address_one,
    address_second: props.user.address_second,
    mobile_number: props.user.mobile_number,
    pan: props.user.pan,
    adhar: props.user.adhar,
    role: props.user.role,
    status: props.user.status,
    })
  }, [props.user])
  
const updateUser =  (values) => {
  updateUserCall( values, id).then(
    (response) => {
      document.getElementById('update-user').disabled = true;
      toast.success('Saved successfully');
      setTimeout(() => {
        props.closeModal()
      }, 2000);

    },
    (error) => {
      console.log('error', error)
    }
  );
};
  return (
    <div className={`modal fade show ${props.isOpen ? 'show' : 'hide'}`} style={{ display: 'block' }} >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h4>Edit Details</h4>
            <button type="button" className="close color-white" onClick={props.closeModal}>x</button>
          </div>
            <div className='modal-body'>
              <Formik
                initialValues={initialValues}
                onSubmit={updateUser}
                validationSchema={validationSchema}
                enableReinitialize
              >
                <Form>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <FormikControl
                          type="text"
                          control="input"
                          id="first_name"
                          name="first_name"
                          label="First name"
                          placeholder="First name"
                        />
                      </div>
                      <div className="col-md-6">
                        <FormikControl
                          type="text"
                          control="input"
                          id="middle_name"
                          name="middle_name"
                          label="Middle name"
                          placeholder="Middle name"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <FormikControl
                          type="text"
                          control="input"
                          id="last_name"
                          name="last_name"
                          label="Last name"
                          placeholder="Last Name"
                        />
                      </div>

                      <div className="col-md-6">
                        <FormikControl
                          type="email"
                          control="input"
                          id="email"
                          name="email"
                          label="Email"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <FormikControl
                          type="text"
                          control="input"
                          id="mobile_number"
                          name="mobile_number"
                          label="Mobile number"
                          placeholder="Mobile number"
                        />
                      </div>
                      <div className="col-md-6">

                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <FormikControl
                          type="text"
                          control="input"
                          id="pan"
                          name="pan"
                          label="Pan"
                          placeholder="Pan"
                        />
                      </div>
                      <div className="col-md-6">
                        <FormikControl
                          type="text"
                          control="input"
                          id="adhar"
                          name="adhar"
                          label="Adhar"
                          placeholder="Adhar"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <FormikControl
                          type="text"
                          control="input"
                          id="address_one"
                          name="address_one"
                          label="Address one"
                          placeholder="Address one"
                        />
                      </div>
                      <div className="col-md-6">
                        <FormikControl
                          type="text"
                          control="input"
                          id="address_second"
                          name="address_second"
                          label="Address second"
                          placeholder="Address second"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <FormikControl
                          control="select"
                          id="role"
                          name="role"
                          label="Role"
                          options={roles}
                        />
                      </div>
                      <div className="col-md-6">
                        <FormikControl
                          control="select"
                          id="status"
                          name="status"
                          label="Status"
                          options={userStatus}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2">
                        <button 
                        type="submit" 
                        className="btn btn-primary"
                        id='update-user'
                        >Update</button>
                      </div>
                      <div className="col-md-6">
                        <button 
                        className="btn btn-primary" 
                        onClick={props.closeModal} >
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
        </div>

      </div>
      <style jsx='true'>
        {`
        .modal {
          overflow-y: hidden;
        }
        `}
      </style>
    </div>
  )
}

export default EditUser