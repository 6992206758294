import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { Formik } from 'formik';
import { Form } from 'formik';
import { validationSchema } from '../../../pages/admin/users/Validation';
import { userStatus} from '../../../helpers/constant'
import {  getRetailerById, updateRetailer } from '../../../api/instance_method';
import {  toast } from 'react-toastify';
import FormikControl from '../../../components/FormikControl';

const Edit = (props) => {
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({
    id:  '',
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
    address_one: '',
    address_second: '',
    mobile_number: '',
    pan: '',
    adhar: '',
    // role: '',
    status: '',
  });
  useEffect(() => {
    getRetailerById(props.usedId).then (
      (response) => {
        setInitialValues({
        id: response.data.data.id,
        first_name: response.data.data.first_name,
        middle_name: response.data.data.middle_name,
        last_name: response.data.data.last_name,
        email: response.data.data.email,
        address_one: response.data.data.address_one,
        address_second: response.data.data.address_second,
        mobile_number: response.data.data.mobile_number,
        pan: response.data.data.pan,
        adhar: response.data.data.adhar,
        role: response.data.data.role,
        status: response.data.data.status,
        })
      },
      (error) => {
        toast.error('User data cannot be fetched')
      }
    )

  }, [props.usedId, id])
  
const updateUser =  (values) => {
  updateRetailer( values, props.usedId).then(
    (response) => {
      document.getElementById('update-user').disabled = true;
      toast.success('Saved successfully');
      setTimeout(() => {
        props.closeModal()
      }, 2000);

    },
    (error) => {
      toast.error('Error. User cannot be updated')
    }
  );
};
  return (
    <div className={`modal fade show ${props.isOpen ? 'show' : 'hide'}`} 
    
    style={{ display: 'block' }} >
      <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
        <div className='modal-content'>
          <div className='modal-header bg-light'>
            <h6 className='modal-title'>Edit Details</h6>
            <button type="button" className="close color-white" onClick={props.closeModal}>x</button>
          </div>
            <div className='modal-body'>
              <Formik
                initialValues={initialValues}
                onSubmit={updateUser}
                validationSchema={validationSchema}
              >
                <Form>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <FormikControl
                          type="text"
                          control="input"
                          id="first_name"
                          name="first_name"
                          label="First name"
                          placeholder="First name"
                        />
                      </div>
                      <div className="col-md-6">
                        <FormikControl
                          type="text"
                          control="input"
                          id="middle_name"
                          name="middle_name"
                          label="Middle name"
                          placeholder="Middle name"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <FormikControl
                          type="text"
                          control="input"
                          id="last_name"
                          name="last_name"
                          label="Last name"
                          placeholder="Last Name"
                        />
                      </div>

                      <div className="col-md-6">
                        <FormikControl
                          type="email"
                          control="input"
                          id="email"
                          name="email"
                          label="Email"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <FormikControl
                          type="text"
                          control="input"
                          id="mobile_number"
                          name="mobile_number"
                          label="Mobile number"
                          placeholder="Mobile number"
                        />
                      </div>
                      <div className="col-md-6">

                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <FormikControl
                          type="text"
                          control="input"
                          id="pan"
                          name="pan"
                          label="Pan"
                          placeholder="Pan"
                        />
                      </div>
                      <div className="col-md-6">
                        <FormikControl
                          type="text"
                          control="input"
                          id="adhar"
                          name="adhar"
                          label="Adhar"
                          placeholder="Adhar"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <FormikControl
                          type="text"
                          control="input"
                          id="address_one"
                          name="address_one"
                          label="Address one"
                          placeholder="Address one"
                        />
                      </div>
                      <div className="col-md-6">
                        <FormikControl
                          type="text"
                          control="input"
                          id="address_second"
                          name="address_second"
                          label="Address second"
                          placeholder="Address second"
                        />
                      </div>
                    </div>
                    <div className="row">
                      {/* <div className="col-md-6">
                        <FormikControl
                          control="select"
                          id="role"
                          name="role"
                          label="Role"
                          options={roles}
                        />
                      </div> */}
                      <div className="col-md-6">
                        <FormikControl
                          control="select"
                          id="status"
                          name="status"
                          label="Status"
                          options={userStatus}
                        />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-md-6  text-right">
                        <button 
                        type="submit" 
                        className="btn btn-outline-dark"
                        id='update-user'
                        >Update</button>
                      </div>
                      <div className="col-md-6">
                        <button 
                        className="btn btn-outline-dark" 
                        onClick={props.closeModal} >
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
        </div>
        <div className='overlay'> </div>
      </div>
      <style jsx='true'>
        {`
        .modal {
          overflow-y: hidden;
        }
        `}
      </style>
    </div>
  )
}

export default Edit