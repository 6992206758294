import React from 'react';

const Loader = () => {
  return (
    <div>
      <div className='d-flex align-items-center justify-content-center my-3'>
        <span className="loader"></span>
      </div>
    </div>
  )
};

export default Loader;
