import React from 'react'
import { CardHeader } from '../../../components/sections/CardHeader'
import { Formik, Form } from 'formik'
import FormikControl from '../../../components/FormikControl'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'
import { ActivateMatmvalidationSchema } from '../validation' 
import { ToastContainer, toast } from 'react-toastify';

const ActivateMatm = () => {
    
    const initialValues = {
      serial_number:''
    }

  const handleSubmit = (value, { resetForm }) => {
    toast.success('Data read successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    console.log('submit button clicked');
    resetForm()
  }
  return (
    <div className='content-wrapper'>
      <ToastContainer />
        <Breadcrumbs title="Activate Matm" path="/retailer-dashboard" /> 
        <div className='container'>
            <div className='card'>
                <CardHeader title="Activate Matm" />
                <div className='card-body'>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={ActivateMatmvalidationSchema}
                      onSubmit={handleSubmit}
                    >
                      <Form>
                          <div className='row'>
                              <div className='col-6'>
                                  <FormikControl
                                      control="input"
                                      placeholder="Enter Device Serial Number"
                                      label="Enter Device serial number"
                                      name="serial_number"
                                  />
                              </div>
                              <div className='col-6 my-auto'>
                                <button className='btn mt-3 btn-secondary' type='submit'>
                                Activate
                                </button>
                              </div> 
                          </div>
                      </Form>
                    </Formik>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ActivateMatm