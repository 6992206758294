import React, { useEffect, useState } from 'react'
import { getTopupTransfer } from '../../../api/instance_method';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import DistributorHeader from '../DistributorHeader';
import Breadcrumbs from '../../../components/sections/Breadcrumbs';

const List = () => {
  const [users, setUsers] = useState([]);
  const token = sessionStorage.getItem('token')
  const [totalItemsCount, setTotalItemsCount] = useState(0)
  const [activepage, setActivePage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      getTopupTransfer(1).then(
        (response) => {
          console.log(response.data.data.data)
          setUsers(
            response.data.data.data
          );
          setTotalItemsCount(
            response.data.data.total
          )
        },
      );
      setTimeout(() => {
        setIsLoading(false)
      }, 2000)
    }
  }, []);
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber)
    getTopupTransfer(pageNumber).then(
      (response) => {
        setUsers(response.data.data.data);
        console.log(response)
        setTotalItemsCount(response.data.data.total);
      },
      (error) => {
        if (error) {
          navigate('/')
          console.log(error)
        }
      }
    )
  }
  return (
    <div className="content-wrapper">
      <DistributorHeader />
      <Breadcrumbs title='User List' path='/distributor-dashboard' />
      
      <div className='container-fluid'>
        {isLoading ? (
          <div className='d-flex align-items-center justify-content-center pt-5 mt-5'>
            <span className="loader"></span>
          </div>
        ) : (
          <div className='card'>
            <div className='card-header'>
              <div className='card-title'>
                User List
              </div>
            </div><div className="card-body table-responsive">
              <div id="example2_wrapper" className="dataTables_wrapper dt-bootstrap4">
                <table
                  id="example2"
                  className="table table-bordered table-hover dataTable dtr-inline"
                  aria-describedby="example2_info"
                >
                  <thead>
                    {/* <tr>
                      <th>Retailer Name</th>
                      <th>Amount</th>
                      <th>Transfer Date</th>
                      <th>Transfered By</th>
                    </tr> */}
                    <tr>
                      <th>Retailer Name</th>
                      <th>Main Wallet</th>
                      <th>Cash-In Wallet</th>
                      <th>Credit</th>
                      <th>Top-Up</th>
                      <th>Receiving</th>
                      <th>Debit</th>
                      <th>History</th>
                    </tr>
                  </thead>
                  {users.length ? (
                    <tbody>
                      {users.map((element) => {
                        return (
                          <tr>
                            <td>
                              {element.to_user && element.to_user.first_name ? element.to_user.first_name : '-'}&nbsp;
                               {element.to_user && element.to_user.middle_name ? element.to_user.middle_name : ''}&nbsp;
                                {element.to_user && element.to_user.last_name ? element.to_user.last_name : ''}&nbsp;
                            </td>
                            <td>{element.amount}</td>
                            <td>{element.created_at}</td>
                            <td>
                              {element.from_user && element.from_user.first_name ? element.from_user.first_name  : '-'}&nbsp;
                               {element.from_user && element.from_user.middle_name ? element.from_user.middle_name : ''}&nbsp;
                               {element.from_user && element.from_user.last_name ? element.from_user.last_name : ''}&nbsp;
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={4}>
                          <p className='font-mulish'>No user found</p>
                        </td>
                      </tr>
                    </tbody>
                  )}

                </table>
                <div className='mt-4'>
                  <div className='row'>
                    <div className="col-sm-12 col-md-6">
                      <div className="dataTables_info text-primary" id="example2_info" role="status" aria-live="polite">
                        *Showing <span className='fw-bold'>{users.length} </span>
                        of <span className='fw-bold'>{totalItemsCount} </span>entries
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 text-right ">
                      <div className="float-end dataTables_paginate paging_simple_numbers" id="example2_paginate">
                        {users.length >
                          0 ? (
                          <Pagination
                            activePage={activepage}
                            itemsCountPerPage={10}
                            totalItemsCount={totalItemsCount}
                            pageRangeDisplayed={10}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        )}

      </div>



    </div>
  )
}

export default List