import React from 'react'
import Breadcrumbs from '../../../components/sections/Breadcrumbs'

const Yesbanktransfer = () => {
  return (
    <div className='content-wrapper'>
        <Breadcrumbs title='Money Transfer' path='retailer-dashboard' />
        <div className='conatiner'>
            NO UI
        </div>
    </div>
  )
}

export default Yesbanktransfer